import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import * as actions from "../../store/actions/index";
import MainPage from "./MainPage/MainPage";
import Spinner from "../../components/UI/Spinner/Spinner";
import Error from "../../components/UI/Errors/ServerError/ServerError";

// assets
import shopImg from "../../assets/images/components/ShopsList/shop.svg";
import bigShopImg from "../../assets/images/pages/Storage/big_shop_grey_icon.svg";
import CancelIcon from "@material-ui/icons/Cancel";
import "./main.scss";
import "./MainPage/mainPage.scss";
import $api from "../../api/http";

const Main = (props) => {
  const { selectedMerchant, merchants } = props;
  const { t } = useTranslation();
  const info = JSON.parse(localStorage.getItem("info"));
  const roleId = info && info.roleId;
  const shopId = info && info.shopId;
  const merchantId = info && info.merchantId;
  const token = localStorage.getItem("token") || props.token;

  const userIsSuperUser = roleId === -1;
  const userIsAdmin = roleId === 1;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (userIsSuperUser && !merchants) {
      setError(false);
      setLoading(true);

      $api
        .get("/Merchants")
        .then((res) => {
          props.setMerchants(res.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    } else if (userIsAdmin) {
      $api
        .get(`/Merchants/${merchantId}`)
        .then((res) => {
          props.setSelMerchant(res.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    }
  }, []);

  if (!info) return <div />;
  if (![-1, 1].includes(roleId)) {
    /*TEST*/
    /*const shopName = info.shopName;
    const shopCity = info.shopCity;
    const shopStreet = info.shopStreet;
    const buildingNumber = info.buildingNumber;*/

    return (
      <div className="main-page-container">
        {/*TEST*/}
        {/*<p style={{ marginLeft: '20px', marginRight: '20px', marginTop: '10px' }}>
          {t('merchant.workingIn.1')} <strong>{shopName}</strong>{' '}
          <span style={{ fontSize: '12px' }}>
            ({t('merchant.workingIn.2')} {shopCity}
            {t('merchant.workingIn.3')} {shopStreet}
            {t('merchant.workingIn.4')} {buildingNumber})
          </span>
        </p>*/}
        <MainPage
          {...props}
          shopId={shopId}
          token={token}
          merchantId={merchantId}
          setError={setError}
        />
      </div>
    );
  }

  if (loading)
    return (
      <div className="main-page-loading-container">
        <Spinner />
      </div>
    );

  if (error) return <Error />;

  return (
    <div className="main-page-container">
      {userIsSuperUser && (
        <div className="selecting-market-point-header">
          <div className="main-page-selected-merch">
            <label>
              <img className="shop_img" src={shopImg} alt="" />
              {selectedMerchant
                ? t("merchant.choosedMerchant")
                : t("merchant.chooseMerchant")}
            </label>
            {selectedMerchant && (
              <div>
                <p>
                  {selectedMerchant.name.length > 30
                    ? selectedMerchant.name.slice(0, 30) + "..."
                    : selectedMerchant.name}
                </p>
                <CancelIcon
                  onClick={() => props.setSelMerchant(null)}
                  className="cancel-icon"
                />
              </div>
            )}
            {!selectedMerchant && (
              <select
                value={selectedMerchant ? selectedMerchant : ""}
                onChange={(e) =>
                  props.setSelMerchant(JSON.parse(e.target.value))
                }
              >
                <option value={""} disabled hidden>
                  {t("merchant.chooseFromList")}
                </option>
                {merchants &&
                  merchants.map((el, i) => (
                    <option value={JSON.stringify(el)} key={i}>
                      {el.name.length > 30
                        ? el.name.slice(0, 30) + "..."
                        : el.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
        </div>
      )}
      {!userIsSuperUser && (
        <MainPage
          {...props}
          merchantId={merchantId}
          token={token}
          setError={setError}
        />
      )}
      {userIsSuperUser && selectedMerchant && (
        <MainPage
          {...props}
          merchantId={selectedMerchant.id}
          token={token}
          setError={setError}
        />
      )}
      {!selectedMerchant && userIsSuperUser && (
        <div className="not-selected-shop">
          <div className="centered-container">
            <img src={bigShopImg} alt="" />
            <span>{t("merchant.notChoosedMerchant")}</span>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  info: state.auth.info,
  merchants: state.admin.merchants,
  selectedMerchant: state.admin.selectedMerchant,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  setMerchants: (arr) => dispatch(actions.setMerchants(arr)),
  setSelMerchant: (val) => dispatch(actions.setSelectedMerchant(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
