import React from "react";
import { useTranslation } from "react-i18next";
import PosterStores from "./PosterStores/PosterStores";
import "./styles.scss";
import PosterMerchantForm from "./PosterMerchantForm/PosterMerchantForm";

const IframePosterMerchant = ({ stores, merchant, synchronizeMerchant }) => {
  const { t } = useTranslation();

  return (
    <div className="IframePosterMerchantPage">
      <div className="IframePosterMerchant">
        <div className="IframePosterStoresHeaderWrap">
          <div className="IframePosterStoresHeader">
            <button
              className="HeaderImportButton"
              onClick={synchronizeMerchant}
            >
              {t("auth.syncImport_button")}
            </button>
          </div>
        </div>

        <div className="IframePosterStoresContent">
          {merchant && <PosterMerchantForm merchant={merchant} />}

          {stores && <PosterStores stores={stores} />}
        </div>
      </div>
    </div>
  );
};

export default IframePosterMerchant;
