import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18nResourses from "./locales";

const languages = ["ua", "ru"];

i18next.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: "ua",
  lng: "ua",
  debug: true,
  whitelist: languages,
  resources: i18nResourses,
});

export default i18next;
