import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import jstz from "jstz";

import * as actions from "../../store/actions";

import { getUUID } from "../../tools/getUUID";

import "./ordersList.scss";

import OrderItem from "./orderItem/OrderItem";
import Spinner from "../UI/Spinner/Spinner";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import CancelIcon from "@material-ui/icons/Cancel";

// icons
import ordersImage from "../../assets/images/components/OrdersList/orders.svg";
import refresh from "../../assets/images/components/OrdersList/refresh.svg";
import search from "../../assets/images/components/ShopsList/search.svg";
import { ReactComponent as SwapIcon } from "../../assets/images/components/OrdersList/swap.svg";

const OrdersList = (props) => {
  const {
    orders,
    loading,
    selectedOrder,
    offset,
    ordersPerPage,
    orderFilter,
    setOrderFilter,
  } = props;
  const { t } = useTranslation();
  const [searchNum, setSearchNum] = useState("");

  const orderFilters = [
    {
      label: t("По номеру заказа (по убыванию)"),
      value: "number",
      id: getUUID(),
    },
    { label: t("По времени выдачи заказа"), value: "delivery", id: getUUID() },
  ];

  const [filteredMenuActivated, setFilteredMenuActivated] = useState(false);
  const node = useRef(getUUID());

  const previousPage = `${t("merchant.ordersList.previous")} ${ordersPerPage}`;
  const nextPage = `${t("merchant.ordersList.next")} ${ordersPerPage}`;

  const ordersSum = +props.orders
    .reduce((acc, val) => {
      const orderSum = +val.order.shoppingCart.products
        .reduce((acc1, val1) => {
          if (val1.pd.bl) {
            return (
              acc1 +
              (val1.q / (val1.pd.w === 0 ? val1.pd.v : val1.pd.w)) * val1.pd.p
            );
          } else {
            return acc1 + val1.q * val1.pd.p;
          }
        }, 0)
        .toFixed(2);
      return acc + orderSum;
    }, 0)
    .toFixed(2);

  useEffect(() => {
    if (filteredMenuActivated) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filteredMenuActivated]);

  const ordersListContent = orders.map((el, index) => {
    const nextOrderHaveAnotherDate =
      index !== 0
        ? !onCompareTwoDatesMonthesAndNumber(
            orders[index - 1].order.createdDate,
            orders[index].order.createdDate,
          )
        : true;

    const timezone = jstz.determine();
    const orderDateFull = new Date(el.order.createdDate)
      .toLocaleDateString("ru", {
        timeZone: timezone.name(),
      })
      .slice(0, 5);
    return (
      <div key={`${el.order.id}_${index}`}>
        {nextOrderHaveAnotherDate && (
          <div className="date-line">
            <span>{orderDateFull}</span>
          </div>
        )}
        <OrderItem
          order={el}
          addOrderToWork={props.orderLoading ? () => {} : props.addOrderToWork}
          selected={
            selectedOrder ? selectedOrder.order.id === el.order.id : false
          }
          setSelectedOrder={
            (selectedOrder && selectedOrder.order.id === el.order.id) ||
            props.orderLoading
              ? () => {}
              : props.setSelectedOrder
          }
        />
      </div>
    );
  });

  return (
    <div className="orders-list-block">
      <div className="orders-list-block__header">
        <div className="orders-list-block__header_title">
          <img src={ordersImage} alt="orders" />
          <h2>{t("merchant.ordersList.list")}</h2>
          <div className="refresh-button" onClick={props.onRefresh}>
            <img src={refresh} alt="" />
          </div>
        </div>
        <div className="orders-list-block__header_subtitle">
          {!props.searchNum ? (
            <label className="search" htmlFor="search_orders_list">
              <label htmlFor="search_orders_list">
                <img
                  src={search}
                  alt=""
                  onClick={() => {
                    props.setSearchNum(searchNum);
                    setSearchNum("");
                  }}
                />
              </label>
              <input
                type="text"
                id="search_orders_list"
                placeholder={t("Номер заказа")}
                value={searchNum}
                type="number"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    props.setSearchNum(e.target.value);
                    setSearchNum("");
                  }
                }}
                onChange={(e) => setSearchNum(e.target.value)}
              />
            </label>
          ) : (
            <label className="search" htmlFor="search_orders_list">
              <input
                type="text"
                disabled
                id="search_orders_list"
                placeholder={
                  "Заказ " +
                  (props.searchNum.toString().length > 3
                    ? "..." + props.searchNum.toString().slice(-3)
                    : props.searchNum)
                }
                value={searchNum}
                type="number"
              />
              <CancelIcon
                onClick={() => props.setSearchNum(null)}
                style={{ marginLeft: "auto", cursor: "pointer" }}
              />
            </label>
          )}
          <div className="dropdown-wraper">
            <div
              className={`filter-dropdown ${
                filteredMenuActivated ? "opened" : ""
              }`}
              onClick={() => setFilteredMenuActivated((v) => !v)}
            >
              <span className="label">
                {orderFilters.filter((el) => el.value === orderFilter)[0].label}
              </span>
              <SwapIcon />
            </div>
            <div
              className={`dropdown-list ${
                filteredMenuActivated ? "opened" : ""
              }`}
              ref={node}
            >
              <div className="gray-line" />
              {orderFilters.map((el, index) => (
                <div
                  className="line"
                  key={`${el.id}_${index}`}
                  onClick={() => {
                    setOrderFilter(el.value);
                    setFilteredMenuActivated(false);
                  }}
                >
                  <span>{el.label}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="orders-list-block__body">
        <div className="orders-list-block__body_scrolled">
          {offset !== 0 && !loading && (
            <div
              className="orders-list-block__body_scrolled_previous"
              onClick={() =>
                props.setOffset(
                  offset < ordersPerPage ? 0 : offset - ordersPerPage,
                )
              }
            >
              <DoubleArrowIcon />
              <p>{previousPage}</p>
            </div>
          )}
          {!loading && ordersListContent}
          {orders.length === ordersPerPage && !loading && (
            <div
              className="orders-list-block__body_scrolled_next"
              onClick={() => props.setOffset(offset + ordersPerPage)}
            >
              <DoubleArrowIcon />
              <p>{nextPage}</p>
            </div>
          )}
          {loading && (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                height: "100px",
                marginTop: "30px",
              }}
            >
              <Spinner />
            </div>
          )}
        </div>
        <div className="scrollable-shadow" />
      </div>

      <div className="orders-list-block__footer">
        <p className="orders-list-block__footer_label">
          {t("merchant.ordersList.footer.1")}
        </p>
        <p className="orders-list-block__footer_price">{ordersSum + " ₴"}</p>
      </div>
    </div>
  );

  function onCompareTwoDatesMonthesAndNumber(first, second) {
    const timezone = jstz.determine();

    const firstDateFull = new Date(first).toLocaleDateString("ru", {
      timeZone: timezone.name(),
    });
    const secondDateFull = new Date(second).toLocaleDateString("ru", {
      timeZone: timezone.name(),
    });

    return firstDateFull === secondDateFull;
  }

  function handleClickOutside(event) {
    if (node.current.contains(event.target)) {
      return;
    } else {
      setFilteredMenuActivated(false);
    }
  }
};

const mapStateToProps = (state) => ({
  info: state.auth.info,
  merchants: state.admin.merchants,
  selectedMerchant: state.admin.selectedMerchant,
  selectedShop: state.admin.selectedShop,
  mode: state.mode.mode,
  searchNum: state.mode.searchNum,
});

const mapDispatchToProps = (dispatch) => ({
  setOrdersMode: (val) => dispatch(actions.setChangedOrderMode(val)),
  setSearchNum: (int) => dispatch(actions.setSearchOrderNumber(int)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);
