import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  onChangeInput,
  onChangeSize,
} from "../../utils/createProdFunctions/createProdFunctions";

import Spinner from "../../../../../../../components/UI/Spinner/Spinner";
import Error from "../../../../../../../components/UI/Errors/ServerError/ServerError";
import CustomError from "../../../../../../../components/UI/Errors/CustomError/CustomError";
import SelectCat from "../SelectCat/SelectCat";
import ProductAPI from "../../../../../../../models/storage/products/products";

// svg
import basket from "../../../../../../../assets/images/pages/Products/ProdForm/basket.svg";
import close from "../../../../../../../assets/images/pages/Products/ProdForm/close.svg";
import done from "../../../../../../../assets/images/pages/Products/ProdForm/done.svg";
import cancel from "../../../../../../../assets/images/pages/Products/ProdForm/cancel.svg";

const EditProd = (props) => {
  const { merchantId, shopId } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const categories = props.cats;
  const id = props.id;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [prodType, setProdType] = useState("weight");
  const [form, setForm] = useState([
    {
      value: "",
      rules: {
        required: true,
        minLength: 2,
        maxlength: 50,
      },
      error: t("prodErrors.0"),
      name: "name",
      valid: false,
      touched: false,
    },
    {
      value: "",
      rules: {
        required: true,
      },
      error: t("prodErrors.1"),
      name: "category",
      valid: false,
      touched: false,
    },
    {
      value: "",
      rules: {
        required: true,
        minLength: 1,
        maxlength: 2000,
      },
      error: t("prodErrors.2"),
      name: "url",
      valid: false,
      touched: false,
    },
    {
      value: "",
      rules: {
        required: true,
      },
      error: t("prodErrors.3"),
      name: "type",
      valid: false,
      touched: false,
    },
    {
      value: "",
      rules: {
        required: true,
        minLength: 1,
        maxlength: 6,
      },
      error: t("prodErrors.4"),
      name: "count",
      valid: false,
      touched: false,
    },
    {
      value: "",
      rules: {
        required: true,
        minLength: 1,
        maxlength: 6,
      },
      error: t("prodErrors.5"),
      name: "price",
      valid: false,
      touched: false,
    },
  ]);
  const [size, setSize] = useState("");
  const [formIsValid, setFormIsValid] = useState(true);
  const [formError, setFormError] = useState(null);

  useEffect(async () => {
    setLoading(true);
    setError(false);
    const product = new ProductAPI(merchantId, shopId);
    try {
      const result = await product.getOneById(id);
      const convertedHtmlDescr = htmlToDraft(result.data.d);
      const initialDescription = ContentState.createFromBlockArray(
        convertedHtmlDescr.contentBlocks,
        convertedHtmlDescr.entityMap,
      );

      setDescription(EditorState.createWithContent(initialDescription));
      setProdType(result.data.bl ? "weight" : "sht");
      setForm([
        {
          value: result.data.t,
          rules: {
            required: true,
            minLength: 2,
            maxlength: 50,
          },
          error: t("prodErrors.0"),
          name: "name",
          valid: true,
          touched: false,
        },
        {
          value: result.data.clst[0],
          rules: {
            required: true,
          },
          error: t("prodErrors.1"),
          name: "category",
          valid: true,
          touched: false,
        },
        {
          value: result.data.img,
          rules: {
            required: true,
            minLength: 1,
            maxlength: 2000,
          },
          error: t("prodErrors.2"),
          name: "url",
          valid: true,
          touched: false,
        },
        {
          value: result.data.v > 0 ? "ml" : "g",
          rules: {
            required: true,
          },
          error: t("prodErrors.3"),
          name: "type",
          valid: true,
          touched: false,
        },
        {
          value: result.data.q,
          rules: {
            required: true,
            minLength: 1,
            maxlength: 6,
          },
          error: t("prodErrors.4"),
          name: "count",
          valid: true,
          touched: false,
        },
        {
          value: result.data.p,
          rules: {
            required: true,
            minLength: 1,
            maxlength: 6,
          },
          error: t("prodErrors.5"),
          name: "price",
          valid: true,
          touched: false,
        },
      ]);
      setSize(result.data.v > 0 ? result.data.v : result.data.w);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }, []);

  const onEditProd = async () => {
    setLoading(true);
    setError(false);
    const products = new ProductAPI(merchantId, shopId);
    try {
      const result = await products.onEdit(id, {
        bc: "hz",
        t: form[0].value,
        d: draftToHtml(convertToRaw(description.getCurrentContent())),
        p: form[5].value,
        w: form[3].value === "ml" ? 0 : size,
        v: form[3].value === "g" ? 0 : size,
        img: form[2].value,
        q: form[4].value,
        bl: prodType === "weight" ? true : false,
        clst: [form[1].value],
      });
      history.push({
        pathname: "/main/storage/products",
        state: {
          successChanged: true,
          name: form[0].value,
        },
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        setError(e.response.data.message);
      }
    }
    setLoading(false);
  };

  const changeInputData = {
    form,
    setForm,
    setFormIsValid,
    setFormError,
  };

  return (
    <div className="create-cat-container">
      {error === true && <Error />}
      {typeof error === "string" && <CustomError title={error} />}
      {loading && (
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
      {!error && !loading && (
        <>
          <div className="close-div" onClick={props.onClose}>
            <p>{t("createProd.closeLabel")}</p>
            <img src={close} alt="" />
          </div>
          <h2>
            <img src={basket} alt="" />
            <p>{t("createProd.title2")}</p>
          </h2>
          <div className="content">
            <div className="content_area">
              <label
                htmlFor="name"
                className={formError === form[0].error ? "error" : null}
              >
                {t("createProd.name")}
              </label>
              <input
                type="text"
                id="name"
                value={form[0].value}
                onChange={(e) => onChangeInput(e, "name", changeInputData)}
                className={formError === form[0].error ? "error" : null}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <label
                htmlFor="cat"
                style={{
                  letterSpacing: "0.8px",
                  fontSize: "20px",
                  color: formError === form[1].error ? "#c73f3f" : "#6F7486",
                  opacity: 0.8,
                  marginRight: "5px",
                  cursor: "pointer",
                }}
              >
                {t("createProd.cat")}
              </label>
              <div style={{ fontSize: "16px !important" }}>
                <SelectCat
                  cats={categories}
                  value={form[1].value}
                  change={(e) => onChangeInput(e, "category", changeInputData)}
                />
              </div>
            </div>
            <div className="content_area">
              <label
                htmlFor="url"
                className={formError === form[2].error ? "error" : null}
              >
                {t("createProd.url")}
              </label>
              <input
                type="text"
                id="url"
                value={form[2].value}
                onChange={(e) => onChangeInput(e, "url", changeInputData)}
                className={formError === form[2].error ? "error" : null}
              />
            </div>
            <div
              className="content_area"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <label htmlFor="type">{"Тип товара"}</label>
              <div className="blue-select">
                <div
                  onClick={() => setProdType("weight")}
                  className={
                    prodType === "weight"
                      ? "blue-select-item-active"
                      : "blue-select-item"
                  }
                >
                  <p>{"весовой"}</p>
                </div>
                <div
                  onClick={() => setProdType("sht")}
                  className={
                    prodType === "sht"
                      ? "blue-select-item-active"
                      : "blue-select-item"
                  }
                >
                  <p>{"штучный"}</p>
                </div>
              </div>
            </div>
            {prodType === "weight" && (
              <>
                <div className="content_area" style={{ alignItems: "center" }}>
                  <label
                    htmlFor="price"
                    className={formError === form[5].error ? "error" : null}
                  >
                    {"Цена, ₴:"}
                  </label>
                  <input
                    value={form[5].value.toString()}
                    onChange={(e) =>
                      onChangeInput(e, "price", {
                        isNumber: true,
                        ...changeInputData,
                      })
                    }
                    type="number"
                    id="price"
                    style={{ marginRight: "10px" }}
                    className={
                      formError === form[5].error ? "error small" : "small"
                    }
                  />
                  <label>{"за:"}</label>
                  <input
                    value={size.toString()}
                    onChange={(e) => onChangeSize(e.target.value, { setSize })}
                    type="number"
                    id="mlsz"
                    style={{ marginLeft: "10px" }}
                    className="small"
                  />
                  <div className="blue-select">
                    <div
                      onClick={() =>
                        onChangeInput(
                          { target: { value: "g" } },
                          "type",
                          changeInputData,
                        )
                      }
                      className={
                        form[3].value === "g"
                          ? "blue-select-item-active"
                          : "blue-select-item"
                      }
                    >
                      <p>{"(гр.)"}</p>
                    </div>
                    <div
                      onClick={() =>
                        onChangeInput(
                          { target: { value: "ml" } },
                          "type",
                          changeInputData,
                        )
                      }
                      className={
                        form[3].value === "ml"
                          ? "blue-select-item-active"
                          : "blue-select-item"
                      }
                    >
                      <p>{"(мл.)"}</p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {prodType === "sht" && (
              <>
                <div className="content_area" style={{ alignItems: "center" }}>
                  <label htmlFor="mlsz">{"Вес / Объем"}</label>
                  <input
                    value={size.toString()}
                    onChange={(e) => onChangeSize(e.target.value, { setSize })}
                    type="number"
                    id="mlsz"
                    className="small"
                  />
                  <div className="blue-select">
                    <div
                      onClick={() =>
                        onChangeInput(
                          { target: { value: "g" } },
                          "type",
                          changeInputData,
                        )
                      }
                      className={
                        form[3].value === "g"
                          ? "blue-select-item-active"
                          : "blue-select-item"
                      }
                    >
                      <p>{"(гр.)"}</p>
                    </div>
                    <div
                      onClick={() =>
                        onChangeInput(
                          { target: { value: "ml" } },
                          "type",
                          changeInputData,
                        )
                      }
                      className={
                        form[3].value === "ml"
                          ? "blue-select-item-active"
                          : "blue-select-item"
                      }
                    >
                      <p>{"(мл.)"}</p>
                    </div>
                  </div>
                </div>
                <div className="content_area">
                  <label
                    htmlFor="price"
                    className={formError === form[5].error ? "error" : null}
                  >
                    {t("createProd.price")}
                  </label>
                  <input
                    value={form[5].value.toString()}
                    onChange={(e) =>
                      onChangeInput(e, "price", {
                        isNumber: true,
                        ...changeInputData,
                      })
                    }
                    type="number"
                    id="price"
                    className={formError === form[5].error ? "error" : null}
                  />
                </div>
              </>
            )}
            <div className="content_area">
              <label
                htmlFor="count"
                className={formError === form[4].error ? "error" : null}
              >
                {`В наличии (${
                  prodType === "sht"
                    ? "шт."
                    : form[3].value === "ml"
                      ? "мл."
                      : "гр."
                }) :`}
              </label>
              <input
                type="number"
                id="count"
                value={form[4].value.toString()}
                onChange={(e) =>
                  onChangeInput(e, "count", {
                    isNumber: true,
                    ...changeInputData,
                  })
                }
                className={formError === form[4].error ? "error" : null}
              />
            </div>
            <label style={{ marginTop: "20px" }}>{t("createProd.descr")}</label>
            <div className="content_area editor">
              <Editor
                editorState={description}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={(el) => setDescription(el)}
              />
            </div>
            <div className="bottom">
              <p className="error">{formError}</p>
              <div className="buttons">
                <button
                  className={
                    !formIsValid ||
                    ((form[3].value === "sht" || form[3].value === "ml") &&
                      size === "")
                      ? "disabled"
                      : "confirm"
                  }
                  onClick={
                    !formIsValid ||
                    ((form[3].value === "sht" || form[3].value === "ml") &&
                      size === "")
                      ? () => {}
                      : onEditProd
                  }
                >
                  <img src={done} alt="" />
                  <p>{t("createProd.button3")}</p>
                </button>
                <button onClick={props.onClose} className="cancel">
                  <img src={cancel} alt="" />
                  <p>{t("createProd.button2")}</p>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EditProd;
