import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import WebFont from "webfontloader";
import "./styles/fonts.css";
import "./index.scss";
import "./translation/i18n";

// redux stuff
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import authReducer from "./store/reducers/auth";
import adminReducer from "./store/reducers/admin";
import ModeReducer from "./store/reducers/modes";
import alertReducer from "./store/reducers/alertReducer";

WebFont.load({
  google: {
    families: ["Lato:400,500,600,700,800", "sans-serif"],
  },
});

const rootReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  mode: ModeReducer,
  alert: alertReducer,
});
const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const app = (
  <React.Suspense fallback={<div>Загрузка...</div>}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.Suspense>
);

ReactDOM.render(app, document.getElementById("root"));

reportWebVitals();
