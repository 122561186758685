import { Buffer } from "buffer";
import AccountsService from "../../../api/services/AccountsService";

export const getUserAva = async (userId) => {
  try {
    const response = await AccountsService.getUserAva(userId);
    const fileData = `data:${
      response.headers["content-type"]
    };base64,${new Buffer(response.data, "binary").toString("base64")}`;

    return fileData;
  } catch (e) {
    console.log("e = ", e);
    return null;
  }
};
