import React, { useEffect, useState } from "react";
import promptIcon from "../../../assets/images/components/common/promptIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setPromptInfo,
  setPromptIsShown,
} from "../../../store/actions/alert/alertActions";
import "./Prompt.scss";
import "../../../app.scss";

const Prompt = () => {
  const dispatch = useDispatch();
  const isPromptShown = useSelector((state) => state.alert.isPromptShown);
  const promptInfo = useSelector((state) => state.alert.promptInfo);
  const [animationEnd, setAnimationEnd] = useState(false);

  const [promptText, setPromptText] = useState("");

  useEffect(() => {
    setPromptText(promptInfo.promptText);
    const t1 = window.setTimeout(() => {
      setAnimationEnd(true);
    }, 6000);
    const t2 = window.setTimeout(() => {
      promptInfo && dispatch(setPromptInfo(null));
      dispatch(setPromptIsShown(false));
    }, 7000);

    return () => {
      promptInfo && dispatch(setPromptInfo(null));
      isPromptShown && dispatch(setPromptIsShown(false));
      window.clearTimeout(t1);
      window.clearTimeout(t2);
    };
  }, []);

  return (
    <div
      className={
        !animationEnd
          ? `Prompt Animation_Opacity_0to1_08s`
          : `Prompt} Animation_Opacity_1to0_08s}`
      }
    >
      <div className="PromptBox">
        <span>{promptText}</span>
        <img className="PromptIcon" src={promptIcon} alt="підказка" />
      </div>
    </div>
  );
};

export default Prompt;
