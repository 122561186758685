import $api from "../../api/http";

class Notifications {
  getUnhandledMessages() {
    return $api.get("/notifications/stat");
  }

  getMessages({ type, dateOffset, limit, offset, isArchive }) {
    const typeSample = type !== 0 ? `type=${type}` : "";
    const dateOffsetSample = dateOffset ? `&dateOffset=${dateOffset}` : "";
    const limitSample = limit ? `&limit=${limit}` : "";
    const offsetSample = offset ? `&offset=${offset}` : "";
    const isArchiveSample = `&isArchive=${isArchive}`;

    return $api.get(
      `/notifications?${typeSample}${dateOffsetSample}${limitSample}${offsetSample}${isArchiveSample}`,
    );
  }

  archiveSingleRecord(record) {
    return $api.post(`/notifications/${record.id}/archive`);
  }

  deleteSingleRecord(record) {
    return $api.delete(`/notifications/${record.id}`);
  }

  archiveStackRecords(selectedRecords) {
    return $api.post(
      "/notifications/bulk-archive",
      selectedRecords.map((el) => el.id),
    );
  }

  deleteStackRecords(selectedRecords) {
    return $api.post(
      "/notifications/bulk-delete",
      selectedRecords.map((el) => el.id),
    );
  }

  changeMessageStatusToDone(id) {
    return $api.post(`/notifications/${id}/process`);
  }
}

export default Notifications;
