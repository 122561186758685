import React from "react";
import { Route, Switch, Redirect, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// products
import Products from "./components/Products/Products";

// categories
import Categories from "./components/Categories/Categories";
import CreateCategory from "./components/Categories/components/CreateCategory/CreateCategory";
import EditCategory from "./components/Categories/components/EditCategory/EditCategory";

import "./pageStorage.scss";
import "./createEditProd.scss";

const PageStorage = (props) => {
  const { t } = useTranslation();

  const { merchantId, shopId } = props;

  return (
    <div className="storage">
      <div className="storage-links">
        <NavLink
          to="/main/storage/products"
          onClick={props.onClose}
          activeClassName="active"
        >
          {t("storage.bm.1")}
        </NavLink>
        <NavLink
          exact
          to="/main/storage/categories"
          onClick={props.onClose}
          activeClassName="active"
        >
          {t("storage.bm.2")}
        </NavLink>
      </div>
      <div>
        <Switch>
          <Route
            exact
            path="/main/storage/products"
            render={(props) => (
              <Products merchantId={merchantId} shopId={shopId} />
            )}
          />
          <Redirect exact from="/main/storage/" to="/main/storage/products" />
          <Route
            exact
            path="/main/storage/categories"
            render={(props) => (
              <Categories
                topLevel={true}
                {...props}
                merchantId={merchantId}
                shopId={shopId}
              />
            )}
          />
          <Route
            exact
            path="/main/storage/categories/:id"
            render={(props) => (
              <Categories
                topLevel={false}
                {...props}
                merchantId={merchantId}
                shopId={shopId}
              />
            )}
          />
          <Route
            exact
            path="/main/storage/categories/:id/create"
            render={(props) => (
              <CreateCategory
                {...props}
                merchantId={merchantId}
                shopId={shopId}
              />
            )}
          />
          <Route
            exact
            path="/main/storage/categories/:id/edit"
            render={(props) => (
              <EditCategory
                {...props}
                merchantId={merchantId}
                shopId={shopId}
              />
            )}
          />
        </Switch>
      </div>
    </div>
  );
};

export default PageStorage;
