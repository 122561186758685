import React, { useEffect, useState } from "react";
import "./MerchantInfo.scss";
import { useTranslation } from "react-i18next";
import EditMerchantContent from "./EditMerchantContent";
import { getWeekDays } from "../../../tools/pages/administration";
import { setPromptInfo } from "../../../store/actions/alert/alertActions";
import { useDispatch } from "react-redux";

const MerchantInfo = ({ setSuccessUpdatedMerchant, getAllShops }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [editedMerchant, setEditedMerchant] = useState(undefined);

  useEffect(() => {
    const merchantInfo = JSON.parse(window.localStorage.selectedMerchant);
    if (merchantInfo) {
      const allDates = getWeekDays(merchantInfo, t);
      setEditedMerchant({
        ...merchantInfo,
        weekDays: allDates.weekDays,
        exclusionDays: allDates.exclusionDays,
        settings: merchantInfo.settings
          ? {
              ...JSON.parse(merchantInfo.settings),
            }
          : {},
      });
    }
  }, []);

  const onSuccess = () => {
    setSuccessUpdatedMerchant(true);
  };

  const onError = () => {
    dispatch(
      setPromptInfo({
        promptText: t("global.error"),
      }),
    );
  };

  if (!editedMerchant) return <div />;
  return (
    <div className="merchantInfo">
      <h2 className="merchantInfo_title">{t("merchant.title")}</h2>

      <EditMerchantContent
        onSuccess={onSuccess}
        onError={onError}
        editedMerchant={editedMerchant}
        setEditedMerchant={setEditedMerchant}
        getAllShops={getAllShops}
      />
    </div>
  );
};

export default MerchantInfo;
