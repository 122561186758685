import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import * as actions from "../../../../../../store/actions/index";

const OrdersDateLinks = (props) => {
  const { t } = useTranslation();

  return (
    <div className="header__bottom__history">
      <p>{t("header.bottom.history.title1")}</p>
      <div className="header__bottom__history_date">
        <p
          onClick={() => props.setOrdersMode(null)}
          className={props.mode === null ? "active_border" : "dis_border"}
        >
          {t("header.bottom.history.1")}
        </p>
        <p
          onClick={() => props.setOrdersMode("yesterday")}
          className={
            props.mode === "yesterday" ? "active_border" : "dis_border"
          }
        >
          {t("header.bottom.history.2")}
        </p>
        <p
          onClick={() => props.setOrdersMode("week")}
          className={props.mode === "week" ? "active_border" : "dis_border"}
        >
          {t("header.bottom.history.3")}
        </p>
        <p
          onClick={() => props.setOrdersMode("month")}
          className={props.mode === "month" ? "active_border" : "dis_border"}
        >
          {t("header.bottom.history.4")}
        </p>
        <p
          onClick={() => props.setOrdersMode("year")}
          className={props.mode === "year" ? "active_border" : "dis_border"}
        >
          {t("header.bottom.history.5")}
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  mode: state.mode.mode,
});

const mapDispatchToProps = (dispatch) => ({
  setOrdersMode: (val) => dispatch(actions.setChangedOrderMode(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersDateLinks);
