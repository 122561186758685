import React from "react";
import { CSSTransition } from "react-transition-group";

import "./authModal.scss";

import close from "../../../assets/images/components/UI/AuthModal/close.svg";
import logo from "../../../assets/images/components/UI/AuthModal/logo.svg";
import phone from "../../../assets/images/components/UI/AuthModal/call.svg";
import teleg from "../../../assets/images/components/UI/AuthModal/telegram.svg";

const AuthModal = React.memo((props) => {
  const { show } = props;
  return (
    <>
      {show && <div className="auth-modal-backdrop" onClick={props.onClose} />}
      <CSSTransition
        in={show}
        timeout={300}
        classNames="auth-modal-anim"
        unmountOnExit
      >
        <div className="auth-modal-block-content">
          <div onClick={props.onClose} className="close-area">
            <p>{"Закрити"}</p>
            <img alt="" src={close} />
          </div>
          <div className="auth-modal-block-content_body">
            <img src={logo} alt="" />
            <p className="parag">{"Служба підтримки клієнтів Samo:"}</p>
            <div className="auth-modal-block-content_body_list">
              <div>
                <img alt="" src={phone} />
                <div>
                  <p>{"Телефон гарячої лінії"}</p>
                  <a href="tel:+0443039929">+(044) 303 99 29</a>
                </div>
              </div>
              <div>
                <img alt="" src={teleg} />
                <div>
                  <p>{"Телефон гарячої лінії"}</p>
                  <a href="mailto:info@ukrpay.net">info@ukrpay.net</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
});

export default AuthModal;
