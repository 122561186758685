import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { Controller } from "react-hook-form";
import { closePopupByParentID } from "../../../tools/common/additionalFuncs";
import select_arrow from "../../../assets/images/components/common/select_arrow.svg";

const RHFSelect = ({
  control,
  keyName,
  listData,
  description,
  id,
  wrapStyles,
  isInactive = false,
  optionInit,
}) => {
  const editPageRef = useRef(null);
  const selectedOptionRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(listData[0]);
  const [otherOptions, setOtherOptions] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  // save options without selected option
  useEffect(() => {
    const newOtherOptions = [...listData];
    newOtherOptions.splice(listData.indexOf(selectedOption), 1);
    setOtherOptions(newOtherOptions);
  }, [selectedOption]);

  useEffect(() => {
    optionInit && setSelectedOption(optionInit);
  }, [optionInit]);
  /*useEffect(() => {
    setSelectedOption(selectedOption);
  }, [selectedOption]);*/

  useEffect(() => {
    if (editPageRef.current && selectedOptionRef.current) {
      editPageRef.current.style.zIndex = isExpanded ? "3" : "2";
      selectedOptionRef.current.style.color = isExpanded
        ? "rgba(112,120,127,0.75)"
        : "#111E29FF";
    }
  }, [isExpanded]);

  // closing popup
  useEffect(() => {
    const listener = closePopupByParentID(`Select_${id}`, setIsExpanded);
    return () => window.removeEventListener("click", listener);
  }, []);

  // styles
  const lastOptionStyles = {
    borderBottom: "1px solid rgba(112, 120, 127, 0.5)",
    paddingBottom: "12px",
  };

  const wrapInactiveStyles = {
    backgroundColor: "rgb(245,245,245,1)",
    cursor: "auto",
    //border: 'none',
    //backgroundColor: "inherit",
    //marginLeft: "-16px",
  };

  // handlers
  const onClickSelectHandler = () => {
    setIsExpanded((prev) => !prev);
  };
  const onClickOptionHandler = (option, field) => {
    if (selectedOption !== option) {
      setSelectedOption(option);
      field.onChange(option.value);
    }
    setIsExpanded((prev) => !prev);
  };

  return (
    <div style={wrapStyles ? wrapStyles : {}} className="RHFSelect">
      <div className="SelectWrap">
        <div
          className="SelectDescription"
          /*style={
            inputIsValid
              ? { color: `rgb(112, 120, 127)` }
              : { color: `rgba(255, 0, 0, 0.7)` }
          }*/
        >
          {description}
        </div>

        <Controller
          control={control}
          name={keyName}
          render={({ field }) => (
            <div
              ref={editPageRef}
              className="SelectBox"
              id={`Select_${id}`}
              style={wrapStyles ? wrapStyles : {}}
            >
              <div
                style={isInactive ? wrapInactiveStyles : {}}
                className="Select"
                onClick={!isInactive ? onClickSelectHandler : () => null}
              >
                <div ref={selectedOptionRef} className="SelectLeft">
                  <span>{selectedOption.text}</span>
                </div>
                <div className="SelectRight">
                  {!isInactive && <img src={select_arrow} alt="вибрати" />}
                </div>
              </div>
              {isExpanded &&
                otherOptions &&
                otherOptions.map((option, index) => {
                  return (
                    <div
                      className="SelectOption"
                      key={index}
                      style={
                        index + 1 < otherOptions.length ? {} : lastOptionStyles
                      }
                      onClick={() => onClickOptionHandler(option, field)}
                    >
                      {option.text}
                    </div>
                  );
                })}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default RHFSelect;
