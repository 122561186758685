import React from "react";
import { useTranslation } from "react-i18next";

import "./personal.scss";

import PersonalItem from "./PersonalItem/PersonalItem";

// icons
import personAdd from "../../assets/images/components/Personal/person-add.svg";

const Personal = (props) => {
  const { t } = useTranslation();
  const personalArray = props.selectedShop ? props.selectedShop.accounts : null;

  return (
    <div className="personal-list-block">
      {!props.withoutHeader && (
        <div className="personal-list-block__header">
          <div className="personal-list-block__header_title">
            {/*<img src={personal} alt="orders" />*/}
            <h2>{t("personal.title")}</h2>
          </div>
          <div className="personal-list-block__header_subtitle">
            <p>
              {t("personal.subtitle1")}
              <br /> {t("personal.subtitle2")}
            </p>
            <p>{t("personal.subtitle3")}</p>
          </div>
        </div>
      )}

      <div className="personal-list-block__body">
        <div className="personal-list-block__body_scrolled">
          {personalArray &&
            personalArray.map((el, index) => (
              <PersonalItem
                {...props}
                selectedUser={props.selectedUser}
                setSelectedUser={props.setSelectedUser}
                key={index}
                el={el}
              />
            ))}
          {personalArray && !personalArray[0] && (
            <div className="personal-list-block__body_empty">
              <p>{t("personal.empty")}</p>
            </div>
          )}
        </div>
      </div>

      <div onClick={props.onCreate} className="personal-list-block__footer">
        <img src={personAdd} alt="" />
        <p>{t("personal.createNew")}</p>
      </div>
    </div>
  );
};

export default Personal;
