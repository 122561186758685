import React from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ReactTooltip from "react-tooltip";

import "./selectCategory.scss";

const SelectCat = (props) => {
  const { cats, value, change, type } = props;
  const { t } = useTranslation();

  const categoriesList = cats.map((cat) =>
    cat.map((item) => {
      const itemHasChildren = item.el.children && item.el.children.length !== 0;

      if (itemHasChildren) {
        const liPaddingLeft = item.level === 0 ? "5px" : `${item.level * 20}px`;
        const liFontWeight = item.level === 0 ? "bold" : "normal";
        const liPadding = "6px";
        const liMargin = "3px 0";

        return (
          <>
            <li
              key={item.el.name}
              style={{
                margin: liMargin,
                padding: liPadding,
                paddingLeft: liPaddingLeft,
                fontWeight: liFontWeight,
                cursor: "not-allowed",
                opacity: 0.38,
              }}
              data-tip
              data-for={`${item.el.name} tooltip`}
            >
              {item.el.name}
            </li>
            <ReactTooltip
              id={`${item.el.name} tooltip`}
              place="top"
              type="warning"
              effect="solid"
            >
              {t("Категорія має інші підкатегорії")}
            </ReactTooltip>
          </>
        );
      }

      const liPaddingLeft = item.level === 0 ? "5px" : `${item.level * 20}px`;
      const liFontWeight = item.level === 0 ? "bold" : "normal";
      const liMargin = "3px 0";

      return (
        <MenuItem
          key={item.el.name}
          value={item.el.id}
          style={{
            paddingLeft: liPaddingLeft,
            fontWeight: liFontWeight,
            margin: liMargin,
          }}
        >
          {item.el.name}
        </MenuItem>
      );
    }),
  );

  if (type && type === "add-prod-type")
    return (
      <FormControl variant="outlined" className="select-category-component">
        <Select
          value={value}
          onChange={(e) => change(e)}
          displayEmpty
          renderValue={
            value === "" || !value
              ? () => (
                  <span className="placeholder">{t("Оберіть категорію")}</span>
                )
              : undefined
          }
        >
          {categoriesList}
        </Select>
      </FormControl>
    );

  return (
    <FormControl
      variant="outlined"
      style={{ minWidth: "120px", width: "auto" }}
    >
      <InputLabel>{t("prod.search.cat")}</InputLabel>
      <Select
        value={value ?? ""}
        onChange={(e) => change(e)}
        label={t("prod.search.cat")}
      >
        <MenuItem value={""} style={{ color: "brown", fontWeight: "bold" }}>
          {t("prod.search.cancel2")}
        </MenuItem>
        {categoriesList}
      </Select>
    </FormControl>
  );
};

export default SelectCat;
