import * as yup from "yup";
import { inputTypes, methodTypes } from "../../dataUI/common/commonConsts";
import { array } from "yup";

export const getPosterMerchant_Fields = (t) => ({
  name: {
    keyName: "name",
    description: t("joinposter.merchantForm.name"),
    errorMessages: {
      required: t("global.errors.required"),
    },
  },
  description: {
    keyName: "description",
    description: t("joinposter.merchantForm.description"),
    errorMessages: {
      required: t("global.errors.required"),
    },
  },
  category_id: {
    keyName: "category_id",
    description: t("joinposter.merchantForm.category_id"),
    errorMessages: {
      required: t("global.errors.required"),
    },
    listData: [
      { value: 1, text: t("joinposter.merchantForm.categories.1") },
      { value: 2, text: t("joinposter.merchantForm.categories.2") },
      { value: 3, text: t("joinposter.merchantForm.categories.3") },
      { value: 4, text: t("joinposter.merchantForm.categories.4") },
    ],
  },
  category_ids: {
    keyName: "category_ids",
    description: t("joinposter.merchantForm.category_id"),
    errorMessages: {
      required: t("global.errors.required"),
    },
    listData: [
      { value: 1, text: t("joinposter.merchantForm.categories.1") },
      { value: 2, text: t("joinposter.merchantForm.categories.2") },
      { value: 3, text: t("joinposter.merchantForm.categories.3") },
      { value: 4, text: t("joinposter.merchantForm.categories.4") },
    ],
  },
});

export const getPosterStore_Fields = (t) => ({
  name: {
    keyName: "name",
    description: t("joinposter.storeForm.name"),
    errorMessages: {
      required: t("global.errors.required"),
    },
  },
  address: {
    keyName: "address",
    description: t("joinposter.storeForm.address"),
    errorMessages: {
      required: t("global.errors.required"),
    },
  },
  paymentMerchantId: {
    keyName: "paymentMerchantId",
    description: t("joinposter.storeForm.paymentMerchantId"),
    errorMessages: {
      required: t("joinposter.storeForm.payment_id_error"),
    },
  },
  paymentTerminalId: {
    keyName: "paymentTerminalId",
    description: t("joinposter.storeForm.paymentTerminalId"),
    errorMessages: {
      required: t("joinposter.storeForm.payment_id_error"),
    },
  },
  regionId: {
    keyName: "regionId",
    description: t("joinposter.merchantForm.regionId"),
    errorMessages: {
      required: t("global.errors.required"),
    },
    listData: [
      { value: 0, text: "Невідомий" },
      { value: 1, text: "Київська обл." },
      { value: 2, text: "Кіровоградська обл." },
      { value: 3, text: "АР Крим" },
      { value: 4, text: "Вінницька обл." },
      { value: 5, text: "Одеська обл." },
      { value: 6, text: "Дніпропетровська обл." },
      { value: 7, text: "Чернігівська обл." },
      { value: 8, text: "Харківська обл." },
      { value: 9, text: "Житомирська обл." },
      { value: 10, text: "Полтавська обл." },
      { value: 11, text: "Херсонська обл." },
      { value: 12, text: "Запорізька обл." },
      { value: 13, text: "Луганська обл." },
      { value: 14, text: "Донецька обл." },
      { value: 15, text: "Миколаївська обл." },
      { value: 16, text: "Сумська обл." },
      { value: 17, text: "Львівська обл." },
      { value: 18, text: "Черкаська обл." },
      { value: 19, text: "Хмельницька обл." },
      { value: 20, text: "Волинська обл." },
      { value: 21, text: "Рівненська обл." },
      { value: 22, text: "Івано-Франківська обл." },
      { value: 23, text: "Тернопільська обл." },
      { value: 24, text: "Закарпатська обл." },
      { value: 25, text: "Чернівецька обл." },
    ],
  },
});

// validation
function checkField_onlyNums(value) {
  const regexp = new RegExp(`^[0-9]+[0-9]*$`);
  return regexp.test(value);
}

export const getPosterMerchantSchema = (t) => {
  const fields = getPosterMerchant_Fields(t);

  return yup.object().shape({
    name: yup.string().required(fields.name.errorMessages.required),
    description: yup
      .string()
      .required(fields.description.errorMessages.required),
    category_ids: yup
      .array()
      .required(fields.category_id.errorMessages.required),
  });
};

export const getPosterStoreSchema = (t) => {
  const fields = getPosterStore_Fields(t);

  return yup.object().shape({
    name: yup.string().required(fields.name.errorMessages.required),
    address: yup.string().required(fields.address.errorMessages.required),
    paymentMerchantId: yup
      .string()
      .required(fields.paymentMerchantId.errorMessages.required)
      .min(8, fields.paymentMerchantId.errorMessages.required)
      .max(8, fields.paymentMerchantId.errorMessages.required)
      .test(
        "",
        fields.paymentMerchantId.errorMessages.required,
        checkField_onlyNums,
      ),
    paymentTerminalId: yup
      .string()
      .required(fields.paymentTerminalId.errorMessages.required)
      .min(8, fields.paymentMerchantId.errorMessages.required)
      .max(8, fields.paymentMerchantId.errorMessages.required)
      .test(
        "",
        fields.paymentMerchantId.errorMessages.required,
        checkField_onlyNums,
      ),
    regionId: yup.number().required(fields.regionId.errorMessages.required),
  });
};
