import { validation } from "../../../../../../../tools/validation";

export const onChangeInput = (event, name, compData) => {
  const { isNumber, form, setForm, setFormIsValid, setFormError } = compData;

  if (!event.target.value && event.target.value !== "") {
    return;
  }
  const value = isNumber
    ? +event.target.value < 0
      ? 0
      : +event.target.value
    : event.target.value;

  const newForm = [...form];
  const index = newForm.indexOf(newForm.filter((el) => el.name === name)[0]);
  newForm[index] = {
    ...newForm[index],
    value: value,
    valid: validation(
      event.target.value,
      newForm[index].rules,
      newForm[index].error,
    ),
    touched: true,
  };
  setForm(newForm);
  let i = 0;
  const formWithErrors = JSON.parse(JSON.stringify(newForm)).reverse();
  setFormIsValid(true);
  formWithErrors.forEach((el) => {
    if (el.valid !== true) {
      setFormIsValid(false);
      if (el.touched) {
        setFormError(el.error);
        i++;
      }
    }
  });
  if (i === 0) {
    setFormError(null);
  }
};

export const onChangeSize = (value, compData) => {
  const { setSize } = compData;
  const settingValue = +value < 0 ? 0 : +value;

  setSize(settingValue);
};
