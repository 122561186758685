import React from "react";

// header parts
import HeaderTop from "./HeaderTop/HeaderTop";
import HeaderBottom from "./HeaderBottom/HeaderBottom";

import "./Header.scss";

const Header = (props) => {
  return (
    <div className="header">
      <HeaderTop logout={props.logout} />
      <HeaderBottom />
    </div>
  );
};

export default Header;
