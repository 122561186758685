import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import {
  OrderToWorkContent,
  OrderCancelContent,
  OrderPendingContent,
  OrderInProcessContent,
  OrderCompletedContent,
  OrderIssueContent,
  OrderReturnedContent,
} from "../../../components/Modal/ModalTemplates/Order/Order";

import OrdersList from "../../../components/OrdersList/OrdersList";
import ShopsList from "../../../components/Shops/ShopsList/ShopsList";
import ConsistList from "../../../components/ConsistList/ConsistList";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Modal from "../../../components/UI/Modal/Modal";

import "./mainPage.scss";
import $api from "../../../api/http";
import shop from "../../../assets/images/components/ShopsList/shop.svg";
import search from "../../../assets/images/components/ShopsList/search.svg";
import { useTranslation } from "react-i18next";

const MainPage = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const [shops, setShops] = useState(null);
  const [selectedShop, setSelectedShop] = useState(null);

  const [orders, setOrders] = useState([]);
  const ordersPerPage = 10;
  const [ordersOffset, setOrdersOffset] = useState(0);
  const [orderFilter, setOrderFilter] = useState("number");

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderToWorkMode, setOrderToWorkMode] = useState(null);
  const [orderCancelMode, setOrderCancelMode] = useState(null);
  const [orderPendingMode, setOrderPendingMode] = useState(null);
  const [orderInProcessMode, setOrderInProcessMode] = useState(null);
  const [orderCompletedMode, setOrderCompletedMode] = useState(null);
  const [orderIssuedMode, setOrderIssuedMode] = useState(null);
  const [orderReturnedMode, setOrderReturnedMode] = useState(null);
  const [searchString, setSearchString] = useState("");

  const { shopId, merchantId } = props;

  /*TEST*/
  const info = JSON.parse(localStorage.getItem("info"));

  // render orders on pageStart
  useEffect(() => {
    const getAllShops = async () => {
      try {
        setLoading(true);
        const shopsData = await $api.get(
          `/Merchants/${merchantId}/shops?limit=1000&offset=0`,
        );
        if (![-1, 1].includes(info.roleId)) {
          const cashierShops = shopsData.data.results.filter((el) =>
            info.shopIds.includes(el.id),
          );
          setShops(cashierShops);
        } else {
          setShops(shopsData.data.results);
        }
        /**/
      } catch (err) {
        props.setError(true);
      } finally {
        setLoading(false);
      }
    };

    const loadAllOrdersDependOnParams = (interval) => {
      let searchNum = props.searchNum;

      // when we coming from notification order
      if (location.state && location.state.selectedRecord) {
        props.setReduxSearchNum(+location.state.selectedRecord.actionArgs);
        searchNum = +location.state.selectedRecord.actionArgs;
      }

      /*TEST*/
      let shopIdsUrl = "";
      info.shopIds.forEach((id) => {
        shopIdsUrl += `shopIds=${id}&`;
      });

      const route = props.mode
        ? searchNum
          ? `/Merchants/${merchantId}/orders?${shopIdsUrl}limit=${ordersPerPage}&offset=0&dateOffset=${props.mode}&orderBy=${orderFilter}&orderId=${searchNum}`
          : `/Merchants/${merchantId}/orders?${shopIdsUrl}limit=${ordersPerPage}&offset=0&orderBy=${orderFilter}&dateOffset=${props.mode}`
        : searchNum
          ? `/Merchants/${merchantId}/orders?${shopIdsUrl}limit=${ordersPerPage}&offset=0&orderBy=${orderFilter}&orderId=${searchNum}`
          : `/Merchants/${merchantId}/orders?${shopIdsUrl}limit=${ordersPerPage}&orderBy=${orderFilter}&offset=0`;

      $api
        .get(route)
        .then((res) => {
          setOrders(res.data);
          ordersLoading && setOrdersLoading(false);
          const interval1 = setInterval(() => {
            setOrdersLoading(true);
            setSelectedOrder(null);
            $api
              .get(route)
              .then((res) => {
                setOrders(res.data);
                setOrdersLoading(false);
              })
              .catch((err) => {
                props.setError(true);
                setOrdersLoading(false);
              });
          }, 180000);
          interval = interval1;
        })
        .catch((err) => {
          props.setError(true);
          setOrdersLoading(false);
        });
    };

    let interval;

    const renderOrdersOnStart = async () => {
      if (!shopId) {
        await getAllShops();
      } else {
        await loadAllOrdersDependOnParams(interval);
      }
    };

    renderOrdersOnStart();

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if ((location.state && location.state.selectedRecord) || !selectedShop) {
      location.state = null;
      return;
    }

    let interval;
    const currentShopId = shopId || (selectedShop && selectedShop.id);

    /*TEST*/
    /*let shopIdsUrl = '';
    info.shopIds.forEach((id) => {
      shopIdsUrl += `shopIds=${id}&`;
    })*/
    /*TEST*/
    const shopIdsUrl = `shopIds=${selectedShop.id}&`;

    const route = props.mode
      ? props.searchNum
        ? `/Merchants/${merchantId}/orders?${shopIdsUrl}limit=${ordersPerPage}&offset=${ordersOffset}&dateOffset=${props.mode}&orderBy=${orderFilter}&orderId=${props.searchNum}`
        : `/Merchants/${merchantId}/orders?${shopIdsUrl}limit=${ordersPerPage}&offset=${ordersOffset}&orderBy=${orderFilter}&dateOffset=${props.mode}`
      : props.searchNum
        ? `/Merchants/${merchantId}/orders?${shopIdsUrl}limit=${ordersPerPage}&offset=${ordersOffset}&orderBy=${orderFilter}&orderId=${props.searchNum}`
        : `/Merchants/${merchantId}/orders?${shopIdsUrl}limit=${ordersPerPage}&orderBy=${orderFilter}&offset=${ordersOffset}`;
    if (currentShopId) {
      setOrdersLoading(true);
      setSelectedOrder(null);
      $api
        .get(route)
        .then((res) => {
          setOrders(res.data);
          setOrdersLoading(false);
          const interval1 = setInterval(() => {
            setOrdersLoading(true);
            setSelectedOrder(null);
            $api
              .get(route)
              .then((res) => {
                setOrders(res.data);
                setOrdersLoading(false);
              })
              .catch((err) => {
                props.setError(true);
                setOrdersLoading(false);
              });
          }, 180000);
          interval = interval1;
        })
        .catch((err) => {
          props.setError(true);
          setOrdersLoading(false);
        });
    }

    return () => {
      clearInterval(interval);
    };
  }, [
    selectedShop,
    ordersOffset,
    props.mode,
    props.searchNum,
    orderFilter,
    triggerRefresh,
  ]);

  const onSelectNewShop = (value) => {
    setOrdersOffset(0);
    setSelectedOrder(null);
    setSelectedShop(value);
  };

  const addOrderToWork = (id) => {
    const order = [...orders].filter((el) => el.order.id === id)[0];
    setOrderToWorkMode(order);
  };
  const cancelOrder = (id) => {
    const order = [...orders].filter((el) => el.order.id === id)[0];
    setOrderCancelMode(order);
  };
  const onSendToPending = (order) => {
    setOrderPendingMode(order);
  };
  const onSendToProcessIn = (id) => {
    const order = [...orders].filter((el) => el.order.id === id)[0];
    setOrderInProcessMode(order);
  };
  const onCompleteOrder = (id) => {
    const order = [...orders].filter((el) => el.order.id === id)[0];
    setOrderCompletedMode(order);
  };
  const onIssueOrder = (id) => {
    const order = [...orders].filter((el) => el.order.id === id)[0];
    setOrderIssuedMode(order);
  };
  const onReturnOrder = (id) => {
    const order = [...orders].filter((el) => el.order.id === id)[0];
    setOrderReturnedMode(order);
  };

  const onDenyOrder = async (orderId) => {
    const currentShopId = selectedShop ? selectedShop.id : shopId;
    try {
      setOrderToWorkMode(null);
      setOrderCancelMode(null);
      setOrderPendingMode(null);
      setOrderInProcessMode(null);
      setOrderCompletedMode(null);
      setOrderIssuedMode(null);
      setOrderReturnedMode(null);
      setOrderLoading(true);
      const result = await $api.post(
        `/Merchants/${merchantId}/shops/${currentShopId}/orders/${orderId}/remove-transition`,
      );
      const newOrder = result.data;
      const newOrders = [...orders].map((el) => {
        if (el.order.id === newOrder.order.id) return newOrder;
        else return el;
      });
      setOrders(newOrders);
      setSelectedOrder(newOrder);
    } catch (err) {
      props.setError(true);
    } finally {
      setOrderLoading(false);
    }
  };

  const onOrderChange = (value, method) => {
    const order = value;
    const orderId = order.id;
    setOrderToWorkMode(null);
    setOrderCancelMode(null);
    setOrderPendingMode(null);
    setOrderInProcessMode(null);
    setOrderCompletedMode(null);
    setOrderIssuedMode(null);
    setOrderReturnedMode(null);
    setOrderLoading(true);
    const currentShopId = selectedShop ? selectedShop.id : shopId;
    const state = {
      take: 2,
      cancel: 1,
      pending: 4,
      inProcess: 5,
      completed: 6,
      issued: 0,
      returned: 7,
    };
    $api
      .put(
        `/Merchants/${merchantId}/shops/${currentShopId}/orders/${orderId}`,
        {
          state: state[method],
          shoppingCart: method === "pending" ? order.shoppingCart : null,
        },
      )
      .then((res) => {
        const newOrder = res.data;
        const newOrders = [...orders].map((el) => {
          if (el.order.id === newOrder.order.id) return newOrder;
          else return el;
        });
        setOrders(newOrders);
        setSelectedOrder(newOrder);
        setOrderLoading(false);
      })
      .catch((err) => {
        props.setError(true);
        setOrderLoading(false);
      });
  };

  return (
    <>
      <Modal show={orderToWorkMode} onClose={() => setOrderToWorkMode(null)}>
        <OrderToWorkContent
          orderToWorkMode={orderToWorkMode}
          onOrderChange={onOrderChange}
          onClose={() => setOrderToWorkMode(null)}
        />
      </Modal>
      <Modal show={orderCancelMode} onClose={() => setOrderCancelMode(null)}>
        <OrderCancelContent
          orderCancelMode={orderCancelMode}
          onOrderChange={onOrderChange}
          onClose={() => setOrderCancelMode(null)}
        />
      </Modal>
      <Modal show={orderPendingMode} onClose={() => setOrderPendingMode(null)}>
        <OrderPendingContent
          orderPendingMode={orderPendingMode}
          onOrderChange={onOrderChange}
          onClose={() => setOrderPendingMode(null)}
        />
      </Modal>
      <Modal
        show={orderInProcessMode}
        onClose={() => setOrderInProcessMode(null)}
      >
        <OrderInProcessContent
          orderInProcessMode={orderInProcessMode}
          onOrderChange={onOrderChange}
          onClose={() => setOrderInProcessMode(null)}
        />
      </Modal>
      <Modal
        show={orderCompletedMode}
        onClose={() => setOrderCompletedMode(null)}
      >
        <OrderCompletedContent
          orderCompletedMode={orderCompletedMode}
          onOrderChange={onOrderChange}
          onClose={() => setOrderCompletedMode(null)}
        />
      </Modal>
      <Modal show={orderIssuedMode} onClose={() => setOrderIssuedMode(null)}>
        <OrderIssueContent
          orderIssuedMode={orderIssuedMode}
          onOrderChange={onOrderChange}
          onClose={() => setOrderIssuedMode(null)}
        />
      </Modal>
      <Modal
        show={orderReturnedMode}
        onClose={() => setOrderReturnedMode(null)}
      >
        <OrderReturnedContent
          orderReturnedMode={orderReturnedMode}
          onOrderChange={onOrderChange}
          onClose={() => setOrderReturnedMode(null)}
        />
      </Modal>
      {loading && (
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
      {!loading && (
        <div className="main">
          {shopId ? (
            <>
              <div className="main_half">
                <OrdersList
                  {...props}
                  orders={orders}
                  loading={ordersLoading}
                  offset={ordersOffset}
                  onRefresh={() => setTriggerRefresh((tr) => !tr)}
                  setOffset={setOrdersOffset}
                  orderFilter={orderFilter}
                  setOrderFilter={setOrderFilter}
                  ordersPerPage={ordersPerPage}
                  orderLoading={orderLoading}
                  selectedOrder={selectedOrder}
                  setSelectedOrder={(v) => setSelectedOrder(v)}
                  addOrderToWork={addOrderToWork}
                />
              </div>
              <div className="main_half">
                {selectedOrder && (
                  <ConsistList
                    {...props}
                    selectedShop={selectedShop}
                    order={selectedOrder}
                    orderLoading={orderLoading}
                    addOrderToWork={addOrderToWork}
                    cancelOrder={cancelOrder}
                    onDenyOrder={onDenyOrder}
                    onSendToPending={onSendToPending}
                    onSendToProcessIn={onSendToProcessIn}
                    onCompleteOrder={onCompleteOrder}
                    onIssueOrder={onIssueOrder}
                    onReturnOrder={onReturnOrder}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div className="main_places">
                <div className="main_places__header">
                  <div className="main_places__header_title">
                    <img src={shop} alt="orders" />
                    <h2>{t("shopsList.tabName1")}</h2>
                  </div>
                  <div className="main_places__header_subtitle">
                    <label className="search" htmlFor="search_shops_list">
                      <label htmlFor="search_shops_list">
                        <img src={search} alt="" />
                      </label>
                      <input
                        type="text"
                        id="search_shops_list"
                        placeholder={t("Название торговой точки, город, улица")}
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                      />
                    </label>
                  </div>
                </div>

                <ShopsList
                  {...props}
                  shops={shops}
                  searchString={searchString}
                  selectedShop={selectedShop}
                  setSelectedShop={
                    orderLoading ? () => {} : (v) => onSelectNewShop(v)
                  }
                />
              </div>
              <div className="main_orders">
                {selectedShop && (
                  <OrdersList
                    {...props}
                    orders={orders}
                    onRefresh={() => setTriggerRefresh((tr) => !tr)}
                    loading={ordersLoading}
                    offset={ordersOffset}
                    orderFilter={orderFilter}
                    setOrderFilter={setOrderFilter}
                    setOffset={setOrdersOffset}
                    ordersPerPage={ordersPerPage}
                    orderLoading={orderLoading}
                    selectedOrder={selectedOrder}
                    setSelectedOrder={(v) => setSelectedOrder(v)}
                    addOrderToWork={addOrderToWork}
                  />
                )}
              </div>
              <div className="main_menu">
                {selectedOrder && (
                  <ConsistList
                    {...props}
                    selectedShop={selectedShop}
                    order={selectedOrder}
                    orderLoading={orderLoading}
                    addOrderToWork={addOrderToWork}
                    onDenyOrder={onDenyOrder}
                    cancelOrder={cancelOrder}
                    onSendToPending={onSendToPending}
                    onSendToProcessIn={onSendToProcessIn}
                    onCompleteOrder={onCompleteOrder}
                    onIssueOrder={onIssueOrder}
                    onReturnOrder={onReturnOrder}
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  mode: state.mode.mode,
  searchNum: state.mode.searchNum,
  merchants: state.admin.merchants,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxSearchNum: (int) => dispatch(actions.setSearchOrderNumber(int)),
  setSelMerchant: (val) => dispatch(actions.setSelectedMerchant(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
