import React from "react";
import { useTranslation } from "react-i18next";

const ClientInfo = ({ order }) => {
  const { t } = useTranslation();

  return (
    <div className="client-info">
      <p>
        {t("orDet.client.title")}
        <span>{order.user.fn}</span>
      </p>
      <span
        style={{
          marginLeft: "50px",
          marginTop: "5px",
        }}
      >
        {order.user.phn}
      </span>
      {order.order.properties &&
        order.order.properties.desirable_delivery_time && (
          <p className="bazaniy_time">
            {t("orDet.client.bazaniy_time")}
            <span>{order.order.properties.desirable_delivery_time}</span>
          </p>
        )}
      {order.order.properties && order.order.properties.comment && (
        <p className="comment">
          {t("orDet.client.comment")}
          <span>{order.order.properties.comment}</span>
        </p>
      )}
    </div>
  );
};

export default ClientInfo;
