import React from "react";

const ServerError = (props) => {
  const mainDivStyle = {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  };
  const errorBlockStyle = {
    width: "450px",
    borderRadius: "10px",
    border: "3px solid #da4e3b",
    backgroundColor: "#fef2f4",
    marginTop: "40px",
    display: "flex",
    flexDirection: "column",
  };
  const paragErrorStyle = {
    margin: "20px",
    color: "#da4e3b",
    letterSpacing: "1px",
  };
  const buttonStyle = {
    alignSelf: "center",
    marginBottom: "20px",
    padding: "5px 15px",
    border: "none",
    backgroundColor: "#da4e3b",
    color: "#fff",
    cursor: "pointer",
    borderRadius: "3px",
  };

  return (
    <div style={mainDivStyle}>
      <div style={errorBlockStyle}>
        <p style={paragErrorStyle}>{props.title}</p>
        <button style={buttonStyle} onClick={() => window.location.reload()}>
          {"OK"}
        </button>
      </div>
    </div>
  );
};

export default ServerError;
