import React from "react";
import { useTranslation } from "react-i18next";

// icon
import close from "../../../../assets/images/pages/Products/ProdForm/close.svg";
import { ReactComponent as ArchiveIcon } from "../../../../assets/images/components/Notifications/archive.svg";
import { ReactComponent as ArchiveWhiteIcon } from "../../../../assets/images/components/Notifications/archive-white.svg";
import { ReactComponent as CloseBlueIcon } from "../../../../assets/images/components/Notifications/close-blue.svg";

export const ArchiveStack = (props) => {
  const { t } = useTranslation();
  const { onClose, onArchiveStack, selectedRecords } = props;

  return (
    <div className="modal-window-archive-single-modal">
      <div className="close-div" onClick={onClose}>
        <p>{t("createProd.closeLabel")}</p>
        <img src={close} alt="" />
      </div>
      <div className="title-block">
        <ArchiveIcon />
        <h2>{t("Архівування повідомлень")}</h2>
      </div>
      <p>{`Ви точно бажаєте архівувати ${selectedRecords.length} повідомленнь-(ння)?`}</p>
      <div className="buttons">
        <button onClick={onArchiveStack} className="action">
          <ArchiveWhiteIcon />
          <span>{t("Архівувати")}</span>
        </button>
        <button onClick={onClose} className="cancel">
          <CloseBlueIcon />
          <span>{t("Відмінити")}</span>
        </button>
      </div>
    </div>
  );
};
