import $api from "../../api/http";

class Users {
  onEnableById(id) {
    const route = `/Accounts/${id}/unlock`;
    return $api.post(route);
  }
}

export default Users;
