import * as actionTypes from "../actions/actionTypes";

/*
orders modes -> yesterday, week, month, year
*/
const initialState = {
  mode: null,
  searchNum: null,
  notifications_mode: null,
  global_loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CHANGED_ORDER_MODE: {
      return {
        ...state,
        mode: action.mode,
      };
    }
    case actionTypes.SET_SEARCH_ORDER_NUMBER: {
      return {
        ...state,
        searchNum: action.int,
      };
    }
    case actionTypes.SET_NOTIFICATIONS_HISTORY_MODE: {
      return {
        ...state,
        notifications_mode: action.notifications_mode,
      };
    }
    case actionTypes.SET_GLOBAL_LOADING: {
      return {
        ...state,
        global_loading: action.loading,
      };
    }
    default:
      return state;
  }
};

export default reducer;
