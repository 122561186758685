import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";

import NotificationsModel from "../../models/notifications/notifications";
import {
  archiveSingleRecord,
  archiveStackRecords,
  deleteSingleRecord,
  deleteStackRecords,
  changeMessageStatusToDone,
} from "./service";
import * as actions from "../../store/actions/index";

import Modal from "../../components/UI/Modal/Modal";
import { ArchiveSingle } from "./NotificationsModal/ArchiveSingle/ArchiveSingle";
import { ArchiveStack } from "./NotificationsModal/ArchiveStack/ArchiveStack";
import { DeleteSingle } from "./NotificationsModal/DeleteSingle/DeleteSingle";
import { DeleteStack } from "./NotificationsModal/DeleteStack/DeleteStack";
import { SuccessArchivated } from "./NotificationsModal/SuccessArchivated/SuccessArchivated";
import { SuccessDeleted } from "./NotificationsModal/SuccessDeleted/SuccessDeleted";
import { Error } from "./NotificationsModal/Error/Error";
import NotificationsBlock from "./NotificationsBlock/NotificationsBlock";
import SelectFilter from "./SelectFilter/SelectFilter";

import { ReactComponent as FilterIcon } from "../../assets/images/components/Notifications/message-type/filter.svg";
import "./notifications.scss";

const Notifications = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const NotificationReq = new NotificationsModel();
  const messagesLimit = 20;
  const [notificationFilter, setNotificationFilter] = useState(0);
  const [records, setRecords] = useState([]);
  const [recordsOffset, setRecordsOffset] = useState(0);
  const [isLimit, setIsLimit] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState([]);
  // ui
  const [loading, setLoading] = useState(false);
  const [showArchiveSingleModal, setShowArchiveSingleModal] = useState(null);
  const [showArchiveStackModal, setShowArchiveStackModal] = useState(false);
  const [showDeleteSingleModal, setShowDeleteSingleModal] = useState(null);
  const [showDeleteStackModal, setShowDeleteStackModal] = useState(false);

  const [successArchivated, setSuccessArchivated] = useState(false);
  const [successDeleted, setSuccessDeleted] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    function setDefaultStartRecords() {
      setRecordsOffset(0);
      setSelectedRecords([]);
      setIsLimit(false);

      onGetAllNotificationsOnStart();
    }

    setDefaultStartRecords();

    const interval = setInterval(() => {
      setDefaultStartRecords();
    }, 180000);

    return () => {
      clearInterval(interval);
    };
  }, [props.notifications_mode, notificationFilter]);

  return (
    <div className="notifications-page">
      <Modal
        show={showArchiveSingleModal}
        onClose={() => setShowArchiveSingleModal(null)}
      >
        <ArchiveSingle
          onClose={() => setShowArchiveSingleModal(null)}
          onArchiveSingle={() => onArchiveSingle(showArchiveSingleModal)}
        />
      </Modal>
      <Modal
        show={showArchiveStackModal}
        onClose={() => setShowArchiveStackModal(false)}
      >
        <ArchiveStack
          onClose={() => setShowArchiveStackModal(false)}
          onArchiveStack={onArchiveStack}
          selectedRecords={selectedRecords}
        />
      </Modal>
      <Modal
        show={showDeleteSingleModal}
        onClose={() => setShowDeleteSingleModal(null)}
      >
        <DeleteSingle
          onClose={() => setShowDeleteSingleModal(null)}
          onDeleteSingle={() => onDeleteSingle(showDeleteSingleModal)}
        />
      </Modal>
      <Modal
        show={showDeleteStackModal}
        onClose={() => setShowDeleteStackModal(false)}
      >
        <DeleteStack
          onClose={() => setShowDeleteStackModal(false)}
          onDeleteStack={onDeleteStack}
          selectedRecords={selectedRecords}
        />
      </Modal>
      <Modal
        show={successArchivated}
        onClose={() => setSuccessArchivated(false)}
      >
        <SuccessArchivated onClose={() => setSuccessArchivated(false)} />
      </Modal>
      <Modal show={successDeleted} onClose={() => setSuccessDeleted(false)}>
        <SuccessDeleted onClose={() => setSuccessDeleted(false)} />
      </Modal>
      <Modal show={error} onClose={() => setError(false)}>
        <Error onClose={() => setError(false)} />
      </Modal>
      <div className="select-filter-block">
        <div className="label">
          <FilterIcon />
          <span>{"Показать:"}</span>
        </div>
        <SelectFilter
          notificationFilter={notificationFilter}
          setNotificationFilter={setNotificationFilter}
          loading={loading}
        />
      </div>
      <NotificationsBlock
        notificationFilter={notificationFilter}
        records={records}
        selectedRecords={selectedRecords}
        messagesLimit={messagesLimit}
        isLimit={isLimit}
        addSelectedRecord={addSelectedRecord}
        removeSelectedRecord={removeSelectedRecord}
        selectAllRecords={selectAllRecords}
        removeAllSelectedRecords={removeAllSelectedRecords}
        onAddOneMorePartNotifications={onAddOneMorePartNotifications}
        transferSigleRecordToArchive={transferSigleRecordToArchive}
        transferStackRecordsToArchive={transferStackRecordsToArchive}
        transferSigleRecordToDelete={transferSigleRecordToDelete}
        transferStackRecordsToDelete={transferStackRecordsToDelete}
        onRedirectToSingleOrder={onRedirectToSingleOrder}
        onRedirectToSingleProduct={onRedirectToSingleProduct}
        loading={loading}
      />
    </div>
  );

  function addSelectedRecord(newItem) {
    const newSelectedRecords = [...selectedRecords];
    newSelectedRecords.push(newItem);

    setSelectedRecords(newSelectedRecords);
  }

  function removeSelectedRecord(item) {
    const newSelectedRecords = [...selectedRecords].filter(
      (el) => el.id !== item.id,
    );

    setSelectedRecords(newSelectedRecords);
  }

  function selectAllRecords() {
    setSelectedRecords(records);
  }

  function removeAllSelectedRecords() {
    setSelectedRecords([]);
  }

  async function onGetAllNotificationsOnStart() {
    setLoading(true);

    const notificationsResult = await NotificationReq.getMessages({
      type: notificationFilter === "archive" ? 0 : notificationFilter,
      dateOffset: props.notifications_mode,
      limit: messagesLimit,
      offset: recordsOffset,
      isArchive: notificationFilter === "archive",
    });

    setLoading(false);

    if (notificationsResult?.data?.results.length < messagesLimit)
      setIsLimit(true);
    else setIsLimit(false);

    setRecords(
      notificationsResult?.data?.results
        ? notificationsResult.data.results
        : [],
    );
  }

  async function onAddOneMorePartNotifications() {
    const newRecordsOffset = recordsOffset + messagesLimit;
    setRecordsOffset(newRecordsOffset);

    setLoading(true);

    const notificationsResult = await NotificationReq.getMessages({
      type: notificationFilter === "archive" ? 0 : notificationFilter,
      dateOffset: props.notifications_mode,
      limit: messagesLimit,
      offset: newRecordsOffset,
      isArchive: notificationFilter === "archive",
    });

    setLoading(false);

    const addingPart = notificationsResult?.data?.results
      ? notificationsResult.data.results
      : [];
    const newRecords = records.concat(addingPart);

    const list = document.querySelector(".notifications-block-body-items");
    list.scrollTo({
      top: list.clientHeight,
      behavior: "smooth",
    });

    if (addingPart.length < messagesLimit) setIsLimit(true);
    else setIsLimit(false);

    setRecords(newRecords);
  }

  function transferSigleRecordToArchive(record) {
    setShowArchiveSingleModal(record);
  }
  async function onArchiveSingle(record) {
    setShowArchiveSingleModal(null);

    archiveSingleRecord({
      record,
      setGlobalLoading: props.setGlobalLoading,
      successFunction: async () => {
        setRecordsOffset(0);
        setSelectedRecords([]);
        setIsLimit(false);

        await onGetAllNotificationsOnStart();

        setSuccessArchivated(true);
      },
      errorFunction: () => setError(true),
    });
  }

  function transferStackRecordsToArchive() {
    setShowArchiveStackModal(true);
  }
  async function onArchiveStack() {
    setShowArchiveStackModal(false);

    archiveStackRecords({
      selectedRecords,
      setGlobalLoading: props.setGlobalLoading,
      successFunction: async () => {
        setRecordsOffset(0);
        setSelectedRecords([]);
        setIsLimit(false);

        await onGetAllNotificationsOnStart();

        setSuccessArchivated(true);
      },
      errorFunction: () => setError(true),
    });
  }

  function transferSigleRecordToDelete(record) {
    setShowDeleteSingleModal(record);
  }
  function onDeleteSingle(record) {
    setShowDeleteSingleModal(null);

    deleteSingleRecord({
      record,
      setGlobalLoading: props.setGlobalLoading,
      successFunction: async () => {
        setRecordsOffset(0);
        setSelectedRecords([]);
        setIsLimit(false);

        await onGetAllNotificationsOnStart();

        setSuccessDeleted(true);
      },
      errorFunction: () => setError(true),
    });
  }

  function transferStackRecordsToDelete() {
    setShowDeleteStackModal(true);
  }
  function onDeleteStack() {
    setShowDeleteStackModal(false);

    deleteStackRecords({
      selectedRecords,
      setGlobalLoading: props.setGlobalLoading,
      successFunction: async () => {
        setRecordsOffset(0);
        setSelectedRecords([]);
        setIsLimit(false);

        await onGetAllNotificationsOnStart();

        setSuccessDeleted(true);
      },
      errorFunction: () => setError(true),
    });
  }

  async function onRedirectToSingleOrder(record) {
    await changeMessageStatusToDone({
      record,
      setGlobalLoading: props.setGlobalLoading,
      errorFunction: () => setError(true),
    });

    history.push({
      pathname: "/main/orders",
      state: {
        selectedRecord: record,
      },
    });
  }

  async function onRedirectToSingleProduct(record) {
    await changeMessageStatusToDone({
      record,
      setGlobalLoading: props.setGlobalLoading,
      errorFunction: () => setError(true),
    });

    history.push({
      pathname: "/main/storage/products",
      state: {
        selectedRecord: record,
      },
    });
  }
};

const mapStateToProps = (state) => ({
  notifications_mode: state.mode.notifications_mode,
});

const mapDispatchToProps = (dispatch) => ({
  setGlobalLoading: (val) => dispatch(actions.setGlobalLoading(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
