import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Auth from "../../pages/Auth/Auth";
import { authUrls } from "../../dataUI/common/commonConsts";

const UnAuthorizedRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/:authPath"
        render={(props) => (
          <Auth
            {...props}
            joinPosterAuth={true}
            joinPosterStep={authUrls.REG_CONFIRM}
          />
        )}
      />
      <Redirect to="/auth" />
    </Switch>
  );
};

export default UnAuthorizedRoutes;
