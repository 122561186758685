import $api from "../../../api/http";

class Category {
  constructor(merchantId, shopId) {
    this.merchantId = merchantId;
    this.shopId = shopId;
  }

  getTopLevel() {
    const { merchantId, shopId } = this;
    return $api.get(
      `/Merchants/${merchantId}/shops/${shopId}/productcategories`,
    );
  }
  getAllProd() {
    const { merchantId, shopId } = this;
    return $api.get(`/Merchants/${merchantId}/shops/${shopId}/categories`);
  }
  getByCatId(id) {
    const { merchantId, shopId } = this;
    return $api.get(
      `/Merchants/${merchantId}/shops/${shopId}/productcategories/${id}`,
    );
  }
  updateByCatId(id, body) {
    const { merchantId, shopId } = this;
    return $api.put(
      `/Merchants/${merchantId}/shops/${shopId}/productcategories/${id}`,
      body,
    );
  }
  deleteById(id) {
    const { merchantId, shopId } = this;
    return $api.delete(
      `/Merchants/${merchantId}/shops/${shopId}/productcategories/${id}`,
    );
  }
  createCat(body) {
    const { merchantId, shopId } = this;
    return $api.post(
      `/Merchants/${merchantId}/shops/${shopId}/productcategories`,
      body,
    );
  }
}

export default Category;
