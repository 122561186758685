import React from "react";
import { useTranslation } from "react-i18next";

// icon
import { ReactComponent as TrashIcon } from "../../../../assets/images/components/Notifications/trash.svg";
import { ReactComponent as ArchiveWhiteIcon } from "../../../../assets/images/components/Notifications/archive-white.svg";
import { ReactComponent as ClearIcon } from "../../../../assets/images/components/Notifications/close-blue.svg";

const NotificationsHeader = (props) => {
  const { t } = useTranslation();
  const {
    notificationFilter,
    selectedRecords,
    removeAllSelectedRecords,
    transferStackRecordsToArchive,
    transferStackRecordsToDelete,
    loading,
  } = props;

  return (
    <div className="notifications-block-header">
      <div className="buttons">
        {selectedRecords.length !== 0 && (
          <button
            className="clear-button"
            onClick={loading ? () => {} : removeAllSelectedRecords}
          >
            <ClearIcon />
            <span>{t("Отменить выделение")}</span>
          </button>
        )}
        {selectedRecords.length !== 0 && notificationFilter === "archive" && (
          <button
            className="delete-button"
            onClick={loading ? () => {} : transferStackRecordsToDelete}
          >
            <TrashIcon />
            <span>{t("Удалить")}</span>
          </button>
        )}
        {selectedRecords.length !== 0 && notificationFilter !== "archive" && (
          <button
            className="archive-button"
            onClick={loading ? () => {} : transferStackRecordsToArchive}
          >
            <ArchiveWhiteIcon />
            <span>{t("В архив")}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default NotificationsHeader;
