import * as actionTypes from "./actionTypes";
import AuthService from "../../api/services/AuthService";

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("info");
  localStorage.removeItem("selectedShop");
  localStorage.removeItem("selectedMerchant");
  localStorage.removeItem("merchants");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const setLoading = (loading) => {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
};

export const refreshAuthToken = () => {
  return (dispatch) => {
    AuthService.refreshToken()
      .then(async (res) => {
        const token = res.data.jwtToken;
        localStorage.setItem("token", token);
      })
      .catch((error) => {
        console.error(error);
        dispatch(logout());
      });
  };
};

export const loginSuccess = (data) => {
  localStorage.setItem("token", data.token);
  data.info && localStorage.setItem("info", JSON.stringify(data.info));

  return {
    type: actionTypes.AUTH_SUCCESS,
    token: data.token,
    expiresIn: data.expiresIn,
    info: data.info,
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    } else {
      dispatch(refreshAuthToken());
    }
  };
};
