// auth
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const SET_MERCHANTS = "SET_MERCHANTS";
export const UPDATE_MERCHANTS = "UPDATE_MERCHANTS";
export const SET_SEL_MERCHANT = "SET_SEL_MERCHANT";
export const SET_SEL_SHOP = "SET_SEL_SHOP";

export const SET_CHANGED_ORDER_MODE = "SET_CHANGED_ORDER_MODE";
export const SET_SEARCH_ORDER_NUMBER = "SET_SEARCH_ORDER_NUMBER";
export const SET_NOTIFICATIONS_HISTORY_MODE = "SET_NOTIFICATIONS_HISTORY_MODE";

export const SET_LOADING = "SET_LOADING";

export const SET_GLOBAL_LOADING = "SET_GLOBAL_LOADING";
