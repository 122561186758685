import React from "react";
import "./styles.scss";
import instruction_img from "../../../assets/images/components/InstructionPage/instruction.png";

const InstructionPage = () => {
  return (
    <div className="InstructionPage">
      <h2 className="InstructionPage_Title">
        SAMO - керування обліковим записом.
        <br /> Видалення облікового запису.
      </h2>

      <div className="PersonalData">
        <div className="InstructionPage_pBox">
          Застосунок використовує наступні персональні дані користувачів:
        </div>
        <ul>
          <li>Імʼя</li>
          <li>Номер телефону</li>
          <li>Email</li>
        </ul>
      </div>

      <div className="InstructionPage_pBox">
        Ці дані використовуються як застосунком, так і надаються нашим партнерам
        для обробки Ваших замовлень.
      </div>

      <div className="InstructionPage_pBox">
        Для видалення цих даних так облікового запису будь ласка увійдіть в
        застосунок, відкрийте профіль та оберіть Видалити обліковий запис.
      </div>

      <div className="InstructionPage_ImageWrap">
        <img src={instruction_img} alt="instruction" />
      </div>

      <div className="InstructionPage_pBox">
        Після цього Ваш обліковий запис буде деактивовано, а дані будуть
        видалені протягом 30 днів.
      </div>

      <div style={{ paddingBottom: "40px" }} className="InstructionPage_pBox">
        Щиро дякуємо за використання нашого сервісу
      </div>
    </div>
  );
};

export default InstructionPage;
