import Notifications from "../../models/notifications/notifications";

export const archiveSingleRecord = (data) => {
  const { record, setGlobalLoading, successFunction, errorFunction } = data;

  const notificationsModel = new Notifications();

  setGlobalLoading(true);

  notificationsModel
    .archiveSingleRecord(record)
    .then(() => successFunction())
    .catch(() => errorFunction())
    .finally(() => setGlobalLoading(false));
};

export const archiveStackRecords = (data) => {
  const { selectedRecords, setGlobalLoading, successFunction, errorFunction } =
    data;

  const notificationsModel = new Notifications();

  setGlobalLoading(true);

  notificationsModel
    .archiveStackRecords(selectedRecords)
    .then(() => successFunction())
    .catch(() => errorFunction())
    .finally(() => setGlobalLoading(false));
};

export const deleteSingleRecord = (data) => {
  const { record, setGlobalLoading, successFunction, errorFunction } = data;

  const notificationsModel = new Notifications();

  setGlobalLoading(true);

  notificationsModel
    .deleteSingleRecord(record)
    .then(() => successFunction())
    .catch(() => errorFunction())
    .finally(() => setGlobalLoading(false));
};

export const deleteStackRecords = (data) => {
  const { selectedRecords, setGlobalLoading, successFunction, errorFunction } =
    data;

  const notificationsModel = new Notifications();

  setGlobalLoading(true);

  notificationsModel
    .deleteStackRecords(selectedRecords)
    .then(() => successFunction())
    .catch(() => errorFunction())
    .finally(() => setGlobalLoading(false));
};

export const changeMessageStatusToDone = async (data) => {
  const { record, setGlobalLoading, errorFunction } = data;

  const notificationsModel = new Notifications();

  setGlobalLoading(true);

  notificationsModel
    .changeMessageStatusToDone(record.id)
    .catch(() => errorFunction())
    .finally(() => setGlobalLoading(false));
};
