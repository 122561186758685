import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import * as actions from "../../../../../../store/actions/index";

const NotificationsDateLinks = (props) => {
  const { t } = useTranslation();

  return (
    <div className="header__bottom__history" style={{ padding: "15px 0" }}>
      <p>{"История сообщений:"}</p>
      <div className="header__bottom__history_date">
        <p
          onClick={() => props.setNotificationsHistoryMode(null)}
          className={
            props.notifications_mode === null ? "active_border" : "dis_border"
          }
        >
          {t("header.bottom.history.1")}
        </p>
        <p
          onClick={() => props.setNotificationsHistoryMode("yesterday")}
          className={
            props.notifications_mode === "yesterday"
              ? "active_border"
              : "dis_border"
          }
        >
          {t("header.bottom.history.2")}
        </p>
        <p
          onClick={() => props.setNotificationsHistoryMode("week")}
          className={
            props.notifications_mode === "week" ? "active_border" : "dis_border"
          }
        >
          {t("header.bottom.history.3")}
        </p>
        <p
          onClick={() => props.setNotificationsHistoryMode("month")}
          className={
            props.notifications_mode === "month"
              ? "active_border"
              : "dis_border"
          }
        >
          {t("header.bottom.history.4")}
        </p>
        <p
          onClick={() => props.setNotificationsHistoryMode("year")}
          className={
            props.notifications_mode === "year" ? "active_border" : "dis_border"
          }
        >
          {t("header.bottom.history.5")}
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  notifications_mode: state.mode.notifications_mode,
});

const mapDispatchToProps = (dispatch) => ({
  setNotificationsHistoryMode: (val) =>
    dispatch(actions.setNotificationsHistoryMode(val)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsDateLinks);
