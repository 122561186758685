import React from "react";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

import trash from "../../../../../../../assets/images/pages/Products/trash.svg";
import pencil from "../../../../../../../assets/images/pages/Products/pencil.svg";

const ProductItem = (props) => {
  const { product, cats, modal } = props;
  const { t } = useTranslation();
  const cat = cats
    .flat(Infinity)
    .filter((el) => Number(el.el.id) === Number(product.clst[0]))[0];

  const weight = product.v ? product.v + " мл." : product.w + " гр.";

  return (
    <li
      className={modal ? "select-modal" : null}
      onClick={modal ? () => props.onAddModalProd(product) : null}
    >
      <div className="pd-name">
        <p>{product.t}</p>
      </div>
      <div className="pd-image">
        <img alt="" src={product.img} />
      </div>
      <div className="pd-cat">
        <p>{cat && cat.el.name}</p>
      </div>
      <div className="pd-price">
        <p>{product.p}</p>
        <p className="small-label">{"за " + weight}</p>
      </div>
      <div className="pd-count">
        <p>
          {product.q}
          {product.bl ? " г." : " шт."}
        </p>
      </div>
      <div className="cat-tools">
        {!modal && (
          <>
            <div
              className="blue-selector"
              onClick={() => props.onEdit(product.id)}
              data-tip
              data-for="blue-selector-tooltip"
            >
              <img src={pencil} alt="" />
            </div>
            <ReactTooltip
              id="blue-selector-tooltip"
              place="top"
              type="dark"
              effect="solid"
            >
              <span>{t("global.edit")}</span>
            </ReactTooltip>
            <div
              className="red-selector"
              onClick={() =>
                props.onDelete({ id: product.id, name: product.t })
              }
              data-tip
              data-for="red-selector-tooltip"
            >
              <img src={trash} alt="" />
            </div>
            <ReactTooltip
              id="red-selector-tooltip"
              place="top"
              type="dark"
              effect="solid"
            >
              <span>{t("global.delete")}</span>
            </ReactTooltip>
          </>
        )}
      </div>
    </li>
  );
};

export default ProductItem;
