import React, { useEffect } from "react";
import "./JoinPosterRegistration.scss";
import { joinPosterConfirmUrl, joinposterId } from "../../../config/config";
import { useTranslation } from "react-i18next";

const JoinPosterRegistration = () => {
  const { t } = useTranslation();

  return (
    <div className="JoinPosterRegistrationPage">
      <div className="JoinPosterRegistration">
        <h2 className="JoinPosterRegistration_Title">
          Реєстрація закладу у мобільному додатку для самовивозу Samo
        </h2>

        <div className="JoinPosterRegistration_pBox">
          <div>
            Для розміщення закладу у мобільному додатку для самовивозу Samo від
            Ощадбанку потрібно:
            <br />
            1. Перед підтвердженням авторизації через joinposter (кнопка нижче){" "}
            <a
              href="https://samoapp.com.ua/malyy-seredniy-biznes/"
              target="_blank"
            >
              зв'язатися з регіональним менеджером
            </a>{" "}
            Ощадбанку для підписання договору інтернет еквайрингу та підключення
            для можливості приймати оплати у додатку. Після укладання угод, ви
            отримаєте номер банківського терміналу, який потрібно буде внести на
            наступній сторінці для прийому платежів. Лише після виконання
            попередніх кроків, потрібно натискати joinposter (кнопка нижче).
            <br />
            2. Натиснувши підтвердження авторизації через joinposter, ви
            пройдете процедуру імпорту закладів та товарів до мобільного додатку
            Samo. Після успішного імпорту, з’явиться відповідне повідомлення на
            екрані. Натисніть кнопку “Продовжити” У разі помилки - потрібно
            зв’язатися з технічною підтримкою.
            <br />
            3. Для отримання оплати, на останній сторінці потрібно буде внести
            номери терміналу для кожної торгової точки окремо (якщо термінали
            різні) або один номер (якщо для всіх торгових точок термінал
            однаковий) і натиснути кнопку “Підтвердити” Після успішного
            збереження, заклад зʼявиться серед пропозицій для покупців у
            мобільному додатку для самовивозу Samo.
            <br />
            <a
              href="https://samoapp.com.ua/malyy-seredniy-biznes/"
              target="_blank"
            >
              FAQ
            </a>{" "}
            за посиланням.
            <br />З технічних питань звертатись:{" "}
            <a type="email" href="mailto:samo@ukrpay.net">
              samo@ukrpay.net
            </a>
            <br />З фінансових питань звертатись:{" "}
            <a href="mailto:samo@ukrpay.net">podolskaso@oschadbank.ua</a>
          </div>
        </div>

        <a
          type="submit"
          className="JoinPosterButton"
          href={`https://joinposter.com/api/auth?application_id=${joinposterId}&redirect_uri=${joinPosterConfirmUrl}&response_type=code`}
        >
          {t("auth.posterAuth")}
        </a>
      </div>
    </div>
  );
};

export default JoinPosterRegistration;
