import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

// icons
import basketBlack from "../../../../../../assets/images/wrapper/header/basket_black.svg";
import basketBlue from "../../../../../../assets/images/wrapper/header/basket_active.svg";
import editBlack from "../../../../../../assets/images/wrapper/header/edit_black.svg";
import editBlue from "../../../../../../assets/images/wrapper/header/edit_active.svg";

const AdminHeaderLinks = (props) => {
  const { t } = useTranslation();
  const { basketColor, editColor } = props;

  return (
    <div className="header__top__links">
      <NavLink to="/main/" className="link" activeClassName="link_active">
        <img alt="" src={editColor !== "black" ? basketBlack : basketBlue} />
        {window.innerWidth >= 500 && <p>{t("header.merchant")}</p>}
      </NavLink>
      <NavLink
        to="/admin/"
        exact
        className="link"
        activeClassName="link_active"
      >
        <img alt="" src={editColor === "black" ? editBlack : editBlue} />
        {window.innerWidth >= 500 && <p>{t("header.admin")}</p>}
      </NavLink>
    </div>
  );
};

export default AdminHeaderLinks;
