import React from "react";
import closeAlertIcon from "../../../assets/images/components/common/closeAlertIcon.svg";
import "./Alert.scss";
import "../../../app.scss";

const ChoosingAlert = ({ alertInfo, closeAlert }) => {
  const firstChoiceClickHandler = () => {
    closeAlert();
    alertInfo.secondChoice_confirmF && alertInfo.secondChoice_confirmF();
  };
  const secondChoiceClickHandler = () => {
    closeAlert();
    alertInfo.firstChoice_confirmF && alertInfo.firstChoice_confirmF();
  };

  return (
    <div
      className={`ChoosingAlertBox Animation_Opacity_0to1_04s`}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <img
        className="AlertCloseIcon"
        src={closeAlertIcon}
        alt="закрити"
        onClick={closeAlert}
      />
      <h3 className="AlertTitle">{alertInfo.titleText}</h3>
      <div className="AlertDescription">{alertInfo.descriptionText}</div>

      <div className="ButtonsWrap">
        <div className="ButtonCommon" onClick={secondChoiceClickHandler}>
          {alertInfo.firstChoice_buttonText}
        </div>
        <div className="ButtonCommon" onClick={firstChoiceClickHandler}>
          {alertInfo.secondChoice_buttonText}
        </div>
      </div>
    </div>
  );
};

export default ChoosingAlert;
