import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

// mui
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import * as actions from "../../../../../../store/actions/index";

const NotificationsDateLinksSmall = (props) => {
  const { t } = useTranslation();

  return (
    <div
      className="header__bottom__history_small"
      style={{ padding: "10px 0" }}
    >
      <span>{t("header.bottom.history.title2")}</span>
      <FormControl variant="filled">
        <Select
          value={
            props.notifications_mode !== null
              ? props.notifications_mode
              : "today"
          }
          onChange={(e) =>
            props.setNotificationsHistoryMode(
              e.target.value === "today" ? null : e.target.value,
            )
          }
        >
          <MenuItem value={"today"}>{t("header.bottom.history.1")}</MenuItem>
          <MenuItem value={"yesterday"}>
            {t("header.bottom.history.2")}
          </MenuItem>
          <MenuItem value={"week"}>{t("header.bottom.history.3")}</MenuItem>
          <MenuItem value={"month"}>{t("header.bottom.history.4")}</MenuItem>
          <MenuItem value={"year"}>{t("header.bottom.history.5")}</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

const mapStateToProps = (state) => ({
  notifications_mode: state.mode.notifications_mode,
});

const mapDispatchToProps = (dispatch) => ({
  setNotificationsHistoryMode: (val) =>
    dispatch(actions.setNotificationsHistoryMode(val)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsDateLinksSmall);
