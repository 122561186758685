import React from "react";
import { useTranslation } from "react-i18next";

// icon
import close from "../../../../assets/images/pages/Products/ProdForm/close.svg";
import { ReactComponent as TrashGrayDeleteIcon } from "../../../../assets/images/components/Notifications/trash-gray-delete.svg";
import { ReactComponent as TrashWhiteIcon } from "../../../../assets/images/components/Notifications/trash-white.svg";
import { ReactComponent as CloseBlueIcon } from "../../../../assets/images/components/Notifications/close-blue.svg";

import "../notificationsModal.scss";

export const DeleteStack = (props) => {
  const { t } = useTranslation();
  const { selectedRecords, onClose, onDeleteStack } = props;

  return (
    <div className="modal-window-delete-notifications-modal">
      <div className="close-div" onClick={onClose}>
        <p>{t("createProd.closeLabel")}</p>
        <img src={close} alt="" />
      </div>
      <div className="title-block">
        <TrashGrayDeleteIcon />
        <h2>{t("УДАЛЕНИЕ СООБЩЕНИЙ")}</h2>
      </div>
      <p>{`${selectedRecords.length} сообщений-(ие) будут удалены безвозвратно.`}</p>
      <p>{"Вы уверены что хотите продолжить?"}</p>
      <div className="buttons">
        <button onClick={onDeleteStack} className="action">
          <TrashWhiteIcon />
          <span>{t("Удалить")}</span>
        </button>
        <button onClick={onClose} className="cancel">
          <CloseBlueIcon />
          <span>{t("Отменить")}</span>
        </button>
      </div>
    </div>
  );
};
