import React from "react";
import Spinner from "../Spinner/Spinner";

import "./globalLoader.scss";

const GlobalLoader = () => (
  <div className="global-loader">
    <Spinner />
  </div>
);

export default GlobalLoader;
