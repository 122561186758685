import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { backendUrl } from "../../../config/config";

import "./userProfile.scss";
import { getUserAva } from "../../../tools/pages/personal/personal";
import { getUserInitials_Fields } from "../../../dataUI/pages/personal";

const UserProfile = (props) => {
  const { t } = useTranslation();
  const {
    firstName,
    lastName,
    middleName,
    roleName,
    title,
    login,
    phone,
    isLocked,
    id,
  } = props.el;

  const userAva = useMemo(() => getUserAva(id), [id]);

  //*TEST
  useEffect(() => {
    props.onEdit && props.onEdit(props.el);
  }, [props.onEdit]);

  /*useEffect(() => {
    id && getAva(id);
  }, [id]);*/

  const onDeleteAccount = () => {
    props.onDelete(props.el.id);
  };

  return (
    <div className="user-profile-container">
      <h2>{t("userProfile.title")}</h2>
      <div className="user-profile-container_header">
        <div className="user-profile-container_header_initials">
          {lastName} {firstName} {middleName}
        </div>
        {userAva && (
          <div>
            <img src={userAva} alt="" />
          </div>
        )}
      </div>
      <div className="user-profile-container_item">
        <p>{t("userProfile.phone")}</p>
        <span>{phone}</span>
      </div>
      <div className="user-profile-container_item">
        <p>{t("userProfile.role")}</p>
        <span>{roleName}</span>
      </div>
      <div className="user-profile-container_item">
        <p>{t("userProfile.status")}</p>
        <span>{title}</span>
      </div>
      <div className="user-profile-container_item">
        <p>{t("userProfile.login")}</p>
        <span>{login}</span>
      </div>
      <div className="user-profile-container_footer">
        <button className="buttonPurple" onClick={() => props.onEdit(props.el)}>
          {t("userProfile.change")}
        </button>
        <button className="red" onClick={onDeleteAccount}>
          {t("userProfile.delete")}
        </button>
        {isLocked && (
          <button
            className="buttonPurple"
            onClick={() => props.onEnable(props.el)}
          >
            {"Разблокировать"}
          </button>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
