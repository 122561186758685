import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { getUserAva } from "../../../tools/pages/personal/personal";
import { useYupValidationResolver } from "../../../tools/common/hooks";
import {
  getPersonalUser_Fields,
  getPersonalUserSchema,
  getUserInitials_Fields,
} from "../../../dataUI/pages/personal";
import RHFInput from "../../common/form/RHFInput";
import { inputTypes, methodTypes } from "../../../dataUI/common/commonConsts";
import { getPersonalRoles } from "../../../tools/common/commonData";
import Spinner from "../../UI/Spinner/Spinner";
import RHFSeveralSelect from "../../common/form/RHFSeveralSelect";
import AccountsService from "../../../api/services/AccountsService";
import { setPromptInfo } from "../../../store/actions/alert/alertActions";
import { useDispatch } from "react-redux";

const EditUser = ({
  onSuccess,
  onError,
  onCancel,
  shops = undefined,
  user = undefined,
  selectedShop,
  methodType,
}) => {
  const dispatch = useDispatch();

  // {imageURL, firstName, lastName, middleName, roleId, title, login, phone, id}
  const [userInfoInit, setUserInfoInit] = useState(undefined);
  const [userShopsList, setUserShopsList] = useState([]);
  const [userAva, setUserAva] = useState(undefined);
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [loading, setLoading] = useState(false);
  const [changePasswordMode, setChangePasswordMode] = useState(
    methodType === methodTypes.CREATE,
  );

  const { t } = useTranslation();
  const userInitials_Fields = useMemo(() => getUserInitials_Fields(t), [t]);
  const personalUser_Fields = useMemo(() => getPersonalUser_Fields(t), [t]);
  const personalRoles = useMemo(() => getPersonalRoles(t), [t]);

  const schema = useMemo(
    () => getPersonalUserSchema(t, methodType),
    [t, methodType],
  );
  const resolver = useYupValidationResolver(schema);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    validateMode: "onChange",
    resolver,
  });

  useEffect(() => {
    if (shops && shops.length) {
      const list = shops.map((shop) => ({ value: shop.id, text: shop.name }));
      setUserShopsList(list);
    }
  }, [shops]);

  useEffect(() => {
    user && user.id && getAvaInit(user.id);

    // set init form values
    if (!userInfoInit) {
      const initValues = {
        firstName: user ? user.firstName : "",
        lastName: user ? user.lastName : "",
        middleName: user ? user.middleName : "",
        phone: user ? user.phone : "",
        role: user ? user.role : personalRoles[0],
        title: user ? user.title : "",
        login: user ? user.login : "",
        shopIds:
          user && user.shopIds
            ? user.shopIds
            : selectedShop
              ? [selectedShop.id]
              : [],
      };

      reset({ ...initValues });
      setUserInfoInit({ ...initValues });
    }
  }, [user, selectedShop, personalRoles]);

  const getAvaInit = async (userId) => {
    const imageFile = await getUserAva(userId);
    imageFile && setUserAva(imageFile);
  };

  const onSelectImage = (e) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFile(file);
      setPreviewUrl(reader.result);
    };

    const file = e.target.files[0];
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setFile(null);
      setPreviewUrl(null);
    }
  };

  const renderInitialsInputs = () => {
    const inputs = [];

    for (let key in userInitials_Fields) {
      const textInput = (
        <RHFInput
          key={key}
          keyName={key}
          description={userInitials_Fields[key].description}
          register={register}
          error={errors[key]}
          inputType={userInitials_Fields[key].type}
          control={control}
          isPhoneNumber={
            userInitials_Fields[key].type === inputTypes.PHONE_NUMBER
          }
        />
      );

      inputs.push(textInput);
    }

    return inputs;
  };

  const updateUser = async (reqData) => {
    try {
      await AccountsService.updateUser(user.id, reqData);
      if (file) {
        try {
          await AccountsService.updateUserFile(user.id, file);
          onSuccess();
        } catch (err) {
          if (err.response && err.response.data && err.response.data.message) {
            dispatch(
              setPromptInfo({
                promptText: `${err.response.data.message}`,
              }),
            );
          } else {
            onError();
          }
        }
      } else {
        onSuccess();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        dispatch(
          setPromptInfo({
            promptText: `${err.response.data.message}`,
          }),
        );
      } else {
        onError();
      }
    }
  };

  const createUser = async (reqData) => {
    try {
      const response = await AccountsService.createUser(reqData);
      if (file && response.data && response.data.id) {
        try {
          await AccountsService.updateUserFile(response.data.id, file);
        } catch (err) {
          if (err.response && err.response.data && err.response.data.message) {
            dispatch(
              setPromptInfo({
                promptText: `${err.response.data.message}`,
              }),
            );
          } else {
            onError();
          }
        }
      }
      onSuccess();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        dispatch(
          setPromptInfo({
            promptText: `${err.response.data.message}`,
          }),
        );
      } else {
        onError();
      }
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const reqData = {
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName,
      phone: data.phone,
      role: +data.role,
      title: data.title,
      login: data.login,
      shopIds: data.shopIds,
      password: changePasswordMode ? data.password : null,
    };
    if (methodType === methodTypes.UPDATE) {
      await updateUser(reqData);
    } else {
      await createUser(reqData);
    }
    setLoading(false);
  };

  if (!userInfoInit) return <div />;
  return (
    <form
      className="EditUserForm"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      id="EditUserForm"
    >
      <h2>
        {methodType === methodTypes.UPDATE
          ? t("userProfile.change_.title")
          : t("userProfile.change_.titleCreate")}
      </h2>
      <div className="FormSection">
        <div
          style={{
            width: "370px",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="ImageBox">
            <div className="ImageWrap">
              {userAva || previewUrl ? (
                <img src={!previewUrl ? userAva : previewUrl} alt="" />
              ) : (
                <label htmlFor="file">
                  <span>{t("userProfile.change_.avatarCreate")}</span>
                </label>
              )}
            </div>

            <div
              className="FormArea"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <label htmlFor="file" className="label-for-file-p">
                {methodType === methodTypes.UPDATE
                  ? t("userProfile.change_.avatar")
                  : t("userProfile.change_.addAvatar")}
              </label>
              <input
                accept="image/*"
                onChange={onSelectImage}
                type="file"
                id="file"
                style={{ display: "none" }}
              />
            </div>
          </div>
        </div>
        <div className="FormArea">{renderInitialsInputs()}</div>
      </div>

      <div
        className="FormSection"
        style={{ borderBottom: "2px solid rgb(0,0,0,0)" }}
      >
        <div className="FormArea">
          <div className="FormSelectWrap">
            <label>{t("userProfile.change_.roleTitle")}</label>
            <select {...register("role")}>
              {personalRoles.map((role, i) => (
                <option key={`${role.id}_${i}`} value={role.id}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>

          {userShopsList && (
            <RHFSeveralSelect
              isInactive={false}
              optionsInit={userShopsList}
              selectedValuesInit={
                userInfoInit && userInfoInit.shopIds ? userInfoInit.shopIds : []
              }
              description={t(
                "merchant.merchantRows.personnelAccessRights.changingShopsNames",
              )}
              id={11}
              control={control}
              keyName={personalUser_Fields.shopIds.keyName}
              wrapStyles={{
                width: "100%",
                marginTop: "40px",
                marginBottom: "40px",
              }}
            />
          )}

          <RHFInput
            wrapStyles={{ marginTop: "30px", marginBottom: "10px" }}
            key={personalUser_Fields.login.keyName}
            keyName={personalUser_Fields.login.keyName}
            description={personalUser_Fields.login.description}
            register={register}
            error={errors[personalUser_Fields.login.keyName]}
            inputType={
              personalUser_Fields[personalUser_Fields.login.keyName].type
            }
            control={control}
          />

          {methodType === methodTypes.UPDATE && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <input
                id="editUser_checkbox"
                type="checkbox"
                checked={changePasswordMode}
                onChange={() => setChangePasswordMode((prev) => !prev)}
              />
              <label
                htmlFor="editUser_checkbox"
                style={{
                  fontSize: "12px",
                  marginLeft: "5px",
                }}
              >
                {t("userProfile.change_.changePassFlag")}
              </label>
            </div>
          )}

          <div
            style={{
              display: changePasswordMode ? "block" : "none",
              marginTop: "20px",
            }}
          >
            <RHFInput
              key={personalUser_Fields.password.keyName}
              keyName={personalUser_Fields.password.keyName}
              description={personalUser_Fields.password.description}
              register={register}
              error={errors[personalUser_Fields.password.keyName]}
              inputType={
                personalUser_Fields[personalUser_Fields.password.keyName].type
              }
              control={control}
            />

            <RHFInput
              key={personalUser_Fields.confirmedPassword.keyName}
              keyName={personalUser_Fields.confirmedPassword.keyName}
              description={personalUser_Fields.confirmedPassword.description}
              register={register}
              error={errors[personalUser_Fields.confirmedPassword.keyName]}
              inputType={
                personalUser_Fields[
                  personalUser_Fields.confirmedPassword.keyName
                ].type
              }
              control={control}
            />
          </div>
        </div>
      </div>

      <div className="FormFooter">
        {loading && (
          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
          </div>
        )}
        {!loading && (
          <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
            <button type="submit" form="EditUserForm" className="FormButton">
              {methodType === methodTypes.UPDATE
                ? t("userProfile.change_.change")
                : t("userProfile.change_.create")}
            </button>
            <button onClick={onCancel} className="FormButton red">
              {t("userProfile.change_.cancell")}
            </button>
          </div>
        )}
      </div>
    </form>
  );
};
export default EditUser;
