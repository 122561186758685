import React, { useCallback, useEffect, useState } from "react";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { validation } from "../../../tools/validation";
import { useTranslation } from "react-i18next";
import close from "../../../assets/images/pages/Products/ProdForm/close.svg";
import NumberFormat from "react-number-format";
import $api from "../../../api/http";
import OpeningHours from "./fields/OpeningHours/OpeningHours";
import { getWorkTimeInit } from "../../../tools/pages/administration";
import ExclusionDays from "./fields/ExclusionDays/ExclusionDays";
import { checkAreKeysExist } from "../../../tools/common/additionalFuncs";

function onChangeCoords(value, callback) {
  let settingValue;
  if (value.length > 10) {
    settingValue = value.slice(0, 10);
  } else settingValue = value;

  return callback(settingValue);
}

const MerchantForm = ({
  isNotModalWindow,
  edit,
  form,
  formError,
  formIsValid,
  onChangeInput,
  onClose,
  onDelete,
  editedShop,
  onUpdateShop,
  onCreateShop,
  weekDaysFormData,
  loading,
  setWeekDaysFormData,
  setExclusionDaysFormData,
  getAllShops,
}) => {
  const { t } = useTranslation();

  return (
    <div
      style={isNotModalWindow ? { display: "block", width: "100%" } : {}}
      className="administration_create_shop_container"
    >
      {!isNotModalWindow && (
        <div className="close-div" onClick={onClose}>
          <img src={close} alt="" />
        </div>
      )}
      {!isNotModalWindow && (
        <h3>
          {edit ? "Изменить Торговую Точку" : "Создать новую Торговую Точку"}
        </h3>
      )}

      <div
        style={isNotModalWindow ? { display: "block", width: "100%" } : {}}
        className="administration_create_shop_container_header"
      >
        <div
          style={isNotModalWindow ? { display: "block", width: "100%" } : {}}
          className="administration_create_shop_container_header_left"
        >
          <div
            style={isNotModalWindow ? { display: "block", width: "100%" } : {}}
            className="administration_create_shop_container_area"
          >
            <label
              htmlFor="name"
              className={formError === form[0].error ? "error" : null}
            >
              {"Имя Торговой Точки:"}
            </label>
            <input
              style={
                isNotModalWindow
                  ? { width: "100%", maxWidth: "100%", marginTop: "3px" }
                  : {}
              }
              value={form[0].value}
              onChange={(e) => onChangeInput(e, "name")}
              type="text"
              id="name"
              className={formError === form[0].error ? "error" : null}
            />
          </div>

          <OpeningHours
            isNotModalWindow={isNotModalWindow}
            weekDaysInit={
              editedShop && editedShop.weekDays
                ? editedShop.weekDays
                : getWorkTimeInit(t)
            }
            setWeekDaysFormData={setWeekDaysFormData}
            weekDaysFormData={weekDaysFormData}
            getAllShops={getAllShops}
          />

          <ExclusionDays
            exclusionDaysInit={
              editedShop && editedShop.exclusionDays
                ? editedShop.exclusionDays
                : []
            }
            setExclusionDaysFormData={setExclusionDaysFormData}
            getAllShops={getAllShops}
          />
          <div
            style={isNotModalWindow ? { width: "100%" } : {}}
            className="administration_create_shop_container_address"
          >
            <h4>{"Ссылки:"}</h4>
            <div
              style={
                isNotModalWindow
                  ? {
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                    }
                  : {}
              }
              className="administration_create_shop_container_area_small"
            >
              <label
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                htmlFor="photoMag"
                className={formError === form[1].error ? "error" : null}
              >
                {"Фото магазина(ссылка):"}
              </label>
              <input
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                value={form[1].value}
                onChange={(e) => onChangeInput(e, "photoMag")}
                type="text"
                id="photoMag"
                className={formError === form[1].error ? "error" : null}
              />
            </div>
            <div
              className="administration_create_shop_container_area"
              style={
                isNotModalWindow
                  ? {
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                    }
                  : {}
              }
            >
              <label
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                htmlFor="photoLogo"
                className={formError === form[2].error ? "error" : null}
              >
                {"Логотип(ссылка):"}
              </label>
              <input
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                value={form[2].value}
                onChange={(e) => onChangeInput(e, "photoLogo")}
                type="text"
                id="photoLogo"
                className={formError === form[2].error ? "error" : null}
              />
            </div>
          </div>
          <div className="administration_create_shop_container_address">
            <h4>{"Адрес:"}</h4>
            {/* <div className="administration_create_shop_container_area_small">
              <label
                htmlFor="codeCountry"
                className={formError === form[3].error ? "error" : null}
              >
                {"Код страны:"}
              </label>
              <input
                value={form[3].value}
                onChange={(e) => onChangeInput(e, "codeCountry")}
                type="text"
                id="codeCountry"
                className={formError === form[3].error ? "error" : null}
              />
            </div> */}
            <div className="administration_create_shop_container_area_small">
              <label
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                htmlFor="city"
                className={formError === form[4].error ? "error" : null}
              >
                {"Город:"}
              </label>
              <input
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                value={form[4].value}
                onChange={(e) => onChangeInput(e, "city")}
                type="text"
                id="city"
                className={formError === form[4].error ? "error" : null}
              />
            </div>
            <div className="administration_create_shop_container_area_small">
              <label
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                htmlFor="street"
                className={formError === form[5].error ? "error" : null}
              >
                {"Улица:"}
              </label>
              <input
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                value={form[5].value}
                onChange={(e) => onChangeInput(e, "street")}
                type="text"
                id="street"
                className={formError === form[5].error ? "error" : null}
              />
            </div>
            <div className="administration_create_shop_container_area_small">
              <label
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                htmlFor="house"
                className={formError === form[6].error ? "error" : null}
              >
                {"Номер дома:"}
              </label>
              <input
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                value={form[6].value}
                onChange={(e) => onChangeInput(e, "house")}
                type="text"
                id="house"
                className={formError === form[6].error ? "error" : null}
              />
            </div>
            <div className="administration_create_shop_container_area_small">
              <label
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                htmlFor="postIndex"
                className={formError === form[7].error ? "error" : null}
              >
                {"Почтовый индекс:"}
              </label>
              <input
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                value={form[7].value}
                onChange={(e) => onChangeInput(e, "postIndex")}
                type="number"
                id="postIndex"
                className={formError === form[7].error ? "error" : null}
              />
            </div>
            <div className="administration_create_shop_container_area_small">
              <label
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                htmlFor="width"
                className={formError === form[8].error ? "error" : null}
              >
                {"Широта:"}
              </label>
              <input
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                value={form[8].value}
                onChange={(e) =>
                  onChangeCoords(e.target.value, (value) =>
                    onChangeInput({ target: { value } }, "width"),
                  )
                }
                type="number"
                id="width"
                className={formError === form[8].error ? "error" : null}
              />
            </div>
            <div className="administration_create_shop_container_area_small">
              <label
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                htmlFor="length"
                className={formError === form[9].error ? "error" : null}
              >
                {"Долгота:"}
              </label>
              <input
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                value={form[9].value}
                onChange={(e) =>
                  onChangeCoords(e.target.value, (value) =>
                    onChangeInput({ target: { value } }, "length"),
                  )
                }
                type="number"
                id="length"
                className={formError === form[9].error ? "error" : null}
              />
            </div>
          </div>
        </div>
        <div
          className="administration_create_shop_container_header_right"
          style={isNotModalWindow ? { width: "100%", marginLeft: 0 } : {}}
        >
          <div
            style={
              isNotModalWindow
                ? {
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "inherit",
                    padding: "10px 0 16px 0",
                  }
                : {}
            }
            className="administration_create_shop_container_header_right_images"
          >
            {form[1].value !== "" && (
              <div
                style={
                  isNotModalWindow
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }
                    : {}
                }
              >
                <p>{"Фото магазина:"}</p>
                <img alt="" src={form[1].value} />
              </div>
            )}
            {form[2].value !== "" && (
              <div
                style={
                  isNotModalWindow
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }
                    : {}
                }
              >
                <p>{"Логотип:"}</p>
                <img alt="" src={form[2].value} />
              </div>
            )}
          </div>
          <div
            style={isNotModalWindow ? { paddingTop: "16px" } : {}}
            className="administration_create_shop_container_address"
          >
            <h4>{"Контакты:"}</h4>
            <div className="administration_create_shop_container_area_small">
              <label
                htmlFor="phone"
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                className={formError === form[10].error ? "error" : null}
              >
                {"Номер телефона:"}
              </label>
              <NumberFormat
                format="+38(0##) ### ## ##"
                mask="_"
                type="text"
                id="phone"
                allowEmptyFormatting
                value={form[10].value}
                onChange={(e) => onChangeInput(e, "phone")}
                className={formError === form[10].error ? "error" : null}
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
              />
            </div>
            <div className="administration_create_shop_container_area_small">
              <label
                htmlFor="email"
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                className={formError === form[11].error ? "error" : null}
              >
                {"email:"}
              </label>
              <input
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                value={form[11].value}
                onChange={(e) => onChangeInput(e, "email")}
                type="text"
                id="email"
                className={formError === form[11].error ? "error" : null}
              />
            </div>
          </div>

          <div className="CommonMerchantFormBox">
            <h4>
              {t("merchant.merchantRows.merchantInfo.titleNames.notifications")}
            </h4>
            <div className="CommonMerchantForm_area_small">
              <div className="CommonMerchantForm_area_small">
                <div className="checkbox">
                  <label htmlFor="sendSms">
                    {t("merchant.merchantRows.merchantInfo.fieldNames.sendSms")}
                  </label>
                  <input
                    style={
                      form[12].value ? { opacity: "1" } : { opacity: "0.5" }
                    }
                    type="checkbox"
                    id="sendSms"
                    checked={form[12].value}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      onChangeInput(
                        {
                          ...e,
                          target: { ...e.target, value: !form[12].value },
                        },
                        "sendSms",
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div
              className="CommonMerchantForm_area_small"
              style={{ marginBottom: "24px" }}
            >
              <label
                htmlFor="notificationPhone"
                className={formError === form[13].error ? "error" : null}
              >
                {t(
                  "merchant.merchantRows.merchantInfo.fieldNames.notificationPhone",
                )}
              </label>
              <NumberFormat
                disabled={!form[12].value}
                format="+38(0##) ### ## ##"
                mask="_"
                type="text"
                id="notificationPhone"
                allowEmptyFormatting
                value={form[13].value}
                onChange={(e) => onChangeInput(e, "notificationPhone")}
                className={formError === form[13].error ? "error" : null}
              />
            </div>
            <div className="CommonMerchantForm_area_small">
              <div className="checkbox">
                <label htmlFor="sendEmail">
                  {t("merchant.merchantRows.merchantInfo.fieldNames.sendEmail")}
                </label>
                <input
                  type="checkbox"
                  style={form[14].value ? { opacity: "1" } : { opacity: "0.5" }}
                  checked={form[14].value}
                  id="sendEmail"
                  /*onClick={(e) => e.stopPropagation()}*/
                  onChange={(e) =>
                    onChangeInput(
                      { ...e, target: { ...e.target, value: !form[14].value } },
                      "sendEmail",
                    )
                  }
                />
              </div>
            </div>
            <div
              className="CommonMerchantForm_area_small"
              style={{ marginBottom: "20px" }}
            >
              <label
                htmlFor="notificationEmail"
                className={formError === form[15].error ? "error" : null}
              >
                {t(
                  "merchant.merchantRows.merchantInfo.fieldNames.notificationEmail",
                )}
              </label>
              <input
                disabled={!form[14].value}
                value={form[15].value}
                onChange={(e) => onChangeInput(e, "notificationEmail")}
                type="text"
                id="notificationEmail"
                className={formError === form[15].error ? "error" : null}
              />
            </div>
          </div>

          <div className="administration_create_shop_container_address">
            <h4>{"Платежные реквизиты:"}</h4>
            <div className="administration_create_shop_container_area_small">
              <label
                htmlFor="terminalId"
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                className={formError === form[16].error ? "error" : null}
              >
                {"terminal id:"}
              </label>
              <input
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                value={form[16].value}
                onChange={(e) => onChangeInput(e, "terminalId")}
                type="text"
                id="terminalId"
                className={formError === form[16].error ? "error" : null}
              />
            </div>
            <div className="administration_create_shop_container_area_small">
              <label
                htmlFor="merchantId"
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                className={formError === form[17].error ? "error" : null}
              >
                {"merchant id:"}
              </label>
              <input
                style={
                  isNotModalWindow ? { width: "50%", maxWidth: "50%" } : {}
                }
                value={form[17].value}
                onChange={(e) => onChangeInput(e, "merchantId")}
                type="text"
                id="merchantId"
                className={formError === form[17].error ? "error" : null}
              />
            </div>
            {/* <div className="administration_create_shop_container_area_small">
              <label
                htmlFor="rate"
                className={formError === form[14].error ? "error" : null}
              >
                {"рейтинг:"}
              </label>
              <input
                value={form[14].value}
                disabled
                onChange={(e) => onChangeInput(e, "rate")}
                type="text"
                id="rate"
                className={formError === form[14].error ? "error" : null}
              />
            </div> */}
          </div>
        </div>
      </div>
      {edit && (
        <div className="administration_create_shop_container_footer">
          {!formIsValid && formError && (
            <p
              style={{
                color: "#c73f3f",
                fontSize: "12px",
                alignSelf: "center",
              }}
            >
              {formError}
            </p>
          )}
          {!loading && (
            <div>
              <button
                className={!formIsValid ? "disabled" : "blue"}
                onClick={formIsValid ? onUpdateShop : null}
              >
                Изменить
              </button>
              <button className="red" onClick={() => onDelete(editedShop)}>
                Удалить
              </button>
            </div>
          )}
          {loading && (
            <div style={{ height: "50px", marginTop: "20px" }}>
              <Spinner />
            </div>
          )}
        </div>
      )}
      {!edit && (
        <div className="administration_create_shop_container_footer">
          {formError && (
            <p
              style={{
                color: "#c73f3f",
                fontSize: "12px",
                alignSelf: "center",
              }}
            >
              {formError}
            </p>
          )}
          {!loading && (
            <button
              className={!formIsValid ? "disabled" : null}
              onClick={formIsValid ? onCreateShop : null}
            >
              Создать
            </button>
          )}
          {loading && (
            <div style={{ height: "50px", marginTop: "20px" }}>
              <Spinner />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const ModalEditShopContent = (props) => {
  const { t } = useTranslation();
  // govnokod:(
  const id = props.editedShop && props.editedShop.id;
  const buildingNumber = props.editedShop && props.editedShop.buildingNumber;
  const city = props.editedShop && props.editedShop.city;
  const countryCode = props.editedShop && props.editedShop.countryCode;
  const email = props.editedShop && props.editedShop.email;
  const image = props.editedShop && props.editedShop.image;
  const latitude = props.editedShop && props.editedShop.latitude;
  const logo = props.editedShop && props.editedShop.logo;
  const longitude = props.editedShop && props.editedShop.longitude;
  const merchantId = props.editedShop && props.editedShop.merchantId;
  const name = props.editedShop && props.editedShop.name;
  const phone = props.editedShop && props.editedShop.phone;
  const postalCode = props.editedShop && props.editedShop.postalCode;
  const raiting = props.editedShop && props.editedShop.raiting;
  const street = props.editedShop && props.editedShop.street;
  const terminalId = props.editedShop && props.editedShop.terminalId;
  const _merchantId = props.editedShop && props.editedShop._merchantId;

  const notificationPhone =
    checkAreKeysExist(props.editedShop, ["settings", "notifications"]) &&
    props.editedShop.settings.notifications.phone;
  const notificationEmail =
    checkAreKeysExist(props.editedShop, ["settings", "notifications"]) &&
    props.editedShop.settings.notifications.email;
  const sendSms =
    checkAreKeysExist(props.editedShop, ["settings", "notifications"]) &&
    props.editedShop.settings.notifications.sendSms;
  const sendEmail =
    checkAreKeysExist(props.editedShop, ["settings", "notifications"]) &&
    props.editedShop.settings.notifications.sendEmail;

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState([]);
  const [formIsValid, setFormIsValid] = useState(true);
  const [formError, setFormError] = useState(null);

  const [weekDaysFormData, setWeekDaysFormData] = useState(
    props.editedShop && props.editedShop.weekDays
      ? props.editedShop.weekDays
      : getWorkTimeInit(t),
  );
  const [exclusionDaysFormData, setExclusionDaysFormData] = useState(
    props.editedShop && props.editedShop.exclusionDays
      ? props.editedShop.exclusionDays
      : [],
  );

  useEffect(() => {
    setForm([
      {
        value: name,
        rules: {
          required: true,
          minLength: 2,
          maxlength: 30,
        },
        error: "Імя Торгової точки має складати від 2 до 30 символів",
        name: "name",
        valid: true,
        touched: false,
      },
      {
        value: image,
        rules: {
          required: true,
          maxlength: 1000,
        },
        error: "Посилання на фото магазина має складати від 1 до 1000 символів",
        name: "photoMag",
        valid: true,
        touched: false,
      },
      {
        value: logo,
        rules: {
          required: true,
          maxlength: 1000,
        },
        error:
          "Посилання на логотип магазина має складати від 1 до 1000 символів",
        name: "photoLogo",
        valid: true,
        touched: false,
      },
      {
        value: countryCode,
        rules: {
          // required: true,
          // minLength: 2,
          // maxlength: 3,
        },
        error: "Код країни має складати 2-3 символа",
        name: "codeCountry",
        valid: true,
        touched: false,
      },
      {
        value: city,
        rules: {
          required: true,
          minLength: 2,
          maxlength: 30,
        },
        error: "Назва міста має складати 2-30 символів",
        name: "city",
        valid: true,
        touched: false,
      },
      {
        value: street,
        rules: {
          required: true,
          minLength: 2,
          maxlength: 30,
        },
        error: "Назва вулиці має складати 2-30 символів",
        name: "street",
        valid: true,
        touched: false,
      },
      {
        value: buildingNumber,
        rules: {
          required: true,
          minLength: 1,
          maxlength: 6,
        },
        error: "Не вірний номер будинку",
        name: "house",
        valid: true,
        touched: false,
      },
      {
        value: postalCode,
        rules: {
          required: true,
          minLength: 0,
          maxLength: 15,
        },
        error: "Не вірний почтовий індекс",
        name: "postIndex",
        valid: true,
        touched: false,
      },
      {
        value: latitude,
        rules: {
          required: true,
          minLength: 1,
          maxLength: 30,
        },
        error: "Не вірна широта",
        name: "width",
        valid: true,
        touched: false,
      },
      {
        value: longitude,
        rules: {
          required: true,
          minLength: 1,
          maxLength: 30,
        },
        error: "Не вірна довгота",
        name: "length",
        valid: true,
        touched: false,
      },
      {
        value: phone,
        rules: {
          required: true,
          minLength: 1,
          maxlength: 20,
        },
        error: "Не вірний номер телефону",
        name: "phone",
        valid: true,
        touched: false,
      },
      {
        value: email,
        rules: {
          required: true,
          minLength: 1,
          maxlength: 30,
          isEmail: true,
        },
        error: "Не вірний email",
        name: "email",
        valid: true,
        touched: false,
      },
      {
        value: sendSms,
        rules: {
          required: false,
        },
        name: "sendSms",
        valid: true,
        touched: false,
      },
      {
        value: notificationPhone ? notificationPhone : "",
        rules: {
          minLength: 1,
          maxlength: 20,
        },
        error: "Не вірний номер телефону для нотифікацій",
        name: "notificationPhone",
        valid: true,
        touched: false,
      },
      {
        value: sendEmail,
        rules: {
          required: false,
        },
        name: "sendEmail",
        valid: true,
        touched: false,
      },
      {
        value: notificationEmail ? notificationEmail : "",
        rules: {
          minLength: 1,
          maxlength: 30,
          isEmail: true,
        },
        error: "Не вірний email для нотифікацій",
        name: "notificationEmail",
        valid: true,
        touched: false,
      },

      {
        value: terminalId,
        rules: {
          required: true,
          minLength: 1,
          maxlength: 40,
        },
        error: "Не вірний terminal id",
        name: "terminalId",
        valid: true,
        touched: false,
      },
      {
        value: merchantId,
        rules: {
          required: true,
          minLength: 1,
          maxlength: 40,
        },
        error: "Не вірний merchant id",
        name: "merchantId",
        valid: true,
        touched: false,
      },
      {
        value: raiting,
        rules: {
          // required: true,
          // minLength: 1,
          // maxlength: 3,
        },
        error: "Не вірний рейтинг",
        name: "rate",
        valid: true,
        touched: false,
      },
    ]);
  }, [props.editedShop]);

  const onChangeInput = (event, name) => {
    const newForm = [...form];
    const index = newForm.indexOf(newForm.filter((el) => el.name === name)[0]);
    newForm[index] = {
      ...newForm[index],
      value: event.target.value,
      touched: true,
      valid: validation(
        event.target.value,
        newForm[index].rules,
        newForm[index].error,
      ),
    };
    setForm(newForm);
    let i = 0;
    const formWithErrors = JSON.parse(JSON.stringify(newForm)).reverse();
    setFormIsValid(true);
    formWithErrors.forEach((el) => {
      if (el.valid !== true) {
        setFormIsValid(false);
        if (el.touched) {
          setFormError(el.error);
          i++;
        }
      }
    });
    if (i === 0) {
      setFormError(null);
    }
  };

  const onUpdateShop = () => {
    setLoading(true);

    $api
      .put(`/Merchants/${_merchantId}/shops/${id}`, {
        name: form[0].value,
        latitude: form[8].value,
        longitude: form[9].value,
        countryCode: form[3].value,
        postalCode: form[7].value,
        city: form[4].value,
        street: form[5].value,
        buildingNumber: form[6].value,
        phone: form[10].value,
        email: form[11].value,
        image: form[1].value,
        logo: form[2].value,
        settings: JSON.stringify({
          ...props.editedShop.settings,
          open_hours: [...weekDaysFormData, ...exclusionDaysFormData],
          notifications: {
            sendSms: form[12].value,
            phone: form[13].value,
            sendEmail: form[14].value,
            email: form[15].value,
          },
        }),
        terminalId: form[16].value,
        merchantId: form[17].value,
        raiting: form[18].value,
      })
      .then((res) => {
        let i;
        [...props.shops].forEach((el, index) => {
          if (el.id === res.data.id) i = index;
        });
        let newShops = [...props.shops];
        const accounts = newShops[i].accounts;
        newShops[i] = {
          ...res.data,
          accounts,
        };
        props.setShops(newShops);
        props.onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data && err.response.data.message) {
          setFormError(err.response.data.message);
        } else {
          props.onError();
        }
      });
  };

  if (!form.length) return <div />;
  return (
    <MerchantForm
      isNotModalWindow={props.isNotModalWindow}
      edit
      form={form}
      formError={formError}
      formIsValid={formIsValid}
      onChangeInput={onChangeInput}
      onClose={props.onClose}
      onDelete={props.onDelete}
      editedShop={props.editedShop}
      onUpdateShop={onUpdateShop}
      loading={loading}
      weekDaysFormData={weekDaysFormData}
      setWeekDaysFormData={setWeekDaysFormData}
      setExclusionDaysFormData={setExclusionDaysFormData}
      getAllShops={props.getAllShops}
    />
  );
};

export const ModalCreateShopContent = (props) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [weekDaysFormData, setWeekDaysFormData] = useState(getWorkTimeInit(t));
  const [exclusionDaysFormData, setExclusionDaysFormData] = useState([]);

  /*const [weekDaysFormData, setWeekDaysFormData] = useState(
    props.editedShop && props.editedShop.weekDays
      ? props.editedShop.weekDays
      : getWorkTimeInit(t)
  );
  const [exclusionDaysFormData, setExclusionDaysFormData] = useState(
    props.editedShop && props.editedShop.exclusionDays
      ? props.editedShop.exclusionDays
      : []
  );*/

  const [form, setForm] = useState([]);
  const [formIsValid, setFormIsValid] = useState(false);
  const [formError, setFormError] = useState(false);

  useEffect(() => {
    setForm([
      {
        value: "",
        rules: {
          required: true,
          minLength: 2,
          maxlength: 30,
        },
        error: "Імя Торгової точки має складати від 2 до 30 символів",
        name: "name",
        valid: false,
        touched: false,
      },
      {
        value: "",
        rules: {
          required: true,
          maxlength: 1000,
        },
        error: "Посилання на фото магазина має складати від 1 до 1000 символів",
        name: "photoMag",
        valid: false,
        touched: false,
      },
      {
        value: "",
        rules: {
          required: true,
          maxlength: 1000,
        },
        error:
          "Посилання на логотип магазина має складати від 1 до 1000 символів",
        name: "photoLogo",
        valid: false,
        touched: false,
      },
      {
        value: "",
        rules: {
          // required: true,
          // minLength: 2,
          // maxlength: 3,
        },
        error: "Код країни має складати 2-3 символа",
        name: "codeCountry",
        valid: true,
        touched: false,
      },
      {
        value: "",
        rules: {
          required: true,
          minLength: 2,
          maxlength: 30,
        },
        error: "Назва міста має складати 2-30 символів",
        name: "city",
        valid: false,
        touched: false,
      },
      {
        value: "",
        rules: {
          required: true,
          minLength: 2,
          maxlength: 30,
        },
        error: "Назва вулиці має складати 2-30 символів",
        name: "street",
        valid: false,
        touched: false,
      },
      {
        value: "",
        rules: {
          required: true,
          minLength: 1,
          maxlength: 6,
        },
        error: "Не вірний номер будинку",
        name: "house",
        valid: false,
        touched: false,
      },
      {
        value: "",
        rules: {
          required: true,
          minLength: 0,
          maxLength: 15,
        },
        error: "Не вірний почтовий індекс",
        name: "postIndex",
        valid: false,
        touched: false,
      },
      {
        value: "",
        rules: {
          required: true,
          minLength: 1,
          maxLength: 30,
        },
        error: "Не вірна широта",
        name: "width",
        valid: false,
        touched: false,
      },
      {
        value: "",
        rules: {
          required: true,
          minLength: 1,
          maxLength: 30,
        },
        error: "Не вірна довгота",
        name: "length",
        valid: false,
        touched: false,
      },
      {
        value: "",
        rules: {
          required: true,
          minLength: 1,
          maxlength: 20,
        },
        error: "Не вірний номер телефону",
        name: "phone",
        valid: false,
        touched: false,
      },
      {
        value: "",
        rules: {
          required: true,
          minLength: 1,
          maxlength: 30,
          isEmail: true,
        },
        error: "Не вірний email",
        name: "email",
        valid: false,
        touched: false,
      },
      {
        value: "",
        rules: {
          required: false,
        },
        name: "sendSms",
        valid: true,
        touched: false,
      },
      {
        value: "",
        rules: {
          minLength: 1,
          maxlength: 20,
        },
        error: "Не вірний номер телефону для нотифікацій",
        name: "notificationPhone",
        valid: true,
        touched: false,
      },
      {
        value: "",
        rules: {
          required: false,
        },
        name: "sendEmail",
        valid: true,
        touched: false,
      },
      {
        value: "",
        rules: {
          minLength: 1,
          maxlength: 30,
          isEmail: true,
        },
        error: "Не вірний email для нотифікацій",
        name: "notificationEmail",
        valid: true,
        touched: false,
      },
      {
        value: "",
        rules: {
          required: true,
          minLength: 1,
          maxlength: 40,
        },
        error: "Не вірний terminal id",
        name: "terminalId",
        valid: false,
        touched: false,
      },
      {
        value: "",
        rules: {
          required: true,
          minLength: 1,
          maxlength: 40,
        },
        error: "Не вірний merchant id",
        name: "merchantId",
        valid: false,
        touched: false,
      },
      {
        value: "0",
        rules: {
          // required: true,
          // minLength: 1,
          // maxlength: 3,
        },
        error: "Не вірний рейтинг",
        name: "rate",
        valid: true,
        touched: false,
      },
    ]);
  }, []);

  const onChangeInput = (event, name) => {
    const newForm = [...form];
    const index = newForm.indexOf(newForm.filter((el) => el.name === name)[0]);
    newForm[index] = {
      ...newForm[index],
      value: event.target.value,
      valid: validation(
        event.target.value,
        newForm[index].rules,
        newForm[index].error,
      ),
      touched: true,
    };
    setForm(newForm);
    let i = 0;
    const formWithErrors = JSON.parse(JSON.stringify(newForm)).reverse();
    setFormIsValid(true);
    formWithErrors.forEach((el) => {
      if (el.valid !== true) {
        setFormIsValid(false);
        if (el.touched) {
          setFormError(el.error);
          i++;
        }
      }
    });
    if (i === 0) {
      setFormError(null);
    }
  };

  const onCreateShop = () => {
    setLoading(true);
    $api
      .post(`/Merchants/${props.merchantId}/shops`, {
        name: form[0].value,
        latitude: form[8].value,
        longitude: form[9].value,
        countryCode: form[3].value,
        postalCode: form[7].value,
        city: form[4].value,
        street: form[5].value,
        buildingNumber: form[6].value,
        phone: form[10].value,
        email: form[11].value,
        image: form[1].value,
        logo: form[2].value,
        settings: JSON.stringify({
          open_hours: [...weekDaysFormData, ...exclusionDaysFormData],
          notifications: {
            sendSms: form[12].value,
            phone: form[13].value,
            sendEmail: form[14].value,
            email: form[15].value,
          },
        }),
        terminalId: form[16].value,
        merchantId: form[17].value,
        raiting: form[18].value,
      })
      .then((res) => {
        let newShops = [...props.shops];
        newShops.push({
          ...res.data,
          account: [],
        });
        props.setShops(newShops);
        props.onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data && err.response.data.message) {
          setFormError(err.response.data.message);
        } else {
          props.onError();
        }
      });
  };

  if (!form.length) return <div />;
  return (
    <MerchantForm
      edit={false}
      form={form}
      formError={formError}
      formIsValid={formIsValid}
      onChangeInput={onChangeInput}
      onClose={props.onClose}
      onCreateShop={onCreateShop}
      loading={loading}
      weekDaysFormData={weekDaysFormData}
      setWeekDaysFormData={setWeekDaysFormData}
      exclusionDaysFormData={exclusionDaysFormData}
      setExclusionDaysFormData={setExclusionDaysFormData}
      getAllShops={props.getAllShops}
    />
  );
};
