import * as actionTypes from "./actionTypes";

export const setChangedOrderMode = (mode) => {
  return {
    type: actionTypes.SET_CHANGED_ORDER_MODE,
    mode,
  };
};

export const setSearchOrderNumber = (int) => {
  return {
    type: actionTypes.SET_SEARCH_ORDER_NUMBER,
    int,
  };
};

export const setNotificationsHistoryMode = (mode) => {
  return {
    type: actionTypes.SET_NOTIFICATIONS_HISTORY_MODE,
    notifications_mode: mode,
  };
};

export const setGlobalLoading = (loading) => {
  return {
    type: actionTypes.SET_GLOBAL_LOADING,
    loading,
  };
};
