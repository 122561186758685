export const validation = (value, rules, error = false) => {
  let isValid = true;

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }
  if (rules.isNumber) {
    isValid = typeof Number(value) === "number" && isValid;
  }
  if (rules.minNumber) {
    isValid = typeof Number(value) >= rules.minNumber && isValid;
  }
  if (rules.maxNumber) {
    isValid = typeof Number(value) <= rules.maxNumber && isValid;
  }
  if (rules.isEqual) {
    isValid = value === rules.isEqual && isValid;
  }
  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }
  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }
  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (isValid) {
    return isValid;
  } else {
    return error;
  }
};
