import React from "react";
import { useTranslation } from "react-i18next";

export const OrderToWorkContent = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>{`${t("merchant.orders.toWork.modal.1")} ${
        props.orderToWorkMode && props.orderToWorkMode.order.id
      } ${t("merchant.orders.toWork.modal.2")}`}</p>
      <div>
        <button
          onClick={() => props.onClose()}
          style={{
            marginTop: "20px",
            marginRight: "10px",
            padding: "9px 20px",
            cursor: "pointer",
            border: "1px solid #9978E2",
            color: "#9978E2",
            backgroundColor: "transparent",
          }}
        >
          {t("prod.no")}
        </button>
        <button
          onClick={() =>
            props.onOrderChange(props.orderToWorkMode.order, "take")
          }
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            color: "#fff",
            backgroundColor: "#257DF7",
          }}
        >
          {t("merchant.orders.yes")}
        </button>
      </div>
    </div>
  );
};

export const OrderCancelContent = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>{`${t("merchant.orders.cancel.modal.1")} ${
        props.orderCancelMode && props.orderCancelMode.order.id
      } ${t("merchant.orders.cancel.modal.2")}`}</p>
      <div>
        <button
          onClick={() => props.onClose()}
          style={{
            marginTop: "20px",
            marginRight: "10px",
            padding: "9px 20px",
            cursor: "pointer",
            border: "1px solid #9978E2",
            color: "#9978E2",
            backgroundColor: "transparent",
          }}
        >
          {t("prod.no")}
        </button>
        <button
          onClick={() =>
            props.onOrderChange(props.orderCancelMode.order, "cancel")
          }
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            color: "#fff",
            backgroundColor: "#9978E2",
          }}
        >
          {t("merchant.orders.yes")}
        </button>
      </div>
    </div>
  );
};

export const OrderPendingContent = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>{`${t("merchant.orders.pending.modal.1")} ${
        props.orderPendingMode && props.orderPendingMode.id
      } ${t("merchant.orders.pending.modal.2")}`}</p>
      <div>
        <button
          onClick={() => props.onClose()}
          style={{
            marginTop: "20px",
            marginRight: "10px",
            padding: "9px 20px",
            cursor: "pointer",
            border: "1px solid #9978E2",
            color: "#9978E2",
            backgroundColor: "transparent",
          }}
        >
          {t("prod.no")}
        </button>
        <button
          onClick={() => props.onOrderChange(props.orderPendingMode, "pending")}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            color: "#fff",
            backgroundColor: "#9978E2",
          }}
        >
          {t("merchant.orders.yes")}
        </button>
      </div>
    </div>
  );
};

export const OrderInProcessContent = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>{`${t("merchant.orders.inProcess.modal.1")} ${
        props.orderInProcessMode && props.orderInProcessMode.order.id
      } ${t("merchant.orders.inProcess.modal.2")}`}</p>
      <div>
        <button
          onClick={() => props.onClose()}
          style={{
            marginTop: "20px",
            marginRight: "10px",
            padding: "9px 20px",
            cursor: "pointer",
            border: "1px solid #9978E2",
            color: "#9978E2",
            backgroundColor: "transparent",
          }}
        >
          {t("prod.no")}
        </button>
        <button
          onClick={() =>
            props.onOrderChange(props.orderInProcessMode.order, "inProcess")
          }
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            color: "#fff",
            backgroundColor: "#9978E2",
          }}
        >
          {t("merchant.orders.yes")}
        </button>
      </div>
    </div>
  );
};

export const OrderCompletedContent = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>{`${t("merchant.orders.completed.modal.1")} ${
        props.orderCompletedMode && props.orderCompletedMode.order.id
      } ${t("merchant.orders.completed.modal.2")}`}</p>
      <div>
        <button
          onClick={() => props.onClose()}
          style={{
            marginTop: "20px",
            marginRight: "10px",
            padding: "9px 20px",
            cursor: "pointer",
            border: "1px solid #9978E2",
            color: "#9978E2",
            backgroundColor: "transparent",
          }}
        >
          {t("prod.no")}
        </button>
        <button
          onClick={() =>
            props.onOrderChange(props.orderCompletedMode.order, "completed")
          }
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            color: "#fff",
            backgroundColor: "#9978E2",
          }}
        >
          {t("merchant.orders.yes")}
        </button>
      </div>
    </div>
  );
};

export const OrderIssueContent = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>{`${t("merchant.orders.issue.modal.1")} ${
        props.orderIssuedMode && props.orderIssuedMode.order.id
      } ${t("merchant.orders.issue.modal.2")}`}</p>
      <div>
        <button
          onClick={() => props.onClose()}
          style={{
            marginTop: "20px",
            marginRight: "10px",
            padding: "9px 20px",
            cursor: "pointer",
            border: "1px solid #9978E2",
            color: "#9978E2",
            backgroundColor: "transparent",
          }}
        >
          {t("prod.no")}
        </button>
        <button
          onClick={() =>
            props.onOrderChange(props.orderIssuedMode.order, "issued")
          }
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            color: "#fff",
            backgroundColor: "#9978E2",
          }}
        >
          {t("merchant.orders.yes")}
        </button>
      </div>
    </div>
  );
};

export const OrderReturnedContent = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>{`${t("merchant.orders.returned.modal.1")} ${
        props.orderReturnedMode && props.orderReturnedMode.order.id
      } ${t("merchant.orders.returned.modal.2")}`}</p>
      <div>
        <button
          onClick={() => props.onClose()}
          style={{
            marginTop: "20px",
            marginRight: "10px",
            padding: "9px 20px",
            cursor: "pointer",
            border: "1px solid #9978E2",
            color: "#9978E2",
            backgroundColor: "transparent",
          }}
        >
          {t("prod.no")}
        </button>
        <button
          onClick={() =>
            props.onOrderChange(props.orderReturnedMode.order, "returned")
          }
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            color: "#fff",
            backgroundColor: "#9978E2",
          }}
        >
          {t("merchant.orders.yes")}
        </button>
      </div>
    </div>
  );
};
