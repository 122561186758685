import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { connect } from "react-redux";

import {
  HeaderBottomLinks,
  ImportArea,
  OrdersDateLinks,
  OrdersDateLinksSmall,
  AdminPageMerchantNotChoosed,
  AdminPageMerchantChoosed,
  NotificationsDateLinks,
  NotificationsDateLinksSmall,
} from "./components";

import { ReactComponent as BorderedBell } from "../../../../assets/images/components/Notifications/bell-bordered.svg";
import { ReactComponent as Add } from "../../../../assets/images/components/Notifications/add.svg";

const HeaderBottom = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const info = JSON.parse(localStorage.getItem("info"));
  const roleId = info && info.roleId;

  const editColor = location.pathname === "/admin/" ? "blue" : "black";

  // booleans
  const isUserAdmin = [-1, 1].includes(roleId);

  const adminSelectShop = props.selectedShop && roleId === 1;
  const superAdminSelectMerchantAndShop =
    props.selectedMerchant && props.selectedShop && roleId === -1;

  /* (location === '/orders' || location === '/storage' ) */
  const userIsInMainPage =
    editColor === "black" && location.pathname.includes("/main");

  const superAdminInAdminPage =
    editColor === "blue" && roleId === -1 && props.merchants;
  const adminInAdminPage = editColor === "blue" && roleId !== -1;

  const userInAnotherPage =
    !location.pathname.includes("/main") &&
    !location.pathname.includes("/admin/") &&
    !location.pathname.includes("/notifications");

  const userIsInStoragePage = location.pathname.includes("/storage");
  const userIsInOrdersPage = location.pathname.includes("/orders");
  const userIsInNotificationsPage = location.pathname.includes("notifications");

  if (userIsInMainPage)
    return (
      <div className="header__bottom">
        <HeaderBottomLinks isUserAdmin={isUserAdmin} />
        {userIsInStoragePage &&
          (superAdminSelectMerchantAndShop || adminSelectShop) && (
            <ImportArea
              setImportMode={props.setImportMode}
              setImportLoading={props.setImportLoading}
              setImportError={props.setImportError}
              setImportSuccessMessage={props.setImportSuccessMessage}
            />
          )}
        {userIsInOrdersPage && (
          <>
            <OrdersDateLinks />
            <OrdersDateLinksSmall />
          </>
        )}
      </div>
    );

  if (userIsInNotificationsPage)
    return (
      <div className="header__bottom">
        <div className="title-block">
          <BorderedBell />
          <span>{t("СООБЩЕНИЯ")}</span>
        </div>
        <div className="right-notifications">
          <NotificationsDateLinks />
          <NotificationsDateLinksSmall />
          <div className="create-message">
            <button>
              <Add />
              <span>{t("Создать сообщение")}</span>
            </button>
          </div>
        </div>
      </div>
    );

  if (superAdminInAdminPage)
    return (
      <div className="header__bottom_choice_merchant">
        {!props.selectedMerchant && <AdminPageMerchantNotChoosed />}
        {props.selectedMerchant && <AdminPageMerchantChoosed />}
      </div>
    );

  if (adminInAdminPage || userInAnotherPage)
    return <div className="header__bottom_empty" />;

  return <></>;
};

const mapStateToProps = (state) => ({
  selectedMerchant: state.admin.selectedMerchant,
  selectedShop: state.admin.selectedShop,
  merchants: state.admin.merchants,
});

export default connect(mapStateToProps)(HeaderBottom);
