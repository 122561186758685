import React, { useEffect, useState } from "react";
import { AlertTypes } from "../../../dataUI/common/alerts";
import {
  setAlertInfo,
  setPromptInfo,
} from "../../../store/actions/alert/alertActions";
import { useDispatch } from "react-redux";
import GlobalLoader from "../../../components/UI/GlobalLoader/GlobalLoader";
import { useTranslation } from "react-i18next";
import { showError } from "../../../tools/common/additionalFuncs";
import JoinPosterService from "../../../api/services/JoinPoster";

const JoinposterConfirm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [code, setCode] = useState(undefined);
  const [account, setAccount] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const url = window.document.location.href;
    const code_l =
      url.includes("code=") && url.includes("&account")
        ? url.split("code=")[1].split("&account")[0]
        : undefined;
    const account_l = url.includes("account=")
      ? url.split("account=")[1]
      : undefined;
    code_l && setCode(code_l);
    account_l && setAccount(account_l);
  }, []);

  useEffect(() => {
    if (code && account) {
      authByJoinPosterToken(account, code);
    }
  }, [code, account]);

  const authByJoinPosterToken = async (account, code) => {
    setIsLoading(true);
    try {
      const response = await JoinPosterService.authByJoinPosterToken(
        account,
        code,
      );
      if (response.status === 200) {
        const alertInfo = {
          alertType: AlertTypes.ALERT_CONFIRM,
          titleText: t("global.success"),
          descriptionText: t("global.regDescription"),
          autoClosing: true,
          confirmButtonText: "ОК",
          confirmButtonF: () => {
            document.location.href = `https://${account}.joinposter.com/manage/applications/samo`;
          },
        };
        dispatch(setAlertInfo(alertInfo));
      } else {
        dispatch(
          setPromptInfo({
            promptText: t("global.error") + ` Код: ${response.status}`,
          }),
        );
        console.log("JoinposterConfirm / response = ", response);
      }
    } catch (error) {
      console.log("error = ", error);
      console.log("error.response = ", error.response);
      dispatch(
        setPromptInfo({
          promptText:
            t("global.error") + `: ${error.response.data["errorMessage"]}`,
        }),
      );
      showError(error, "'authByAccessToken' failed.");
    }
    setIsLoading(false);
    setTimeout(() => {
      document.location.href = `https://${account}.joinposter.com/manage/applications/samo`;
    }, 5000);
  };

  if (isLoading) return <GlobalLoader />;
  return <div />;
};

export default JoinposterConfirm;
