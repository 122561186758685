import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import UsersAPI from "../../../models/users/users";

// modals
import { ModalCreateShopContent, ModalEditShopContent } from "../modals/modals";
import {
  ModalWithTitle,
  ModalWithButton,
} from "../../../components/UI/StatusModals/statusModals";

import ShopsList from "../../../components/Shops/ShopsList/ShopsList";
import Personal from "../../../components/Personal/Personal";
import UserProfile from "../../../components/Personal/UserProfile/UserProfile";
import Modal from "../../../components/UI/Modal/Modal";
import Spinner from "../../../components/UI/Spinner/Spinner";
import ServerError from "../../../components/UI/Errors/ServerError/ServerError";

import "./administration.scss";
import $api from "../../../api/http";
import MerchantNavigation from "../../../components/common/MerchantNavigation";
import { MerchantNavigation_tabsTypes } from "../../../dataUI/common/merchant";
import MerchantInfo from "../../../components/Merchant/MerchantInfo/MerchantInfo";
import ShopInfo from "../../../components/Shops/ShopInfo/ShopInfo";
import {
  getWeekDays,
  getWorkTimeInit,
} from "../../../tools/pages/administration";
import EditUser from "../../../components/Personal/EditUser/EditUser";
import { methodTypes } from "../../../dataUI/common/commonConsts";

const ModalEnableUserContent = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>
        Вы хотите разблокировать пользователя{" "}
        <strong>
          {props.enableUser &&
            props.enableUser.lastName +
              " " +
              props.enableUser.firstName +
              " " +
              props.enableUser.middleName}
        </strong>
      </p>
      <div>
        <button
          onClick={() => props.onClose()}
          style={{
            marginTop: "20px",
            marginRight: "10px",
            padding: "9px 20px",
            cursor: "pointer",
            border: "1px solid #9978E2",
            color: "#9978E2",
            backgroundColor: "transparent",
          }}
        >
          {t("prod.no")}
        </button>
        <button
          onClick={() => props.onEnableUser(props.enableUser)}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            color: "#fff",
            backgroundColor: "#9978E2",
          }}
        >
          {"Да"}
        </button>
      </div>
    </div>
  );
};

const Administration = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [shops, setShops] = useState(null);
  const [personnel, setPersonnel] = useState(null);
  const [paginationAccess, setPaginationAccess] = useState(true);

  //shops functional
  const [selectedShop, setSelectedShop] = useState(null);
  const [editedShop, setEditedShop] = useState(null);
  const [createShopMode, setCreateShopMode] = useState(false);
  const [deleteShopMode, setDeleteShopMode] = useState(null);

  const [successCreatedShop, setSuccessCreatedShop] = useState(false);
  const [successUpdatedShop, setSuccessUpdatedShop] = useState(false);
  const [successUpdatedMerchant, setSuccessUpdatedMerchant] = useState(false);

  // users functional
  const [selectedUser, setSelectedUser] = useState(null);
  const [createUserMode, setCreateUserMode] = useState(false);
  const [deleteUserMode, setDeleteUserMode] = useState(null);
  const [updateUserMode, setUpdateUserMode] = useState(null);
  const [enableUserMode, setEnableUserMode] = useState(null);

  const [successEnabledUser, setSuccessEnabledUser] = useState(false);
  const [successAddUser, setSuccessAddUser] = useState(false);
  const [successChangedUser, setSuccessChangedUser] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [selectedShopsIdsList, setSelectedShopsIdsList] = useState([]);

  useEffect(() => {
    selectedUser && setSelectedShopsIdsList(selectedUser.shopIds);
  }, [selectedUser]);

  /*TEST - changed default*/
  /*const [selectedTabType, setSelectedTabType] = useState(
    MerchantNavigation_tabsTypes.SHOPS
  );*/
  const [selectedTabType, setSelectedTabType] = useState(
    MerchantNavigation_tabsTypes.PERSONNEL,
  );

  const token = localStorage.getItem("token") || props.token;
  const merchantId = props.selectedMerchant
    ? props.selectedMerchant.id
    : JSON.parse(localStorage.getItem("info")).merchantId;

  useEffect(() => {
    getAllData();
  }, []);

  const getAllShops = async () => {
    /*const route = `/Merchants/${merchantId}/shops?limit=1110&offset=0&includeAccounts=true`;*/
    const route = `/Merchants/${merchantId}/shops?offset=0&includeAccounts=true`;
    $api
      .get(route)
      .then((res) => {
        setShops(res.data.results);
      })
      .catch((err) => {
        setError(true);
      });
  };
  const getAllPersonnel = async () => {
    try {
      setLoading(true);
      const personnelData = await $api.get(
        `/Accounts?merchantId=${merchantId}`,
      );
      setPersonnel(personnelData.data);
    } catch (err) {
      props.setError(true);
    }
  };

  const getAllData = async () => {
    setLoading(true);
    setError(false);

    await getAllShops();
    await getAllPersonnel();
    setLoading(false);
  };

  useEffect(() => {
    if (props.location.search === "?successChanged=true") {
      props.history.push("/admin");
      setSuccessChangedUser(true);
    }
  }, [props]);

  const paginationFunction = () => {
    setSelectedShop(null);
    setSelectedUser(null);
    setCreateUserMode(false);
    setLoading(true);
    $api
      .get(
        `/Merchants/${merchantId}/shops?limit=10000&offset=0&includeAccounts=true`,
      )
      .then((res) => {
        setShops(res.data.results);
        setPaginationAccess(false);
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => setLoading(false));
  };

  const onDeleteUser = (id) => {
    setLoading(true);

    $api
      .delete(`/Accounts/${id}`)
      .then((res) => {
        let i;
        const tempShops = shops
          ? [...shops].map((el, index) => {
              const returnValue = el.accounts.filter((el) => el.id === id);
              if (returnValue[0]) {
                i = index;
              }
              return returnValue;
            })
          : [];
        const newShops = shops ? [...shops] : [];
        newShops[i].accounts = newShops[i].accounts.filter(
          (el) => el.id !== id,
        );
        setShops(newShops);
        setDeleteUserMode(null);
        setLoading(false);
        setSelectedUser(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  const onDeleteShop = (value) => {
    const merchantId = value._merchantId;
    const shopId = value.id;
    setLoading(true);
    setDeleteShopMode(null);
    $api
      .delete(`/Merchants/${merchantId}/shops/${shopId}`)
      .then((res) => {
        const newShops = [...shops].filter((el) => el.id !== shopId);
        setShops(newShops);
        setLoading(false);
        setSelectedUser(null);
        setSelectedShop(null);
        setCreateUserMode(false);
        setUpdateUserMode(null);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  const onEnableUser = async () => {
    setLoading(true);
    setError(false);
    const id = enableUserMode.id;
    setEnableUserMode(null);

    const user = new UsersAPI();
    try {
      const result = await user.onEnableById(id);
      const updatedUser = result.data;
      let i;
      shops &&
        [...shops].forEach((el, index) => {
          const returnValue = el.accounts.filter((el) => el.id === id);
          if (returnValue[0]) {
            i = index;
          }
          return returnValue;
        });
      const newShops = shops ? [...shops] : [];
      newShops[i].accounts = newShops[i].accounts.map((el) => {
        if (el.id === updatedUser.id) {
          return updatedUser;
        } else return el;
      });
      setShops(newShops);
      const newSelectedShop = {
        ...selectedShop,
        accounts: [...selectedShop.accounts].map((el) =>
          el.id === updatedUser.id ? updatedUser : el,
        ),
      };

      setSelectedShop(newSelectedShop);
      setSelectedUser(updatedUser);

      setSuccessEnabledUser(true);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  if (shops == null || personnel == null)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  return (
    <>
      {/* modals */}
      {/* shops */}
      <Modal show={createShopMode} onClose={() => setCreateShopMode(false)}>
        <ModalCreateShopContent
          onSuccess={() => {
            setCreateShopMode(false);
            setSuccessCreatedShop(true);
          }}
          onError={() => {
            setCreateShopMode(false);
            setError(true);
          }}
          onClose={() => setCreateShopMode(false)}
          shops={shops}
          setShops={setShops}
          onError={() => {
            setCreateShopMode(false);
            setError(true);
          }}
          token={token}
          merchantId={merchantId}
          getAllShops={getAllShops}
        />
      </Modal>
      <Modal
        show={editedShop !== null && !editedShop.isNotModalWindow}
        onClose={() => setEditedShop(null)}
      >
        {editedShop && (
          <ModalEditShopContent
            onSuccess={() => {
              setEditedShop(null);
              setSuccessUpdatedShop(true);
            }}
            onError={() => {
              setEditedShop(null);
              setError(true);
            }}
            onClose={() => setEditedShop(null)}
            shops={shops}
            setShops={setShops}
            editedShop={{
              ...editedShop,
              weekDays: getWeekDays(editedShop, t).weekDays,
              exclusionDays: getWeekDays(editedShop, t).exclusionDays,
              settings: editedShop.settings
                ? {
                    ...JSON.parse(editedShop.settings),
                  }
                : {},
            }}
            token={token}
            onDelete={(v) => {
              setDeleteShopMode(v);
              setEditedShop(null);
            }}
            getAllShops={getAllShops}
          />
        )}
      </Modal>
      <Modal show={deleteShopMode} onClose={() => setDeleteShopMode(null)}>
        <ModalWithButton
          title={"Вы точно хотите удалить данную Торговую Точку?"}
          buttonTitle={"Да"}
          cancelTitle={t("prod.no")}
          onClose={() => setDeleteShopMode(null)}
          handler={() => onDeleteShop(deleteShopMode)}
        />
      </Modal>
      <Modal
        show={successCreatedShop}
        onClose={() => setSuccessCreatedShop(false)}
      >
        <ModalWithTitle title={"Вы успешно создали новую Торговую Точку!"} />
      </Modal>
      <Modal
        show={successUpdatedShop}
        onClose={() => setSuccessUpdatedShop(false)}
      >
        <ModalWithTitle
          title={t("global.modalText.shops.successfullyChanged")}
        />
      </Modal>
      <Modal
        show={successUpdatedMerchant}
        onClose={() => setSuccessUpdatedMerchant(false)}
      >
        <ModalWithTitle
          title={t("global.modalText.merchant.successfullyChanged")}
        />
      </Modal>
      {/* users */}
      <Modal
        show={deleteUserMode !== null}
        onClose={() => setDeleteUserMode(null)}
      >
        <ModalWithButton
          title={"Вы точно хотите удалить данного пользователя?"}
          buttonTitle={"Да"}
          cancelTitle={t("prod.no")}
          handler={() => onDeleteUser(deleteUserMode)}
          onClose={() => setDeleteUserMode(null)}
        />
      </Modal>
      <Modal show={enableUserMode} onClose={() => setEnableUserMode(null)}>
        <ModalEnableUserContent
          enableUser={enableUserMode}
          onEnableUser={(el) => onEnableUser(el)}
          onClose={() => setEnableUserMode(null)}
        />
      </Modal>
      <Modal show={successAddUser} onClose={() => setSuccessAddUser(false)}>
        <ModalWithTitle title={"Вы успешно добавили нового пользователя!"} />
      </Modal>
      <Modal
        show={successEnabledUser}
        onClose={() => setSuccessEnabledUser(false)}
      >
        <ModalWithTitle title={"Вы успешно разблокировали пользователя!"} />
      </Modal>
      <Modal
        show={successChangedUser}
        onClose={() => {
          setSuccessChangedUser(false);
          window.location.reload();
        }}
      >
        <ModalWithTitle title={"Вы успешно обновили пользователя!"} />
      </Modal>

      {error && <ServerError />}
      {!error && (
        <div className="administration">
          {loading && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </div>
          )}
          {!loading && (
            <>
              <div className="administration_first">
                <MerchantNavigation
                  selectedTabType={selectedTabType}
                  setSelectedTabType={setSelectedTabType}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  setSelectedUser={setSelectedUser}
                  setSelectedShop={setSelectedShop}
                  setUpdateUserMode={setUpdateUserMode}
                  setCreateUserMode={setCreateUserMode}
                />

                {selectedTabType === MerchantNavigation_tabsTypes.SHOPS && (
                  <ShopsList
                    admin={true}
                    shops={shops}
                    selectedShop={selectedShop}
                    setSelectedShop={(v) => {
                      setSelectedShop(v);
                      setSelectedUser(null);
                      setUpdateUserMode(null);
                      setCreateUserMode(false);
                    }}
                    searchString={searchString}
                    setEditedShop={setEditedShop}
                    onCreateNew={() => setCreateShopMode(true)}
                    pagination={shops.length === 10 ? paginationAccess : false}
                    paginationFunction={
                      shops.length === 10 ? paginationFunction : null
                    }
                  />
                )}

                {selectedTabType === MerchantNavigation_tabsTypes.PERSONNEL && (
                  <Personal
                    withoutHeader
                    selectedShop={{ accounts: personnel }}
                    selectedUser={selectedUser}
                    setSelectedUser={(v) => {
                      setSelectedUser(v);
                      setCreateUserMode(false);
                      setUpdateUserMode(null);
                    }}
                    onCreate={() => {
                      setCreateUserMode(true);
                      setSelectedUser(null);
                      setUpdateUserMode(null);
                    }}
                  />
                )}
              </div>

              <div className="administration_second">
                {selectedTabType === MerchantNavigation_tabsTypes.SHOPS &&
                  selectedShop && (
                    <Personal
                      selectedShop={selectedShop}
                      selectedUser={selectedUser}
                      setSelectedUser={(v) => {
                        setSelectedUser(v);
                        setCreateUserMode(false);
                        setUpdateUserMode(null);
                      }}
                      onCreate={() => {
                        setCreateUserMode(true);
                        setSelectedUser(null);
                        setUpdateUserMode(null);
                      }}
                    />
                  )}
                {selectedTabType === MerchantNavigation_tabsTypes.PERSONNEL &&
                  createUserMode &&
                  !updateUserMode &&
                  !selectedUser && (
                    <EditUser
                      {...props}
                      onError={() => {
                        setError(true);
                        setCreateUserMode(false);
                      }}
                      onSuccess={() => {
                        setCreateUserMode(null);
                        setSuccessAddUser(true);
                      }}
                      shops={shops}
                      user={updateUserMode}
                      onCancel={() => setCreateUserMode(false)}
                      selectedShop={selectedShop}
                      methodType={methodTypes.CREATE}
                    />
                  )}
                {selectedTabType === MerchantNavigation_tabsTypes.PERSONNEL &&
                  !createUserMode &&
                  selectedUser &&
                  !updateUserMode && (
                    <UserProfile
                      el={selectedUser}
                      onDelete={(id) => setDeleteUserMode(id)}
                      onEdit={(el) => {
                        setUpdateUserMode(el);
                        setCreateUserMode(false);
                      }}
                      onEnable={(el) => {
                        setEnableUserMode(el);
                      }}
                    />
                  )}
                {selectedTabType === MerchantNavigation_tabsTypes.PERSONNEL &&
                updateUserMode &&
                selectedUser ? (
                  <EditUser
                    {...props}
                    onError={() => {
                      setError(true);
                      setUpdateUserMode(null);
                    }}
                    onSuccess={() => {
                      setSuccessChangedUser(true);
                      setUpdateUserMode(null);
                    }}
                    shops={shops}
                    user={updateUserMode}
                    onCancel={() => setUpdateUserMode(false)}
                    methodType={methodTypes.UPDATE}
                  />
                ) : (
                  ""
                )}

                {selectedTabType === MerchantNavigation_tabsTypes.SHOPS &&
                  !selectedShop && (
                    <MerchantInfo
                      getAllShops={getAllShops}
                      setSuccessUpdatedMerchant={setSuccessUpdatedMerchant}
                    />
                  )}
              </div>
              <div className="administration_third">
                {selectedTabType === MerchantNavigation_tabsTypes.SHOPS &&
                  createUserMode &&
                  !updateUserMode &&
                  !selectedUser && (
                    <EditUser
                      {...props}
                      onError={() => {
                        setError(true);
                        setCreateUserMode(false);
                      }}
                      onSuccess={() => {
                        setCreateUserMode(null);
                        setSuccessAddUser(true);
                      }}
                      shops={shops}
                      user={updateUserMode}
                      onCancel={() => setCreateUserMode(false)}
                      selectedShop={selectedShop}
                      methodType={methodTypes.CREATE}
                    />
                  )}
                {selectedTabType === MerchantNavigation_tabsTypes.SHOPS &&
                  !createUserMode &&
                  selectedUser &&
                  !updateUserMode && (
                    <UserProfile
                      el={selectedUser}
                      onDelete={(id) => setDeleteUserMode(id)}
                      onEdit={(el) => {
                        setUpdateUserMode(el);
                        setCreateUserMode(false);
                      }}
                      onEnable={(el) => {
                        setEnableUserMode(el);
                      }}
                    />
                  )}
                {selectedTabType === MerchantNavigation_tabsTypes.SHOPS &&
                  updateUserMode && (
                    <EditUser
                      {...props}
                      onError={() => {
                        setError(true);
                        setUpdateUserMode(null);
                      }}
                      onSuccess={() => {
                        setSuccessChangedUser(true);
                        setUpdateUserMode(null);
                      }}
                      shops={shops}
                      user={updateUserMode}
                      onCancel={() => setUpdateUserMode(false)}
                      methodType={methodTypes.UPDATE}
                    />
                  )}

                {selectedTabType === MerchantNavigation_tabsTypes.SHOPS &&
                  !selectedUser &&
                  !createUserMode &&
                  selectedShop &&
                  editedShop && (
                    <ShopInfo
                      isNotModalWindow
                      onSuccess={() => {
                        setEditedShop(null);
                        setSuccessUpdatedShop(true);
                      }}
                      onError={() => {
                        setEditedShop(null);
                        setError(true);
                      }}
                      onClose={() => setEditedShop(null)}
                      shops={shops}
                      setShops={setShops}
                      editedShop={{
                        ...editedShop,
                        weekDays: getWeekDays(editedShop, t).weekDays,
                        exclusionDays: getWeekDays(editedShop, t).exclusionDays,
                        settings: editedShop.settings
                          ? {
                              ...JSON.parse(editedShop.settings),
                            }
                          : {},
                      }}
                      onDelete={(v) => {
                        setDeleteShopMode(v);
                        setEditedShop(null);
                      }}
                      getAllShops={getAllShops}
                    />
                  )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  info: state.auth.info,
  token: state.auth.token,
  merchants: state.admin.merchants,
  selectedMerchant: state.admin.selectedMerchant,
});

const dispatchToProps = (dispatch) => ({
  setMerchants: (arr) => dispatch(actions.setMerchants(arr)),
});

export default connect(mapStateToProps, dispatchToProps)(Administration);
