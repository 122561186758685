import React, { useEffect, useState } from "react";
import ConfirmAlert from "./ConfirmAlert";
import { useDispatch, useSelector } from "react-redux";
import "./Alert.scss";
import "../../../app.scss";
import { setAlertIsShown } from "../../../store/actions/alert/alertActions";
import { AlertTypes } from "../../../dataUI/common/alerts";
import ChoosingAlert from "./ChoosingAlert";

const Alert = () => {
  const dispatch = useDispatch();
  const alertInfo = useSelector((state) => state.alert.alertInfo);
  const [content, setContent] = useState(undefined);
  const [wrapAnimationEnd, setWrapAnimationEnd] = useState(false);

  useEffect(() => {
    switch (alertInfo.alertType) {
      case AlertTypes.ALERT_CONFIRM: {
        setContent(
          <ConfirmAlert
            alertInfo={alertInfo}
            closeAlert={closeAlert}
            setWrapAnimationEnd={setWrapAnimationEnd}
          />,
        );
        break;
      }
      case AlertTypes.ALERT_CHOOSING: {
        setContent(
          <ChoosingAlert alertInfo={alertInfo} closeAlert={closeAlert} />,
        );
        break;
      }
    }
  }, [alertInfo.alertType]);

  const closeAlert = () => {
    dispatch(setAlertIsShown(false));
  };

  return (
    <div
      className={
        !wrapAnimationEnd
          ? `Alert Animation_Opacity_0to1_04s`
          : `Alert Animation_Opacity_1to0_04s`
      }
      onMouseDown={closeAlert}
    >
      {content}
    </div>
  );
};

export default Alert;
