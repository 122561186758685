import * as actionTypes from "../actions/actionTypes";

const initialState = {
  selectedMerchant: localStorage.getItem("selectedMerchant")
    ? JSON.parse(localStorage.getItem("selectedMerchant"))
    : null,
  merchants: localStorage.getItem("merchants")
    ? JSON.parse(localStorage.getItem("merchants"))
    : null,
  selectedShop: localStorage.getItem("selectedShop")
    ? JSON.parse(localStorage.getItem("selectedShop"))
    : null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MERCHANTS: {
      return {
        ...state,
        selectedMerchant: "",
        merchants: action.merchants,
        selectedShop: "",
      };
    }
    case actionTypes.UPDATE_MERCHANTS: {
      return {
        ...state,
        merchants: [...action.merchants],
      };
    }
    case actionTypes.SET_SEL_MERCHANT: {
      return {
        ...state,
        selectedMerchant: action.val,
        selectedShop: "",
      };
    }
    case actionTypes.AUTH_LOGOUT: {
      return {
        ...state,
        selectedMerchant: "",
        merchants: null,
        selectedShop: "",
      };
    }
    case actionTypes.SET_SEL_SHOP: {
      return {
        ...state,
        selectedShop: action.val,
      };
    }
    default:
      return state;
  }
};

export default reducer;
