import * as actionTypes from "../actions/actionTypes";

const initialState = {
  token: null,
  expiresIn: null,
  info: null,
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_SUCCESS: {
      return {
        ...state,
        token: action.token,
        //userId: action.userId,
        //status: action.status,
        info: action.info ? action.info : state.info,
        expiresIn: action.expiresIn,
      };
    }
    case actionTypes.AUTH_LOGOUT: {
      return {
        ...state,
        token: null,
        expiresIn: null,
        info: null,
      };
    }
    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    default:
      return state;
  }
};

export default reducer;
