export const ru = {
  translation: {
    // global
    global: {
      edit: "Редактировать",
      delete: "Удалить",
      success: "Регистрация выполнена успешно",
      regDescription:
        'Для завершения регистрации перейдите в раздел меню "Приложения" – выберите "SAMO" и заполните все поля формы.',
      error: "Произошла ошибка.",

      modalText: {
        shops: {
          successfullyChanged: "Вы успешно изменили данные магазина!",
        },
        merchant: {
          successfullyChanged: "Вы успешно изменили Торговую Точку!",
        },
      },
    },
    common: {
      roles: {
        operator: "Оператор ТП",
        cashier: "Касир ТП",
        admin: "Администратор",
      },
    },
    joinposter: {
      merchantForm: {
        name: "Название заведения",
        description: "Описание заведения",
        logo: "Логотип заведения",
        category_id: "Категории мерчанта",
        regionId: "Регион торговой точки",
        categories: {
          1: "АЗС",
          2: "Рестораны",
          3: "Детские товары",
          4: "Продукты",
        },
        changeLogo: "Изменить логотип",
        addLogo: "Добавить логотип",
      },
      storeForm: {
        name: "Название торговой точки",
        address: "Адрес торговой точки",
        schedule: "График работи",
        paymentMerchantId: "MERCHANT_ID",
        paymentTerminalId: "TERMINAL_ID",
        payment_id_error:
          "Ограничение для данного поля - только цифры, 8 знаков",
        photo: "Фото торговой точки",
        changePhoto: "Изменить фото",
        addPhoto: "Добавить фото",
      },
    },
    // auth window
    auth: {
      support: {
        title: "Служба поддержки клиентов Samo",
        phone: "Телефон горячей линии",
        close: "Закрыть",
      },
      login: "Войти",
      posterAuth: "Авторизоваться через joinposter",
      storeImport: "Импорт торговых точек",
      storeImport_button: "Начать импорт",
      syncImport_button: "Синхронизировать данные",
      welcome: "Добро пожаловать в",
      error: "Серверная ошибка...",
      placeholderLogin: "Ваш логин*",
      placeholderPassword: "Пароль*",
      flag: "* - все поля обязательные для заполнения",
    },
    // instructionPage
    instructionPage: {
      site: "Посетить сайт разработчика:",
      email: "Электронная почта:",
      address: "Наш адрес:",
    },

    // header
    header: {
      merchant: "Торговля",
      admin: "Администрирование",
      role: {
        1: {
          1: "Окно админа сети",
        },
        "-1": {
          1: "Окно Суперюзера",
        },
      },
      order: "Номер заказа",
      dropDown: {
        1: "Личный кабинет",
        2: "Выйти",
        3: "Кабинет",
      },
      bottom: {
        history: {
          title1: "История заказов: ",
          title2: "История: ",
          1: "Сегодня",
          2: "Вчера",
          3: "За неделю",
          4: "За месяц",
          5: "За год",
        },
        links: {
          1: "Ассортимент",
          2: "заказы",
        },
      },
    },

    // shops list
    shopsList: {
      //title: 'Торговые Точки',
      tabName1: "Торговые Точки",
      tabName2: "Персонал",
      subtitle1: "Адрес / время роботи /",
      subtitle2: "контакт продавца",
      createNew: "Добавить торговую точку",
      searchPlaceHolder1: "Название торговой точки, город, улица",
      searchPlaceHolder2: "ФИО, должность",
    },
    // personal
    personal: {
      title: "Персонал торговой точки",
      subtitle1: "ФИО/ ",
      subtitle2: "Должность /",
      subtitle3: "Статус",
      createNew: "Добавить",
      empty: "Пока что персонала в этом магазине нет. Добавьте первого...",
    },
    // userProfile
    userProfile: {
      title: "Информация работника",
      phone: "Контактный телефон",
      role: "Роль",
      status: "Должность",
      login: "login",
      change: "Изменить",
      delete: "Удалить",
      // change user
      change_: {
        title: "Изменить информацию работника",
        titleCreate: "Создать работника",
        name: "Имя:",
        surname: "Фамилия:",
        lastName: "Отчество:",
        phone: "Номер телефона:",
        dolj: "Должность:",
        avatar: "Изменить аватар",
        addAvatar: "Добавить аватар",
        roleTitle: "Роль в админ панели:",
        login: "Логин:",
        changePassFlag: "Изменить пароль?",
        pass1: "Задайте пароль:",
        pass2: "Повторите пароль:",
        change: "Изменить",
        cancell: "Отмена",
        create: "Создать",
        avatarCreate: "Выберите аватар",
      },
      errors: {
        name: "Имя должно составлять от 2 до 30 символов",
        surname: "Фамилия должна составлять от 3 до 30 символов",
        lastName: "Отчество должен составлять от 3 до 30 символов",
        phone: "Не верный номер телефона",
        dolj: "Должность должна составлять от 2 до 50 символов",
        login: "Логин должен составлять от 4 до 25 символов",
        password: "Пароли должны совпадать и быть от 6 до 50 символов",
      },
    },

    // merchant
    merchant: {
      merchantRows: {
        personnelAccessRights: {
          title: "Права доступа / режим работы",
          changingShopsNames: "Назначенные магазины:",
          buttonSave: "Сохранить изменения",
        },
        merchantInfo: {
          name: "Имя Торговой сети:",
          openingHours: {
            title: "Режим работы:",
          },
          fieldNames: {
            city: "Город:",
            street: "Улиця:",
            buildingNumber: "Номер дома:",
            postalCode: "Почтовый индекс:",
            notificationPhone: "телефон для sms:",
            notificationEmail: "email для нотификаций:",
            sendSms: "Использовать Sms для нотификаций",
            sendEmail: "Использовать email для нотификаций",
            logoLink: "Логотип(ссылка):",
          },
          titleNames: {
            address: "Адрес:",
            notifications: "Нотификации:",
            contacts: "Контакты:",
            links: "Ссылки:",
            logo: "Логотип:",
          },
          buttonNames: {
            saveChanges: "Сохранить изменения",
          },
          errors: {
            nameFieldError:
              "Имя Торговой точки должно составлять от 2 до 30 символов",
          },
        },
        shopInfo: {
          title: "Информация торговой точки",
          openHours: {
            title: "Режим работы:",
            checkboxText: "Рабочий день",
            days: {
              d1: "Понедельник",
              d2: "Вторник",
              d3: "Среда",
              d4: "Четверг",
              d5: "Пятница",
              d6: "Суббота",
              d7: "Воскресенье",
            },
            buttons: {
              addToAllShops: "Добавить для всех магазинов",
              changeInSelectedShops: "Изменить во всех магазинах",
            },
          },
          exclusionDays: {
            title: "Дни-исключения:",
            button_AddingNewDate1: "Добавить новую дату",
            button_AddingNewDate2: "Добавить",
            dateChecker_description: "Дата/время",
            radioInput_dayStatus1: "Рабочий день",
            radioInput_dayStatus2: "Выходной",
            alerts: {
              choosingAlert:
                "Добавить исключения для всех существующих магазинов или только для тех, которые будут созданы в будущем?",
              choosingAlert_button1: "Для всех",
              choosingAlert_button2: "Для новых",
              choosing1_confirm: "Исключение добавлено успешно",
            },
          },
        },
      },
      title: "Информация торговой сети",
      workingIn: {
        1: "Вы работаете в магазине:",
        2: "по адресу: г. ",
        3: ", ул. ",
        4: ", д. ",
      },
      chooseMerchant: "Выберите фирму: ",
      choosedMerchant: "Фирма: ",
      notChoosedMerchant: "Выберите фирму чтобы продолжить работу",
      chooseFromList: "Выберите из списка",
      orders: {
        yes: "Да",
        toWork: {
          modal: {
            1: "Вы точно хотите взять в работу ",
            2: " заказ?",
          },
          status: "Новый",
          label: "Взять в работу",
        },
        cancel: {
          modal: {
            1: "Вы точно хотите отменить ",
            2: " заказ?",
          },
          status: "Отменен",
          label: "Отменить",
        },
        pending: {
          modal: {
            1: "Вы точно хотите подтвердить ",
            2: " заказ?",
          },
          status: "Оплачивается",
          label: "Подтвердить",
        },
        inProcess: {
          modal: {
            1: "Вы точно хотите начать приготовление ",
            2: " заказа?",
          },
          status: "В процессе",
          label: "Начать приготовление",
        },
        completed: {
          modal: {
            1: "заказ номер ",
            2: " точно готов?",
          },
          status: "Готов",
          label: "Принять готовность",
        },
        issue: {
          modal: {
            1: "заказ номер ",
            2: " выдан?",
          },
          status: "Выдан",
          label: "Выдача",
        },
        returned: {
          modal: {
            1: "заказ номер ",
            2: " возвращен клиентом?",
          },
          status: "Возврат",
          label: "Возврат",
        },
        pend: {
          status: "Оплаченный",
        },
        error: {
          status: "Ошибка",
        },
      },
      ordersList: {
        name: "Заказ",
        previous: "Выгрузить предыдущие ",
        next: "Выгрузить следующие ",
        list: "список заказов",
        header: {
          1: "Номер заказа /",
          2: " статус",
          3: "Сумма заказа /",
          4: " Время заказа",
        },
        footer: {
          1: "Сумма заказов по торговой точке:",
        },
      },
    },

    // personal cabinet
    perCab: {
      title: "Личный кабинет",
      dolj: "Должность:",
      roleName: "Роль в админ панели:",
      changePass: {
        title: "Сменить пароль:",
        old: "Введите старый пароль:",
        new: "Введите новый пароль:",
        button: "Сменить пароль",
      },
      successChnage: "Вы успешно изменили пароль!",
    },

    // order details
    orDet: {
      title: "детали заказа",
      header: {
        1: "Наименование / цена",
        2: "Колличество /",
        3: " вес",
        4: "Сумма",
      },
      client: {
        title: "Клиент: ",
        bazaniy_time: "Желаемое время получения: ",
        comment: "Коментарий: ",
      },
      footer: {
        1: "Всего:",
      },
    },

    // order detail item
    orDetItem: {
      100: "Цена за ",
      1: "Цена за 1 шт.:",
    },

    // categories
    cats: {
      title: "КАТЕГОРИИ",
      create: {
        title1: "Создать",
        title2: "Cоздать новую подкатегорию в ",
        title3: "Создать новую Top-level категорию",
        name: "Введите название: ",
        url: "Введите ссылку на фото: ",
        photo: "Отображение фото: ",
        button: "Создать",
      },
      update: {
        title: "Изменить категорию ",
        name: "Изменить название: ",
        url: "Изменить ссылку на фото: ",
        photo: "Отображение фото: ",
        button: "Изменить",
      },
      table: {
        1: "Название",
        2: "Фото",
        3: "Подкатегории",
      },
      successCreate: "Вы успешно создали категорию ",
      successChange: "Вы успешно изменили категорию ",
      deleteMode: "Вы точно хотите удалить категорию: ",
      yes: "Да",
    },

    storage: {
      bm: {
        1: "страница товаров",
        2: "страница категорий",
      },
      shop: {
        1: "Выберите магазин: ",
        2: "Выбранный магазин: ",
        3: "Выберите магазин чтобы подолжить работу",
        4: "В этой фирме магазинов не найдено.",
      },
    },

    prod: {
      title: "ассортимент",
      button1: "Добавить товар",
      search: {
        name: "Название",
        cat: "Категория",
        search: "Поиск",
        cancel: "Отмена",
        cancel2: "Отменить",
        selectTitle: "Нажмите на товар чтобы выбрать его",
        add: "Добавить",
      },
      table: {
        1: "Имя",
        2: "Фото",
        3: "Категория",
        4: "Цена ₴",
        5: "Количество",
        6: "К.",
        7: "Опции",
      },
      successCreate: "Вы успешно создали товар ",
      successChange: "Вы успешно изменили товар ",
      deleteMode: "Вы точно хотите удалить товар: ",
      yes: "Да",
      no: "Нет",
    },
    prodErrors: {
      0: "Имя должно быть от 2 до 50 символов",
      1: "Не выбрана категория",
      2: "Ссылка на фото не должна превышать 2000 символов",
      3: "Не выбраны единицы измерения",
      4: "Не указано количество товаров",
      5: "Не указана цена",
    },
    createProd: {
      title: "добавить товар",
      title2: "изменить товар",
      closeLabel: "Закрыть",
      name: "Название: ",
      descr: "Описание: ",
      cat: "Категория: ",
      url: "Фото: ",
      type: "Единицы измерения: ",
      typeList: {
        1: "вес",
        2: "шт. (мл.)",
        3: "шт. (г.)",
      },
      sizeList: {
        1: "размер (мл.): ",
        2: "размер (г.): ",
      },
      price: "Цена, ₴ ",
      count: {
        1: "Укажите сколько есть сейчас в наличие товаров ",
        2: "шт.",
        3: "г.",
      },
      button1: "Добавить",
      button2: "Отменить",
      button3: "Изменить",
    },

    import: {
      loading: "Подождите пока файл импортируется",
      error: "Что-то пошло не так...",
      success: "Импорт прошел успешно!",
      title: "Импорт",
    },
  },
};
