import React, { useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import datePicker_icon from "../../../assets/images/components/DateChecker/datePicker_icon.svg";
import moment from "moment-timezone";
import uk from "date-fns/locale/uk";
import "./DateChecker.scss";
import "../../../styles/libs/react-datepicker.css";

registerLocale("uk", uk);

const DateChecker = ({
  changeField,
  dateCheckerOption,
  description,
  wrapStyles,
  isInactive = false,
  showTimeSelect = false,
  onChangeWithRules,
  parName,
  minDate,
  maxDate,
  insideChanging,
}) => {
  const editPageRef = useRef(null);
  const dateCheckerOptionRef = useRef(null);

  const [selectedDate, setSelectedDate] = useState(
    dateCheckerOption ? new Date(dateCheckerOption) : new Date(),
  );

  /*const filterPassedTime = (time) => {
    if (time) {
      const currentDate = new Date();
      const selectedDate = new Date(time);
      return currentDate.getTime() < selectedDate.getTime();
    }
  };
*/
  useEffect(() => {
    insideChanging && setSelectedDate(new Date(dateCheckerOption));
  }, [dateCheckerOption]);

  useEffect(() => {
    const formattedDate = moment(selectedDate).format();
    changeField(formattedDate);
  }, [selectedDate]);

  return (
    <div
      ref={editPageRef}
      className="DateChecker"
      style={wrapStyles ? wrapStyles : {}}
    >
      <div className="DatePickerWrap" style={wrapStyles ? wrapStyles : {}}>
        <div
          className="DatePickerBox"
          style={
            isInactive
              ? {
                  backgroundColor: "rgb(245, 245, 245)",
                  border: "1px solid #e2e4e5",
                }
              : {}
          }
        >
          <div ref={dateCheckerOptionRef} className="DateCheckerLeft">
            {!isInactive && (
              <DatePicker
                maxDate={maxDate}
                minDate={minDate ? minDate : moment().toDate()}
                timeIntervals={60}
                selected={!isInactive ? selectedDate : ""}
                onChange={
                  !onChangeWithRules
                    ? (date) => setSelectedDate(date)
                    : (date) =>
                        onChangeWithRules(date, setSelectedDate, parName)
                }
                dateFormat={showTimeSelect ? "Pp" : "P"}
                locale="uk"
                className="DatePicker"
                popperClassName="Popper"
              />
            )}
          </div>
          <div className="DateCheckerRight">
            {!isInactive && (
              <img
                className="DateCheckerIcon"
                src={datePicker_icon}
                alt="вибрати"
              />
            )}
          </div>
          {description && (
            <div className="DateCheckerDescription">{description}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DateChecker;
