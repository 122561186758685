import React from "react";

import "./ShopItem.scss";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

//icons
//import food from '../../../assets/images/components/ShopItem/food.svg';
import geo from "../../../../assets/images/components/ShopItem/geo.svg";
import phone from "../../../../assets/images/components/ShopItem/phone.svg";
//import time from '../../../assets/images/components/ShopItem/time.svg';

const ShopItem = (props) => {
  const iconEditStyle = {
    height: "24px",
    width: "24px",
    color: "#9978E2",
  };
  const iconEditContentStyle = {
    height: "20px",
    width: "20px",
    color: "#9978E2",
  };

  const { name, city, street, buildingNumber, postalCode } = props.el;

  const onItemClickHandler = () => {
    props.onSelect();
    props.setEditedShop &&
      props.setEditedShop({ ...props.el, isNotModalWindow: true });
  };

  const onEditIconClickHandler = (e) => {
    e.stopPropagation();
    props.onEdit();
  };
  return (
    <div
      className={
        props.selected
          ? "shop-item-container shop-item-container-selected"
          : "shop-item-container"
      }
      onClick={onItemClickHandler}
    >
      <div className="shop-item-container__header">
        <h2 onClick={props.onSelect}>{name}</h2>
        <IconButton
          onClick={props.admin ? (e) => onEditIconClickHandler(e) : () => {}}
          style={iconEditStyle}
        >
          {props.admin && <EditIcon fontSize="small" />}
        </IconButton>
      </div>

      <div onClick={props.onSelect} className="shop-item-container__content">
        <div className="li">
          <img src={geo} />
          <p>{`г. ${city}, ул. ${street}, ${buildingNumber}, почтовый индекс: ${postalCode}`}</p>
        </div>
        {/* <div className="li">
                    <img src={time} alt="" />
                    <p>{'8:00 - 22:00'}</p>
                </div> */}
        <div className="li">
          <img src={phone} alt="" />
          <p>{props.el.phone}</p>
        </div>
        {/* <div className="li food">
                    <img src={food} alt="" />
                    <p>{'Заканчивается'}</p>
                </div> */}
      </div>
    </div>
  );
};

export default ShopItem;
