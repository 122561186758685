import React, { useEffect, useState } from "react";
import "./ExclusionDays.scss";
import { useTranslation } from "react-i18next";
import ExclusionDay from "./ExclusionDay";
import ExclusionDaysAddingNew from "./ExclusionDays_AddingNew";
import { AlertTypes } from "../../../../../dataUI/common/alerts";
import {
  setAlertInfo,
  setPromptInfo,
} from "../../../../../store/actions/alert/alertActions";
import { useDispatch } from "react-redux";
import MerchantService from "../../../../../api/services/MerchantService";
import { showError } from "../../../../../tools/common/additionalFuncs";

const ExclusionDays = ({
  exclusionDaysInit,
  setExclusionDaysFormData,
  isMerchant,
  getAllShops,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [addingNewDate_mode, setAddingNewDate_mode] = useState(false);
  const [dates, setDates] = useState(exclusionDaysInit);

  /*  useEffect(() => {
      setDates(exclusionDaysInit);
      setAddingNewDate_mode(false);
  }, [exclusionDaysInit]);*/

  useEffect(() => {
    setExclusionDaysFormData(dates);
  }, [dates]);

  const onRemoveDateHandler = (id) => {
    setDates((prev) => {
      const newDates = [...prev];
      newDates.splice(id, 1);
      return newDates;
    });
  };

  const putWeekDaysToAllShops = async (newDate, confirmInfo) => {
    try {
      const selectedMerchant = JSON.parse(
        localStorage.getItem("selectedMerchant"),
      );
      const response = await MerchantService.addMerchantDatesToAllShops(
        selectedMerchant.id,
        [newDate],
      );

      setDates((prev) => {
        const newDates = [...prev, newDate];
        return newDates;
      });

      dispatch(setAlertInfo(confirmInfo));

      setTimeout(() => {
        getAllShops();
      }, 1000);

      return response;
    } catch (error) {
      dispatch(
        setPromptInfo({
          promptText: t("global.error"),
        }),
      );
      showError(error, "'addMerchantDatesToAllShops' failed.");
    }
  };

  const onAddingNewDateHandler = (newDate) => {
    if (isMerchant) {
      const alertInfo = {
        alertType: AlertTypes.ALERT_CHOOSING,
        titleText: t(
          "merchant.merchantRows.shopInfo.exclusionDays.alerts.choosingAlert",
        ),
        firstChoice_buttonText: t(
          "merchant.merchantRows.shopInfo.exclusionDays.alerts.choosingAlert_button1",
        ),
        secondChoice_buttonText: t(
          "merchant.merchantRows.shopInfo.exclusionDays.alerts.choosingAlert_button2",
        ),
        firstChoice_confirmF: () => {
          const confirmInfo = {
            alertType: AlertTypes.ALERT_CONFIRM,
            titleText: t(
              "merchant.merchantRows.shopInfo.exclusionDays.alerts.choosing1_confirm",
            ),
            confirmButtonText: "ОК",
            confirmButtonF: () => null,
          };
          putWeekDaysToAllShops(newDate, confirmInfo);
        },
        secondChoice_confirmF: () => {
          setDates((prev) => {
            const newDates = [...prev, newDate];
            return newDates;
          });
        },
      };
      dispatch(setAlertInfo(alertInfo));
    } else {
      setDates((prev) => {
        const newDates = [...prev, newDate];
        return newDates;
      });
    }
  };

  if (!dates) return <div />;
  return (
    <div className="ExclusionDays">
      <h4>{t("merchant.merchantRows.shopInfo.exclusionDays.title")}</h4>
      <div className="ExclusionDaysArea">
        {dates.map((date, index) => (
          <ExclusionDay
            key={index}
            date={date}
            isLastChild={dates.length === index + 1}
            id={index}
            onRemove={onRemoveDateHandler}
          />
        ))}
      </div>
      {!addingNewDate_mode && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "2px",
          }}
        >
          <button
            className="AddingNewTime_AddingButton"
            onClick={() => setAddingNewDate_mode((prev) => !prev)}
          >
            {t(
              "merchant.merchantRows.shopInfo.exclusionDays.button_AddingNewDate1",
            )}
          </button>
        </div>
      )}
      {addingNewDate_mode && (
        <ExclusionDaysAddingNew onAddingNewDate={onAddingNewDateHandler} />
      )}
    </div>
  );
};

export default ExclusionDays;
