import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./OpeningHours.scss";
import OpeningHoursUnit from "./OpeningHoursUnit";
import { AlertTypes } from "../../../../../dataUI/common/alerts";
import {
  setAlertInfo,
  setPromptInfo,
} from "../../../../../store/actions/alert/alertActions";
import { useDispatch } from "react-redux";
import AuthService from "../../../../../api/services/AuthService";
import { showError } from "../../../../../tools/common/additionalFuncs";
import MerchantService from "../../../../../api/services/MerchantService";

const OpeningHours = ({
  isNotModalWindow,
  weekDaysInit,
  setWeekDaysFormData,
  weekDaysFormData,
  getAllShops,
  isMerchant,
  h1center,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [allShopsPanel_isActive, setAllShopsPanel_isActive] = useState(false);
  const [selectedUnitsIds, setSelectedUnitsIds] = useState([]);

  const putWeekDaysToAllShops = async (dates, confirmInfo) => {
    try {
      const selectedMerchant = JSON.parse(
        localStorage.getItem("selectedMerchant"),
      );
      const response = await MerchantService.addMerchantDatesToAllShops(
        selectedMerchant.id,
        dates,
      );

      setSelectedUnitsIds([]);
      setAllShopsPanel_isActive(false);
      dispatch(setAlertInfo(confirmInfo));
      getAllShops &&
        setTimeout(() => {
          getAllShops();
        }, 1000);
      return response;
    } catch (error) {
      dispatch(
        setPromptInfo({
          promptText: t("global.error"),
        }),
      );
      showError(error, "'addMerchantDatesToAllShops' failed.");
    }
  };

  const onClickAddingButton = () => {
    const addingsDays = weekDaysFormData.filter((day) =>
      selectedUnitsIds.includes(day.dayOfWeek),
    );

    const confirmInfo = {
      alertType: AlertTypes.ALERT_CONFIRM,
      titleText: t(
        "merchant.merchantRows.shopInfo.exclusionDays.alerts.choosing1_confirm",
      ),
      confirmButtonText: "ОК",
      confirmButtonF: () => null,
    };

    putWeekDaysToAllShops(addingsDays, confirmInfo);
  };

  if (!weekDaysInit) return <div />;
  return (
    <div
      style={isNotModalWindow ? { display: "block", width: "100%" } : {}}
      className="OpeningHours"
    >
      <div
        className="OpeningHours_TitleBox"
        style={h1center ? { display: "flex", justifyContent: "center" } : {}}
      >
        <h4>{t("merchant.merchantRows.shopInfo.openHours.title")}</h4>

        {isMerchant && (
          <div
            onClick={() => setAllShopsPanel_isActive((prev) => !prev)}
            className="OpeningHours_ButtonAllShops"
          >
            {t(
              "merchant.merchantRows.shopInfo.openHours.buttons.addToAllShops",
            )}
          </div>
        )}
      </div>
      {weekDaysFormData.map((day, index) => (
        <OpeningHoursUnit
          key={index}
          day={day}
          setWeekDaysFormData={setWeekDaysFormData}
          allShopsPanel_isActive={allShopsPanel_isActive}
          setSelectedUnitsIds={setSelectedUnitsIds}
        />
      ))}

      {allShopsPanel_isActive ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            paddingRight: "2px",
          }}
        >
          <button
            className="OpeningHours_AddingButton"
            onClick={onClickAddingButton}
          >
            {t(
              "merchant.merchantRows.shopInfo.openHours.buttons.changeInSelectedShops",
            )}
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default OpeningHours;
