import React, { useEffect, useState } from "react";
import "./OpeningHours.scss";

const TimeInput = ({
  valueInit,
  id,
  onInputChange,
  isNotValid,
  disabled,
  inputStyles,
}) => {
  const [time, setTime] = useState(
    valueInit ? valueInit.slice(0, valueInit.length - 3) : "",
  );

  useEffect(() => {
    setTime(valueInit ? valueInit.slice(0, valueInit.length - 3) : "");
  }, [valueInit]);

  const onChangeTime = (e) => {
    setTime(e.target.value);
    onInputChange(e.target.value, id);
  };

  return (
    <div>
      <input
        disabled={disabled}
        style={
          inputStyles && isNotValid
            ? {
                ...inputStyles,
                border: "1px solid red",
                color: "red",
              }
            : !inputStyles && isNotValid
              ? {
                  border: "1px solid red",
                  color: "red",
                }
              : inputStyles && !isNotValid
                ? inputStyles
                : {}
        }
        className="TimeInput"
        type="text"
        value={time}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => onChangeTime(e)}
      />
    </div>
  );
};

export default TimeInput;
