import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import * as actions from "../../store/actions/index";

import Administration from "./window/Administration";
import Spinner from "../../components/UI/Spinner/Spinner";
import Error from "../../components/UI/Errors/ServerError/ServerError";

// assets
import bigShopImg from "../../assets/images/pages/Storage/big_shop_grey_icon.svg";
import "./administration.scss";
import $api from "../../api/http";

const AdminWindow = (props) => {
  const { t } = useTranslation();
  const { selectedMerchant, setMerchants, merchants } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const roleId = localStorage.getItem("info")
    ? JSON.parse(localStorage.getItem("info")).roleId
    : props.info.roleId;

  const userIsSuperUser = roleId === -1;
  const userIsAdmin = roleId === 1;

  useEffect(() => {
    if (userIsSuperUser && !merchants) {
      setLoading(true);
      setError(false);
      $api
        .get("/Merchants")
        .then((res) => {
          setMerchants(res.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    }
  }, []);

  if (loading)
    return (
      <div className="administration-loading-container">
        <Spinner />
      </div>
    );

  if (error) return <Error />;

  return (
    <div className="administration-page-container">
      {userIsAdmin && <Administration {...props} roleId={roleId} />}
      {userIsSuperUser && selectedMerchant && (
        <Administration {...props} roleId={roleId} />
      )}
      {userIsSuperUser && !selectedMerchant && (
        <div className="not-selected-shop">
          <div className="centered-container">
            <img src={bigShopImg} alt="" />
            <span>{t("merchant.notChoosedMerchant")}</span>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedMerchant: state.admin.selectedMerchant,
  token: state.auth.token,
  info: state.auth.info,
  merchants: state.admin.merchants,
});

const mapDispatchToProps = (dispatch) => ({
  setMerchants: (arr) => dispatch(actions.setMerchants(arr)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminWindow);
