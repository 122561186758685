import React from "react";
import "./ExclusionDays.scss";
import { useTranslation } from "react-i18next";

const AddingNewFormRadio = ({ formRadioTypes, onChangeRadio }) => {
  const { t } = useTranslation();

  const onChangeHandler = (e) => {
    onChangeRadio(e.target.id);
  };

  return (
    <form className="AddingNewFormRadio">
      <div>
        <input
          type="radio"
          id={formRadioTypes[0]}
          name="radio-group"
          defaultChecked
          onChange={onChangeHandler}
        />
        <label htmlFor={formRadioTypes[0]}>
          {t(
            "merchant.merchantRows.shopInfo.exclusionDays.radioInput_dayStatus1",
          )}
        </label>
      </div>
      <div>
        <input
          type="radio"
          id={formRadioTypes[1]}
          name="radio-group"
          onChange={onChangeHandler}
        />
        <label htmlFor={formRadioTypes[1]}>
          {t(
            "merchant.merchantRows.shopInfo.exclusionDays.radioInput_dayStatus2",
          )}
        </label>
      </div>
    </form>
  );
};

export default AddingNewFormRadio;
