import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";

const RHFInput = ({
  register,
  keyName,
  error,
  description,
  inputType,
  wrapStyles,
  isPhoneNumber = false,
  control,
  isDisabled,
}) => {
  const [inputIsValid, setInputIsValid] = useState(true);

  useEffect(() => {
    setInputIsValid(error ? !error.message : true);
  }, [error]);

  return (
    <div style={wrapStyles ? wrapStyles : {}} className="RHFInput">
      <div className="InputWrap">
        <div
          className="InputDescription"
          /*style={
            inputIsValid
              ? { color: `rgb(112, 120, 127)` }
              : { color: `rgba(255, 0, 0, 0.7)` }
          }*/
        >
          {description}
        </div>

        {!isPhoneNumber ? (
          <input
            disabled={isDisabled}
            {...register(keyName)}
            className="Input"
            type={inputType ? inputType : "text"}
            style={
              !inputIsValid
                ? { border: `1px solid rgba(221, 60, 60, 0.5)` }
                : { border: `1px solid rgb(118, 118, 118)` }
            }
          />
        ) : (
          <Controller
            control={control}
            name={keyName}
            render={({ field: { onChange, name, value } }) => (
              <NumberFormat
                className="Input"
                format="+38(0##) ### ## ##"
                mask="_"
                type="text"
                allowEmptyFormatting
                name={name}
                onChange={onChange}
                value={value}
                style={
                  !inputIsValid
                    ? { border: `1px solid rgba(221, 60, 60, 0.5)` }
                    : { border: `1px solid rgb(118, 118, 118)` }
                }
              />
            )}
          />
        )}
      </div>

      <div style={{ height: "10px", paddingTop: "2px" }}>
        {error ? <div className="InputError">{String(error.message)}</div> : ""}
      </div>
    </div>
  );
};

export default RHFInput;
