import React from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  AdminHeaderLinks,
  Bell,
  SearchArea,
  UserInfoBlock,
} from "./components";

import * as actions from "../../../../store/actions/index";

// icons
import UTakeLogo from "../../../../assets/images/wrapper/header/logo.svg";

const HeaderTop = (props) => {
  const { t } = useTranslation();
  const { logout } = props;
  const location = useLocation();
  const history = useHistory();
  const info = JSON.parse(localStorage.getItem("info"));
  const roleId = info && info.roleId;

  const isUserAdmin = [-1, 1].includes(roleId);
  const isUserSuperAdmin = roleId === -1;

  const basketColor = location.pathname === "/" ? "blue" : "black";
  const editColor = location.pathname === "/admin/" ? "blue" : "black";

  return (
    <div className="header__top">
      <div
        className={
          isUserAdmin
            ? "header__top__blackArea w344"
            : "header__top__blackArea w100"
        }
      >
        <div
          className="header__top__blackArea_logo"
          onClick={() => history.push("/main/orders")}
        >
          <img src={UTakeLogo} alt="logo" />
        </div>
        {isUserAdmin && (
          <div className="header__top__blackArea_h1">
            <h1>
              {isUserSuperAdmin ? t("header.role.-1.1") : t("header.role.1.1")}
            </h1>
          </div>
        )}
      </div>
      {!isUserAdmin && <img alt="" src={info && info.logo} className="logo" />}
      {!isUserAdmin && <SearchArea />}
      {isUserAdmin && (
        <AdminHeaderLinks basketColor={basketColor} editColor={editColor} />
      )}
      <div className="flex-column">
        <div className="flex-row">
          <Bell />
          <div className="drop-down-position">
            <UserInfoBlock logout={logout} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(actions.logout()),
});

export default connect(null, mapDispatchToProps)(HeaderTop);
