import React from "react";
import { useTranslation } from "react-i18next";

// icon
import close from "../../../../assets/images/pages/Products/ProdForm/close.svg";

export const SuccessArchivated = (props) => {
  const { t } = useTranslation();
  const { onClose } = props;

  return (
    <div className="modal-window-archive-success-modal">
      <div className="close-div" onClick={onClose}>
        <p>{t("createProd.closeLabel")}</p>
        <img src={close} alt="" />
      </div>
      <p>{t("Ви успішно архівували повідомлення")}</p>
      <div className="buttons">
        <button onClick={onClose} className="cancel">
          <span>{t("OK")}</span>
        </button>
      </div>
    </div>
  );
};
