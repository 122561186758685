import {
  SET_ALERT_INFO,
  SET_ALERT_SHOWN,
  SET_PROMPT_INFO,
  SET_PROMPT_SHOWN,
} from "./alertTypes";

export const setAlertIsShown = (isAlertShown) => ({
  type: SET_ALERT_SHOWN,
  isAlertShown,
});

export const setAlertInfo = (alertInfo) => ({
  type: SET_ALERT_INFO,
  alertInfo,
});

export const setPromptIsShown = (isPromptShown) => ({
  type: SET_PROMPT_SHOWN,
  isPromptShown,
});

export const setPromptInfo = (promptInfo) => ({
  type: SET_PROMPT_INFO,
  promptInfo,
});
