import React from "react";
import "./personalItem.scss";

const PersonalItem = (props) => {
  const { firstName, lastName, middleName, title, isLocked } = props.el;
  const { selectedUser, setSelectedUser } = props;

  return (
    <div
      className={
        selectedUser && selectedUser.id === props.el.id
          ? "personal-item-container personal-item-container-selected"
          : "personal-item-container"
      }
      onClick={() => setSelectedUser(props.el)}
    >
      <div className="personal-item-container_left">
        <p
          className={
            selectedUser && selectedUser.id === props.el.id
              ? "personal-item-container_left_p_sl"
              : null
          }
        >
          {`${firstName} ${lastName} ${middleName}`}
        </p>
        <span>{title}</span>
      </div>
      {isLocked && (
        <div className="personal-item-container_right">
          <p>{"Заблокирован"}</p>
        </div>
      )}
    </div>
  );
};

export default PersonalItem;
