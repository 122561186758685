import React, { useEffect } from "react";
import "./ShopInfo.scss";
import { useTranslation } from "react-i18next";
import { ModalEditShopContent } from "../../../pages/Administration/modals/modals";

const ShopInfo = (props) => {
  const { t } = useTranslation();

  return (
    <div className="ShopInfo">
      <h2 className="PersonnelAccessRights_title">
        {t("merchant.merchantRows.shopInfo.title")}
      </h2>

      <ModalEditShopContent
        isNotModalWindow={props.isNotModalWindow}
        onSuccess={props.onSuccess}
        onError={props.onError}
        onClose={props.onClose}
        shops={props.shops}
        setShops={props.setShops}
        editedShop={props.editedShop}
        onDelete={props.onDelete}
        getAllShops={props.getAllShops}
      />
    </div>
  );
};

export default ShopInfo;
