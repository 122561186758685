import React, { useEffect, useState } from "react";
import { validation } from "../../../tools/validation";
import CommonMerchantForm from "../CommonMerchantForm/CommonMerchantForm";
import { useTranslation } from "react-i18next";
import $api from "../../../api/http";
import { useDispatch } from "react-redux";
import { updateMerchants } from "../../../store/actions/admin";
import { checkAreKeysExist } from "../../../tools/common/additionalFuncs";

const EditMerchantContent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const name = props.editedMerchant && props.editedMerchant.name;
  const city = props.editedMerchant && props.editedMerchant.city;
  const street = props.editedMerchant && props.editedMerchant.street;
  const buildingNumber =
    props.editedMerchant && props.editedMerchant.buildingNumber;
  const postalCode = props.editedMerchant && props.editedMerchant.postalCode;

  const phone = props.editedMerchant && props.editedMerchant.settings.phone;
  const email = props.editedMerchant && props.editedMerchant.settings.email;
  const logoUrl = props.editedMerchant && props.editedMerchant.settings.logoUrl;

  const notificationPhone =
    checkAreKeysExist(props.editedMerchant, ["settings", "notifications"]) &&
    props.editedMerchant.settings.notifications.phone;
  const notificationEmail =
    checkAreKeysExist(props.editedMerchant, ["settings", "notifications"]) &&
    props.editedMerchant.settings.notifications.email;
  const sendSms =
    checkAreKeysExist(props.editedMerchant, ["settings", "notifications"]) &&
    props.editedMerchant.settings.notifications.sendSms;
  const sendEmail =
    checkAreKeysExist(props.editedMerchant, ["settings", "notifications"]) &&
    props.editedMerchant.settings.notifications.sendEmail;

  const _merchantId = props.editedMerchant && props.editedMerchant.id;

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState([]);
  const [formIsValid, setFormIsValid] = useState(true);
  const [formError, setFormError] = useState(null);

  const [weekDaysFormData, setWeekDaysFormData] = useState(
    props.editedMerchant.weekDays,
  );
  const [exclusionDaysFormData, setExclusionDaysFormData] = useState(
    props.editedMerchant.exclusionDays,
  );

  useEffect(() => {
    setForm([
      {
        value: name ? name : "",
        rules: {
          required: true,
          minLength: 2,
          maxlength: 30,
        },
        error: t("merchant.merchantRows.merchantInfo.errors.nameFieldError"),
        name: "name",
        valid: true,
        touched: false,
      },
      {
        value: city ? city : "",
        rules: {
          required: true,
          minLength: 2,
          maxlength: 30,
        },
        error: "Назва міста має складати 2-30 символів",
        name: "city",
        valid: true,
        touched: false,
      },
      {
        value: street ? street : "",
        rules: {
          required: true,
          minLength: 2,
          maxlength: 30,
        },
        error: "Назва вулиці має складати 2-30 символів",
        name: "street",
        valid: true,
        touched: false,
      },
      {
        value: buildingNumber ? buildingNumber : "",
        rules: {
          required: true,
          minLength: 1,
          maxlength: 6,
        },
        error: "Не вірний номер будинку",
        name: "buildingNumber",
        valid: true,
        touched: false,
      },
      {
        value: postalCode ? postalCode : "",
        rules: {
          required: true,
          minLength: 0,
          maxLength: 15,
        },
        error: "Не вірний почтовий індекс",
        name: "postalCode",
        valid: true,
        touched: false,
      },
      {
        value: phone,
        rules: {
          required: true,
          minLength: 1,
          maxlength: 20,
        },
        error: "Не вірний номер телефону",
        name: "phone",
        valid: true,
        touched: false,
      },
      {
        value: email,
        rules: {
          required: true,
          minLength: 1,
          maxlength: 30,
          isEmail: true,
        },
        error: "Не вірний email",
        name: "email",
        valid: true,
        touched: false,
      },
      {
        value: sendSms,
        rules: {
          required: false,
        },
        name: "sendSms",
        valid: true,
        touched: false,
      },
      {
        value: notificationPhone ? notificationPhone : "",
        rules: {
          minLength: 1,
          maxlength: 20,
        },
        error: "Не вірний номер телефону для нотифікацій",
        name: "notificationPhone",
        valid: true,
        touched: false,
      },
      {
        value: sendEmail,
        rules: {
          required: false,
        },
        name: "sendEmail",
        valid: true,
        touched: false,
      },
      {
        value: notificationEmail ? notificationEmail : "",
        rules: {
          minLength: 1,
          maxlength: 30,
          isEmail: true,
        },
        error: "Не вірний email для нотифікацій",
        name: "notificationEmail",
        valid: true,
        touched: false,
      },
      {
        value: logoUrl,
        rules: {
          required: true,
          maxlength: 1000,
        },
        error:
          "Посилання на логотип магазина має складати від 1 до 1000 символів",
        name: "logoUrl",
        valid: true,
        touched: false,
      },
    ]);
  }, [props.editedMerchant]);

  const onChangeInput = (event, name) => {
    const newForm = [...form];

    const index = newForm.indexOf(newForm.filter((el) => el.name === name)[0]);
    newForm[index] = {
      ...newForm[index],
      value: event.target.value,
      touched: true,
      valid: validation(
        event.target.value,
        newForm[index].rules,
        newForm[index].error,
      ),
    };
    setForm(newForm);
    let i = 0;
    const formWithErrors = JSON.parse(JSON.stringify(newForm)).reverse();
    setFormIsValid(true);
    formWithErrors.forEach((el) => {
      if (el.valid !== true) {
        setFormIsValid(false);
        if (el.touched) {
          setFormError(el.error);
          i++;
        }
      }
    });
    if (i === 0) {
      setFormError(null);
    }
  };

  const onUpdateMerchant = () => {
    setLoading(true);

    const data = {
      name: form[0].value,
      city: form[1].value,
      street: form[2].value,
      buildingNumber: form[3].value,
      postalCode: form[4].value,
      id: props.editedMerchant.id,
      description: props.editedMerchant.description,
      apiUrl: props.editedMerchant.apiUrl,
      latitude: props.editedMerchant.latitude,
      longitude: props.editedMerchant.longitude,
      countryCode: props.editedMerchant.countryCode,
      terminal: props.editedMerchant.terminal,
      merchant: props.editedMerchant.merchant,
      settings: JSON.stringify({
        ...props.editedMerchant.settings,
        open_hours: [...weekDaysFormData, ...exclusionDaysFormData],
        phone: form[5].value,
        email: form[6].value,
        logoUrl: form[11].value,
        notifications: {
          sendSms: form[7].value,
          phone: form[8].value,
          sendEmail: form[9].value,
          email: form[10].value,
        },
      }),
    };

    $api
      .put(`/Merchants/${props.editedMerchant.id}`, data)
      .then((res) => {
        const updatedMerchant = res.data;
        localStorage.setItem(
          "selectedMerchant",
          JSON.stringify({
            ...updatedMerchant,
          }),
        );

        // only for SuperUser
        const merchantsLS = JSON.parse(localStorage.getItem("merchants"));
        if (merchantsLS) {
          const updatedMerchantsLS = merchantsLS.map((merchantsLS) => {
            if (merchantsLS.id !== updatedMerchant.id) {
              return { ...merchantsLS };
            } else {
              return { ...updatedMerchant };
            }
          });

          dispatch(updateMerchants(updatedMerchantsLS));
        }

        props.onSuccess();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data && err.response.data.message) {
          setFormError(err.response.data.message);
        } else {
          props.onError();
        }
      });
  };

  if (!form.length) return <div />;
  return (
    <CommonMerchantForm
      edit
      form={form}
      formError={formError}
      formIsValid={formIsValid}
      onChangeInput={onChangeInput}
      editedMerchant={props.editedMerchant}
      onUpdateMerchant={onUpdateMerchant}
      loading={loading}
      weekDaysFormData={weekDaysFormData}
      setWeekDaysFormData={setWeekDaysFormData}
      exclusionDaysFormData={exclusionDaysFormData}
      setExclusionDaysFormData={setExclusionDaysFormData}
      getAllShops={props.getAllShops}
    />
  );
};

export default EditMerchantContent;
