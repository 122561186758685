import React, { useEffect, useState } from "react";
import "./OpeningHours.scss";
import TimeInput from "./TimeInput";
import { useTranslation } from "react-i18next";

const OpeningHoursUnit = ({
  day,
  setWeekDaysFormData,
  allShopsPanel_isActive,
  setSelectedUnitsIds,
}) => {
  const { t } = useTranslation();
  const [isNotValid, setIsNotValid] = useState(false);
  const [isWorkingDay, setIsWorkingDay] = useState(day.on);
  const [unitIsChecked, setUnitIsChecked] = useState(false);

  useEffect(() => {
    setIsWorkingDay(day.on);
  }, [day]);

  const onInputChange = (value, inputKeyName) => {
    const regexp = `(([0,1][0-9])|(2[0-3])):[0-5][0-9]`;
    const matches_array = value.match(regexp);
    if (!matches_array || value.length > 5) {
      !isNotValid && setIsNotValid(true);
    } else {
      isNotValid && setIsNotValid(false);

      setWeekDaysFormData((prev) => {
        const newWeekDays = [...prev];
        const changedDay = newWeekDays.find(
          (el) => el.dayOfWeek === day.dayOfWeek,
        );
        changedDay[inputKeyName] = value + ":00";

        return newWeekDays;
      });
    }
  };

  const onChangeWorkingDayHandler = (e) => {
    const newState = !isWorkingDay;
    setIsWorkingDay(newState);
    setWeekDaysFormData((prev) => {
      const newWeekDays = [...prev];
      const changedDay = newWeekDays.find(
        (el) => el.dayOfWeek === day.dayOfWeek,
      );
      changedDay.on = newState;
      return newWeekDays;
    });
  };

  const onUnitCheck = (e) => {
    e.preventDefault();
    const unitIsChecked_l = !unitIsChecked;
    setUnitIsChecked(unitIsChecked_l);

    setSelectedUnitsIds((prev) => {
      if (unitIsChecked_l) {
        return [...prev, day.dayOfWeek];
      } else {
        return [...prev].filter((el) => el !== day.dayOfWeek);
      }
    });
  };

  return (
    <div
      onClick={allShopsPanel_isActive ? (e) => onUnitCheck(e) : () => null}
      className={
        !allShopsPanel_isActive
          ? "OpeningHoursUnit"
          : "OpeningHoursUnit OpeningHoursUnit_withActivePanel"
      }
      style={
        allShopsPanel_isActive
          ? {
              cursor: "pointer",
              border: `${
                unitIsChecked ? "1px solid #257df7" : "1px solid #e7e7e7"
              }`,
            }
          : { cursor: "inherit" }
      }
    >
      <div className="OpeningHoursUnit_Left">
        <div className="OpeningHoursUnit_Name">{day.name}</div>
        <div className="OpeningHoursUnit_CheckboxWrap">
          <p style={isWorkingDay ? { opacity: "1" } : { opacity: "0.5" }}>
            {t("merchant.merchantRows.shopInfo.openHours.checkboxText")}
          </p>
          <input
            style={isWorkingDay ? { opacity: "1" } : { opacity: "0.5" }}
            type="checkbox"
            checked={isWorkingDay}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => onChangeWorkingDayHandler(e)}
          />
        </div>
      </div>
      <div className="OpeningHoursUnit_Right">
        <div className="OpeningHoursUnit_TimeWrap">
          <div className="OpeningHoursUnit_Start">
            <TimeInput
              valueInit={isWorkingDay ? day.from : ""}
              id="from"
              onInputChange={onInputChange}
              isNotValid={isNotValid}
              inputStyles={isWorkingDay ? { opacity: "1" } : { opacity: "0.5" }}
              disabled={!isWorkingDay}
            />
          </div>
          <span style={isWorkingDay ? { opacity: "1" } : { opacity: "0.5" }}>
            &nbsp; &ndash; &nbsp;
          </span>
          <div className="OpeningHoursUnit_End">
            <TimeInput
              valueInit={isWorkingDay ? day.to : ""}
              id="to"
              onInputChange={onInputChange}
              isNotValid={isNotValid}
              inputStyles={isWorkingDay ? { opacity: "1" } : { opacity: "0.5" }}
              disabled={!isWorkingDay}
            />
          </div>
        </div>

        {allShopsPanel_isActive ? (
          <div className="AllShopsPanel_CheckboxWrap">
            <input
              onChange={(e) => onUnitCheck(e)}
              id={`checkbox_${day.dayOfWeek}`}
              type="checkbox"
              name="check"
              checked={unitIsChecked}
            />
            <label htmlFor={`checkbox_${day.dayOfWeek}`}></label>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default OpeningHoursUnit;
