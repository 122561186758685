import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// icons
import assort from "../../../../../../assets/images/wrapper/header/assort.svg";
import orders from "../../../../../../assets/images/wrapper/header/orders.svg";

const HeaderBottomLinks = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { isUserAdmin } = props;

  return (
    <div className="header__bottom_links">
      {isUserAdmin && (
        <NavLink
          to="/main/storage"
          className={location.pathname.includes("/storage") ? "active" : "dis"}
        >
          <div>
            <img alt="" src={assort} />
            <p>{t("header.bottom.links.1")}</p>
          </div>
        </NavLink>
      )}
      <NavLink
        to="/main/orders"
        className={location.pathname.includes("/orders") ? "active" : "dis"}
      >
        <div>
          <img alt="" src={orders} />
          <p>{t("header.bottom.links.2")}</p>
        </div>
      </NavLink>
    </div>
  );
};

export default HeaderBottomLinks;
