import React, { useEffect, useMemo, useState } from "react";
import "./styles.scss";
import {
  getPersonalUserSchema,
  getUserInitials_Fields,
} from "../../../../dataUI/pages/personal";
import { useYupValidationResolver } from "../../../../tools/common/hooks";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  getPosterMerchant_Fields,
  getPosterMerchantSchema,
} from "../../../../tools/pages/joinposter";
import { getUserAva } from "../../../../tools/pages/personal/personal";
import GlobalLoader from "../../../../components/UI/GlobalLoader/GlobalLoader";
import RHFInput from "../../../../components/common/form/RHFInput";
import { inputTypes } from "../../../../dataUI/common/commonConsts";
import RHFTextarea from "../../../../components/common/form/RHFTextarea";
import RHFSelect from "../../../../components/common/form/RHFSelect";
import JoinPosterService from "../../../../api/services/JoinPoster";
import RhfPhoto from "../../../../components/common/form/RHFPhoto";
import RHFSeveralSelect from "../../../../components/common/form/RHFSeveralSelect";
import { setPromptInfo } from "../../../../store/actions/alert/alertActions";
import { useDispatch } from "react-redux";

const PosterMerchantForm = ({ merchant }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const merchant_Fields = useMemo(() => getPosterMerchant_Fields(t), [t]);
  const schema = useMemo(() => getPosterMerchantSchema(t), [t]);
  const resolver = useYupValidationResolver(schema);
  const [logoInit, setLogoInit] = useState(
    merchant.logo ? merchant.logo : undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    validateMode: "onChange",
    resolver,
  });

  useEffect(() => {
    console.log("errors = ", errors);
  }, [errors]);

  useEffect(() => {
    /*merchant && merchant.logo && getAvaInit(merchant.id);*/
    // set init form values
    const initValues = {
      name: merchant ? merchant.name : "",
      description: merchant ? merchant.description : "",
      category_ids:
        merchant && merchant.category_ids ? merchant.category_ids : [],
    };

    reset({ ...initValues });
  }, [merchant]);

  const getAvaInit = async (id) => {
    /*const imageFile = await getMerchantAva(id);
    imageFile && setAvaInit(imageFile);*/
  };

  const addFormDataLogo = async (file) => {
    const newName = file.name.replaceAll(" ", "");
    const resp = await JoinPosterService.updateMerchantLogo(file, merchant.id, newName);

    if (resp.status === 200) {
      setLogoInit(`https://adminsamo.com.ua/img/spots/images/${newName}`);
    } else {
      dispatch(
        setPromptInfo({ promptText: `При завантаженні фото виникла помилка.` })
      );
    }
  };

  const onSubmit = async (data) => {
    if (!logoInit) {
      dispatch(
        setPromptInfo({
          promptText: `Дані не були збережені: необхідно додати фото.`,
        })
      );
      return;
    }
    setIsLoading(true);
    const resp = await JoinPosterService.updateMerchant({
      ...data,
      id: merchant.id,
    });
    setIsLoading(false);
  };

  return (
    <div className="PosterMerchantFormWrap">
      {isLoading && <GlobalLoader />}
      <h2> Дані Мерчанту</h2>

      <form
        className="PosterMerchantForm"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        id="PosterMerchantForm"
      >
        <RHFInput
          wrapStyles={{ marginTop: "30px", marginBottom: "10px" }}
          keyName={merchant_Fields.name.keyName}
          description={merchant_Fields.name.description}
          register={register}
          error={errors[merchant_Fields.name.keyName]}
          inputType={merchant_Fields[merchant_Fields.name.keyName].type}
          control={control}
        />
        <RHFTextarea
          wrapStyles={{ marginTop: "30px", marginBottom: "32px" }}
          keyName={merchant_Fields.description.keyName}
          description={merchant_Fields.description.description}
          register={register}
          error={errors[merchant_Fields.description.keyName]}
          inputType={merchant_Fields[merchant_Fields.description.keyName].type}
          control={control}
        />

        <RHFSeveralSelect
          isInactive={false}
          optionsInit={merchant_Fields.category_ids.listData}
          selectedValuesInit={
            merchant && merchant.category_ids ? merchant.category_ids : []
          }
          description={merchant_Fields.category_ids.description}
          id={1}
          control={control}
          keyName={merchant_Fields.category_ids.keyName}
          wrapStyles={{
            width: "100%",
            marginTop: "40px",
            marginBottom: "50px",
          }}
        />

        <RhfPhoto
          previewUrlInit={logoInit}
          id="merchantForm_logo"
          buttonChangingText={t("joinposter.merchantForm.changeLogo")}
          buttonAddingText={t("joinposter.merchantForm.addLogo")}
          descriptionText={t("joinposter.merchantForm.logo")}
          setFormDataFile={addFormDataLogo}
        />

        <div>* усі поля є обовʼязковими до заповнення </div>
        <div className="ButtonConfirmWrap">
          <button
            className="ButtonConfirm"
            type="submit"
            form="PosterMerchantForm"
          >
            <span>
              {t("merchant.merchantRows.personnelAccessRights.buttonSave")}
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default PosterMerchantForm;
