import React, { useEffect, useState } from "react";
import JoinPosterService from "../../api/services/JoinPoster";
import { authUrls } from "../../dataUI/common/commonConsts";
import { useTranslation } from "react-i18next";
import getFakeResponse from "../../tools/forTest/getFakeResponse";
import PosterMerchantForm from "./IframePosterMerchant/PosterMerchantForm/PosterMerchantForm";
import JoinposterConfirm from "./JoinposterConfirm/JoinposterConfirm";
import IframePosterConfirm from "./IframePosterConfirm/IframePosterConfirm";
import IframePosterMerchant from "./IframePosterMerchant/IframePosterMerchant";
import GlobalLoader from "../../components/UI/GlobalLoader/GlobalLoader";

const JoinPosterIframe = () => {
  const { t } = useTranslation();
  const [stores, setStores] = useState(undefined);
  const [merchantInit, setMerchantInit] = useState(undefined);
  const [syncData, setSyncData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const url = window.document.location.href;
    const code = url.includes(authUrls.IFRAME)
      ? url.split(authUrls.IFRAME)[1]
      : undefined;
    code && getMerchantData(code);
  }, []);

  const getMerchantData = async (code) => {
    const resp = await JoinPosterService.getMerchantData(code);
    resp.data.spots && setStores(resp.data.spots);

    const merchantRespInit = {
      id: resp.data.merchant.id,
      name: resp.data.merchant.name,
      description: resp.data.merchant.description,
      logo: resp.data.merchant.logo,
      category_ids: resp.data.merchant.category_ids,
    };

    setMerchantInit({ ...merchantRespInit });
    setSyncData({
      account: resp.data.account_name,
      token: resp.data.access_token,
    });
  };

  const synchronizeMerchant = async () => {
    setIsLoading(true);
    const resp = await JoinPosterService.synchronizeMerchant(
      syncData.token,
      syncData.account,
    );
    const merchantRespInit = {
      id: resp.data.merchant.id,
      name: resp.data.merchant.name,
      description: resp.data.merchant.description,
      logo: resp.data.merchant.logo,
      category_ids: resp.data.merchant.category_ids,
    };
    setMerchantInit(merchantRespInit);
    setIsLoading(false);
    resp.data.spots && setStores(resp.data.spots);
  };

  return !stores || !merchantInit || !syncData || isLoading ? (
    <GlobalLoader />
  ) : stores.length ? (
    <IframePosterMerchant
      stores={stores}
      merchant={merchantInit}
      synchronizeMerchant={synchronizeMerchant}
    />
  ) : (
    <IframePosterConfirm
      syncData={syncData}
      synchronizeMerchant={synchronizeMerchant}
    />
  );
};

export default JoinPosterIframe;
