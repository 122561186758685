import * as yup from "yup";
import { inputTypes, methodTypes } from "../common/commonConsts";
import { array } from "yup";

export const getUserInitials_Fields = (t) => ({
  firstName: {
    keyName: "firstName",
    description: t("userProfile.change_.name"),
    errorMessages: {
      common: t("userProfile.errors.name"),
    },
  },
  lastName: {
    keyName: "lastName",
    description: t("userProfile.change_.surname"),
    errorMessages: {
      common: t("userProfile.errors.surname"),
    },
  },
  middleName: {
    keyName: "middleName",
    description: t("userProfile.change_.lastName"),
    errorMessages: {
      common: t("userProfile.errors.lastName"),
    },
  },
  phone: {
    keyName: "phone",
    description: t("userProfile.change_.phone"),
    errorMessages: {
      common: t("userProfile.errors.phone"),
    },
    type: inputTypes.PHONE_NUMBER,
  },
  title: {
    keyName: "title",
    description: t("userProfile.change_.dolj"),
    errorMessages: {
      common: t("userProfile.errors.dolj"),
    },
  },
});

export const getPersonalUser_Fields = (t) => ({
  ...getUserInitials_Fields(t),
  role: {
    keyName: "role",
  },
  shopIds: {
    keyName: "shopIds",
  },
  login: {
    keyName: "login",
    description: t("userProfile.change_.login"),
    errorMessages: {
      common: t("userProfile.errors.login"),
    },
  },
  password: {
    keyName: "password",
    description: t("userProfile.change_.pass1"),
    errorMessages: {
      common: t("userProfile.errors.password"),
    },
    type: "password",
  },
  confirmedPassword: {
    keyName: "confirmedPassword",
    description: t("userProfile.change_.pass2"),
    errorMessages: {
      common: t("userProfile.errors.password"),
    },
    type: "password",
  },
});

// validation
function checkPassword(confirmedPassword) {
  const password = this.parent.password;

  if (
    (!confirmedPassword && !password) ||
    (confirmedPassword &&
      confirmedPassword.length > 5 &&
      confirmedPassword.length < 51 &&
      confirmedPassword === password)
  ) {
    return true;
  } else {
    return false;
  }
}

export const getPersonalUserSchema = (t, type) => {
  return yup.object().shape({
    firstName: yup
      .string()
      .required(t("userProfile.errors.name"))
      .min(2, t("userProfile.errors.name"))
      .max(30, t("userProfile.errors.name")),
    lastName: yup
      .string()
      .required(t("userProfile.errors.surname"))
      .min(3, t("userProfile.errors.surname"))
      .max(30, t("userProfile.errors.surname")),
    middleName: yup
      .string()
      .required(t("userProfile.errors.lastName"))
      .min(3, t("userProfile.errors.lastName"))
      .max(30, t("userProfile.errors.lastName")),
    phone: yup
      .string()
      .required(t("userProfile.errors.phone"))
      .max(20, t("userProfile.errors.phone")),
    title: yup
      .string()
      .required(t("userProfile.errors.dolj"))
      .min(2, t("userProfile.errors.dolj"))
      .max(50, t("userProfile.errors.dolj")),
    login: yup
      .string()
      //.required(t("userProfile.errors.login"))
      .min(4, t("userProfile.errors.login"))
      .max(25, t("userProfile.errors.login")),
    password:
      type === methodTypes.UPDATE
        ? yup.string()
        : yup.string().required(t("userProfile.errors.password")),
    confirmedPassword: yup
      .string()
      .nullable()
      .test("", t("userProfile.errors.password"), checkPassword),
    shopIds: array(),
  });
};
