import React from "react";

const ProductItem = (props) => {
  const { product, cats } = props;
  const cat = cats
    .flat(Infinity)
    .filter((el) => Number(el.el.id) === Number(product.clst[0]))[0];

  const weight = product.v ? product.v + " мл." : product.w + " гр.";

  return (
    <li className="select-modal" onClick={() => props.onAddModalProd(product)}>
      <div className="pd-name">
        <p>{product.t}</p>
      </div>
      <div className="pd-image">
        <img alt="" src={product.img} />
      </div>
      <div className="pd-cat">
        <p>{cat && cat.el.name}</p>
      </div>
      <div className="pd-price" style={{ width: "calc(13% + 40px)" }}>
        <p>{product.p}</p>
        <p className="small-label">{"за " + weight}</p>
      </div>
      <div className="pd-count">
        <p>
          {product.q}
          {product.bl ? " г." : " шт."}
        </p>
      </div>
    </li>
  );
};

export default ProductItem;
