import React from "react";
import { useTranslation } from "react-i18next";
import Error from "../../../../../../../components/UI/Errors/ServerError/ServerError";
import CustomError from "../../../../../../../components/UI/Errors/CustomError/CustomError";
import Spinner from "../../../../../../../components/UI/Spinner/Spinner";
import Category from "../../../../../../../models/storage/categories/categories";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const CreateCategory = (props) => {
  const { t } = useTranslation();
  const { merchantId, shopId } = props;
  const parentId = props.match.params.id;
  const parentName =
    parentId === "top-level" ? null : props.location.state.name;
  const createLabel = parentName
    ? t("cats.create.title2")
    : t("cats.create.title3");

  const [name, setName] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const onSetName = (event) => {
    const val = event.target.value;
    if (val.length > 50) {
      setName(val.slice(0, 50));
    } else setName(val);
  };

  const onSetUrl = (event) => {
    const val = event.target.value;
    if (val.length > 2000) {
      setUrl("");
    } else {
      setUrl(val);
    }
  };

  const onCreateCategory = async () => {
    setLoading(true);
    setError(false);

    const categoryAPI = new Category(merchantId, shopId);
    const newParentId = parentId === "top-level" ? null : Number(parentId);
    try {
      const result = await categoryAPI.createCat({
        name,
        image: url,
        parentId: newParentId,
      });
      props.history.push({
        pathname: newParentId
          ? `/main/storage/categories/${newParentId}`
          : "/main/storage/categories/",
        state: {
          successCreated: true,
          name: result.data.name,
        },
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        setError(e.response.data.message);
      } else {
        setError(true);
      }
    }
    setLoading(false);
  };

  return (
    <div className="create-cat-container" style={{ paddingLeft: "60px" }}>
      {error === true && <Error />}
      {typeof error === "string" && <CustomError title={error} />}
      {loading && (
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
      <IconButton
        style={{ position: "absolute", top: "10px", left: "60px" }}
        onClick={() => props.history.push("/main/storage/categories")}
      >
        <ArrowBackIcon />
      </IconButton>
      {!error && !loading && (
        <>
          <h2 style={{ alignSelf: "center" }}>
            {createLabel}{" "}
            <span style={{ marginLeft: "5px" }}>{parentName}</span>
          </h2>
          <div className="content">
            <div className="content_area">
              <label htmlFor="name">{t("cats.create.name")}</label>
              <input value={name} onChange={onSetName} type="text" id="name" />
            </div>
            <div className="content_area">
              <label htmlFor="name">{t("cats.create.url")}</label>
              <input value={url} onChange={onSetUrl} type="text" id="name" />
            </div>
            <div className="example">
              <p>{t("cats.create.photo")}</p>
              <img alt="" src={url} />
            </div>
            <button onClick={onCreateCategory}>
              {t("cats.create.button")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateCategory;
