import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Modal from "../../../../../UI/Modal/Modal";

import ProductsAPI from "../../../../../../models/storage/products/products";

import upload from "../../../../../../assets/images/wrapper/header/upload.svg";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";

const ImportProcessContent = (props) => {
  const { loading, error, message } = props;
  const { t } = useTranslation();

  return (
    <div className="import-modal-content">
      {loading && <p className="loading">{t("import.loading")}</p>}
      {error && <p className="error">{t("import.error")}</p>}
      {!loading && !error && message && (
        <>
          <div className="close-button">
            <IconButton onClick={props.onClose}>
              <ClearIcon />
            </IconButton>
          </div>
          <h5 className="message-title">{t("import.success")}</h5>
          <p className="message">{message}</p>
        </>
      )}
    </div>
  );
};

const ImportArea = (props) => {
  const { t } = useTranslation();
  const info = JSON.parse(localStorage.getItem("info"));
  const merchantId = info && info.merchantId;

  // import
  const [importMode, setImportMode] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [importError, setImportError] = useState(false);
  const [importSuccessMessage, setImportSuccessMessage] = useState(null);

  const onSelectFileHandler = (event) => {
    const reader = new FileReader();
    setImportMode(true);
    reader.onloadend = async () => {
      setImportLoading(true);
      setImportError(false);
      const products = new ProductsAPI(
        props.selectedMerchant ? props.selectedMerchant.id : merchantId,
        props.selectedShop.id,
      );

      try {
        const result = await products.onImportProducts(file);

        setImportSuccessMessage(result.data);
      } catch (e) {
        console.log("e = ", e);
        setImportError(true);
      }
      setImportLoading(false);
    };
    const file = event.target.files[0];
    reader.readAsDataURL(file);
  };

  return (
    <>
      <Modal
        show={importMode}
        onClose={() => {
          setImportMode(false);
          setImportSuccessMessage(null);
        }}
      >
        <ImportProcessContent
          loading={importLoading}
          error={importError}
          message={importSuccessMessage}
          onClose={() => {
            setImportMode(false);
            setImportSuccessMessage(null);
          }}
        />
      </Modal>

      <label className="import-button" htmlFor="idishnik">
        <img src={upload} alt="" />
        <p>{t("import.title")}</p>
      </label>
      <input
        id="idishnik"
        value=""
        onChange={(event) => onSelectFileHandler(event)}
        type="file"
        accept="text/xml"
        style={{ display: "none" }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedMerchant: state.admin.selectedMerchant,
  selectedShop: state.admin.selectedShop,
});

export default connect(mapStateToProps)(ImportArea);
