import React, { useEffect, useState } from "react";
import DateChecker from "../../../../../components/common/Calendar/DateChecker";
import moment from "moment-timezone";
import TimeInput from "../OpeningHours/TimeInput";
import AddingNewFormRadio from "./AddingNew_FormRadio";
import { useTranslation } from "react-i18next";

const FormRadioTypes = {
  ON: "on",
  OFF: "off",
};

const ExclusionDaysAddingNew = ({ onAddingNewDate }) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(moment().toDate());
  const [time, setTime] = useState({
    from: { time: "00:00:00", isNotValid: false },
    to: { time: "00:00:00", isNotValid: false },
  });
  const [radioValue, setRadioValue] = useState(true);

  const onTimeChange = (value, inputKeyName) => {
    const regexp = `(([0,1][0-9])|(2[0-3])):[0-5][0-9]`;
    const matches_array = value.match(regexp);
    if (!matches_array || value.length > 5) {
      setTime((prev) => {
        const newTime = { ...prev };
        newTime[inputKeyName].isNotValid = true;
        return newTime;
      });
    } else {
      setTime((prev) => {
        const newTime = { ...prev };
        newTime[inputKeyName].isNotValid = false;
        newTime[inputKeyName].time = value + ":00";
        return newTime;
      });
    }
  };

  const onChangeDateHandler = (date) => {
    setDate(moment(date).format("YYYY-MM-DD"));
  };

  const onChangeRadioHandler = (value) => {
    setRadioValue(value === FormRadioTypes.ON);
  };

  const onAddingNewDateHandler = () => {
    const addingDay = {
      on: radioValue,
      from: time.from.time,
      to: time.to.time,
      date: date,
    };
    if (!time.from.isNotValid && !time.from.isNotValid) {
      onAddingNewDate(addingDay);
    } else {
      console.log("validation-time-error");
    }
  };

  return (
    <div className="ExclusionDays_AddingNew">
      <div className="ExclusionDays_AddingNewFirst">
        <DateChecker
          changeField={onChangeDateHandler}
          id={3}
          parName="date"
          minDate={moment().toDate()}
          maxDate={moment().add(10, "year").toDate()}
          wrapStyles={{ width: "160px" }}
          description={t(
            "merchant.merchantRows.shopInfo.exclusionDays.dateChecker_description",
          )}
        />
      </div>
      <div className="ExclusionDays_AddingNewTimeBox">
        <TimeInput
          valueInit={"00:00:00"}
          id="from"
          onInputChange={onTimeChange}
          isNotValid={time.from.isNotValid}
          inputStyles={{
            width: "66px",
            height: "40px",
            border: "1px solid #e2e4e5",
          }}
        />
        <span>&nbsp; &ndash; &nbsp;</span>
        <TimeInput
          valueInit={"00:00:00"}
          id="to"
          onInputChange={onTimeChange}
          isNotValid={time.to.isNotValid}
          inputStyles={{
            width: "66px",
            height: "40px",
            border: "1px solid #e2e4e5",
          }}
        />
      </div>
      <AddingNewFormRadio
        formRadioTypes={[FormRadioTypes.ON, FormRadioTypes.OFF]}
        onChangeRadio={onChangeRadioHandler}
      />
      <button
        className="AddingNewTime_AddingButton"
        onClick={onAddingNewDateHandler}
      >
        {t(
          "merchant.merchantRows.shopInfo.exclusionDays.button_AddingNewDate2",
        )}
      </button>
    </div>
  );
};

export default ExclusionDaysAddingNew;
