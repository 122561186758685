// roles
export const getPersonalRoles = (t) => [
  {
    id: 2,
    name: t("common.roles.cashier"),
  },
  {
    id: 3,
    name: t("common.roles.operator"),
  },
  {
    id: 1,
    name: t("common.roles.admin"),
  },
  // supervisor id = -1
];
