import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";

const RHFTextarea = ({
  register,
  keyName,
  error,
  description,
  inputType,
  wrapStyles,
  isPhoneNumber = false,
  control,
}) => {
  const [inputIsValid, setInputIsValid] = useState(true);

  useEffect(() => {
    setInputIsValid(error ? !error.message : true);
  }, [error]);

  return (
    <div style={wrapStyles ? wrapStyles : {}} className="RHFTextarea">
      <div className="TextareaWrap">
        <div
          className="TextareaDescription"
          /*style={
            inputIsValid
              ? { color: `rgb(112, 120, 127)` }
              : { color: `rgba(255, 0, 0, 0.7)` }
          }*/
        >
          {description}
        </div>

        <textarea
          {...register(keyName)}
          className="Textarea"
          style={
            !inputIsValid
              ? { border: `1px solid rgba(221, 60, 60, 0.5)` }
              : { border: `1px solid rgb(118, 118, 118)` }
          }
        />
      </div>

      <div style={{ height: "10px", paddingTop: "2px" }}>
        {error ? (
          <div className="TextareaError">{String(error.message)}</div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default RHFTextarea;
