import React, { useEffect } from "react";
import "./styles.scss";
import PosterStoreForm from "./PosterStoreForm/PosterStoreForm";

const PosterStores = ({ stores }) => {
  return (
    <div className="PosterStores">
      <h2>Торгові точки</h2>
      {stores &&
        stores.map((store, i) => (
          <PosterStoreForm
            key={`${store.id}${i}`}
            index={`${store.id}${i}`}
            store={store}
          />
        ))}
    </div>
  );
};
export default PosterStores;
