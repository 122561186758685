import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

// icons
import Scope from "../../../../../../assets/images/wrapper/header/search.svg";
// mui
import CancelIcon from "@material-ui/icons/Cancel";
//redux
import * as actions from "../../../../../../store/actions/index";

const SearchArea = ({ reduxSearchNum, setReduxSearchNum, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [currentInputSearchNum, setCurrentInputSearchNum] = useState("");

  return (
    <>
      {!reduxSearchNum ? (
        <div className="header__top__searcharea">
          <img
            src={Scope}
            alt="search"
            onClick={() => {
              setReduxSearchNum(+currentInputSearchNum);
              setCurrentInputSearchNum("");
              history.push("/main/orders");
            }}
          />
          <input
            placeholder={t("header.order")}
            value={currentInputSearchNum}
            type="number"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setReduxSearchNum(e.target.value);
                setCurrentInputSearchNum("");
                history.push("/main/orders");
              }
            }}
            onChange={(e) => setCurrentInputSearchNum(e.target.value)}
          />
        </div>
      ) : (
        <div
          className="header__top__searcharea"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <p>
            {"Заказ " +
              (reduxSearchNum.toString().length > 3
                ? "..." + reduxSearchNum.toString().slice(-3)
                : reduxSearchNum)}
          </p>
          <CancelIcon
            onClick={() => setReduxSearchNum(null)}
            style={{ marginLeft: "auto", cursor: "pointer" }}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  reduxSearchNum: state.mode.searchNum,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxSearchNum: (int) => dispatch(actions.setSearchOrderNumber(int)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchArea);
