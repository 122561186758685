import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useHistory } from "react-router-dom";

import NotificationsModel from "../../../../../../models/notifications/notifications";

// icons
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as BorderedBell } from "../../../../../../assets/images/components/Notifications/bell-bordered.svg";
import { ReactComponent as BlueFillBell } from "../../../../../../assets/images/components/Notifications/blue-fill-bell.svg";
import { ReactComponent as WhiteBell } from "../../../../../../assets/images/components/Notifications/bell-white.svg";

import "./bell.scss";

const Bell = () => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [currentUnHandeledMessages, setCurrentUnHandeledMessages] = useState(0);
  const [newMessageModalOpened, setNewMessageModalOpened] = useState(false);

  const weAreInNotPage = location.pathname.includes("/notifications");

  useEffect(() => {
    if (!weAreInNotPage) getUnhandledMessages();

    const intervalRef = setInterval(() => {
      if (!weAreInNotPage) return getUnhandledMessages();
    }, 180000);

    return () => clearInterval(intervalRef);
  }, []);

  const bellIndicatorCN = `bell-indicator ${
    currentUnHandeledMessages > 9 ? "red" : "green"
  }`;
  const newMessageModalCN = `new-message-modal-window ${
    newMessageModalOpened ? "opened" : "closed"
  }`;

  return (
    <>
      <div className={newMessageModalCN}>
        <div className="message-title">
          <WhiteBell />
          <span>{t("У вас нові повідомлення")}</span>
        </div>
        <div className="buttons">
          <button
            className="look"
            onClick={() => {
              setNewMessageModalOpened(false);
              history.push("/notifications/");
            }}
          >
            {t("Переглянути")}
          </button>
          <button
            className="close"
            onClick={() => setNewMessageModalOpened(false)}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
      <NavLink className="bell" to={"/notifications/"}>
        {weAreInNotPage ? (
          <BlueFillBell className="bell-icon-active" />
        ) : (
          <BorderedBell />
        )}
        {currentUnHandeledMessages > 0 && (
          <div className={bellIndicatorCN}>
            <span>
              {currentUnHandeledMessages > 9 ? "9+" : currentUnHandeledMessages}
            </span>
          </div>
        )}
      </NavLink>
    </>
  );

  async function getUnhandledMessages() {
    const Notification = new NotificationsModel();

    const result = await Notification.getUnhandledMessages();

    const newUnhandledMessages = result.data.notProcessed;

    setCurrentUnHandeledMessages(newUnhandledMessages);

    if (newUnhandledMessages > 0) {
      // activate modal
      setNewMessageModalOpened(true);
      setTimeout(() => setNewMessageModalOpened(false), 8000);
    }
  }
};

export default Bell;
