import React from "react";
import { NavLink } from "react-router-dom";
import trash from "../../../../../../../assets/images/pages/Products/trash.svg";
import pencil from "../../../../../../../assets/images/pages/Products/pencil.svg";

const CategoryItem = (props) => {
  const { id, name, image, child } = props;

  return (
    <li>
      <div className="cat-name">
        <NavLink to={`/main/storage/categories/${id}`}>{name + "🔗"}</NavLink>
      </div>
      <div className="cat-image">
        <img src={image} alt="" />
      </div>
      <div className="cat-list">
        <ul>
          {child &&
            child.map((el) => (
              <NavLink to={`/main/storage/categories/${el.id}`} key={el.name}>
                {el.name + "🔗"}
              </NavLink>
            ))}
        </ul>
      </div>
      <div className="cat-tools">
        <img
          onClick={() => props.onEdit(id)}
          style={{ marginBottom: "10px", cursor: "pointer" }}
          src={pencil}
          alt=""
        />
        <img
          onClick={() => props.onDelete({ id, name })}
          style={{ cursor: "pointer" }}
          src={trash}
          alt=""
        />
      </div>
    </li>
  );
};

export default CategoryItem;
