import React, { useEffect } from "react";
import { refreshAuthToken } from "../../store/actions";
import { useDispatch } from "react-redux";
import ScrollToTop from "../../tools/scrollToTop";
import { Redirect, Route, Switch } from "react-router-dom";
import Main from "../../pages/Main/Main";
import Storage from "../../pages/Storage/Storage";
import AdminWindow from "../../pages/Administration/Administration";
import Profile from "../../pages/Profile/Profile";
import Notifications from "../../pages/Notifications/Notifications";

const AuthorizedRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshAuthToken());

    const interval = window.setInterval(
      () => {
        dispatch(refreshAuthToken());
      },
      60 * 1000 * 14,
    ); // 14 minutes

    return () => {
      window.clearInterval(interval);
    };
  }, []);

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Redirect exact from="/main" to="/main/orders" />
        <Route
          exact
          path="/main/orders"
          render={(props) => <Main {...props} />}
        />
        <Route
          path="/main/storage"
          render={(props) => <Storage {...props} />}
        />
        <Route
          exact
          path="/admin/"
          render={(props) => <AdminWindow {...props} />}
        />
        <Route path="/profile/" render={(props) => <Profile {...props} />} />
        <Route
          path="/notifications/"
          render={(props) => <Notifications {...props} />}
        />
        <Redirect from="/auth" to="/main" />
        <Redirect from="/" to="/main" />
      </Switch>
    </>
  );
};

export default AuthorizedRoutes;
