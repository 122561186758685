import React from "react";

import NotificationsHeader from "./NotificationsHeader/NotificationsHeader";
import NotificationsBody from "./NotificationsBody/NotificationsBody";

const NotificationsBlock = (props) => {
  const {
    notificationFilter,
    records,
    messagesLimit,
    isLimit,
    selectedRecords,
    addSelectedRecord,
    removeSelectedRecord,
    selectAllRecords,
    removeAllSelectedRecords,
    onAddOneMorePartNotifications,
    transferSigleRecordToArchive,
    transferStackRecordsToArchive,
    transferSigleRecordToDelete,
    transferStackRecordsToDelete,
    onRedirectToSingleOrder,
    onRedirectToSingleProduct,
    loading,
  } = props;

  return (
    <div className="notifications-block">
      <NotificationsHeader
        notificationFilter={notificationFilter}
        selectedRecords={selectedRecords}
        selectAllRecords={selectAllRecords}
        removeAllSelectedRecords={removeAllSelectedRecords}
        transferStackRecordsToArchive={transferStackRecordsToArchive}
        transferStackRecordsToDelete={transferStackRecordsToDelete}
        loading={loading}
      />
      <NotificationsBody
        records={records}
        messagesLimit={messagesLimit}
        isLimit={isLimit}
        notificationFilter={notificationFilter}
        selectedRecords={selectedRecords}
        addSelectedRecord={addSelectedRecord}
        removeSelectedRecord={removeSelectedRecord}
        selectAllRecords={selectAllRecords}
        removeAllSelectedRecords={removeAllSelectedRecords}
        onAddOneMorePartNotifications={onAddOneMorePartNotifications}
        transferSigleRecordToArchive={transferSigleRecordToArchive}
        transferSigleRecordToDelete={transferSigleRecordToDelete}
        onRedirectToSingleOrder={onRedirectToSingleOrder}
        onRedirectToSingleProduct={onRedirectToSingleProduct}
        loading={loading}
      />
    </div>
  );
};

export default NotificationsBlock;
