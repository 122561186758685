import React, { useEffect, useRef, useState } from "react";
import select_arrow from "../../../assets/images/components/common/select_arrow.svg";
import closeMark from "../../../assets/images/components/common/closeMark.svg";
import "./styles.scss";
import { closePopupByParentID } from "../../../tools/common/additionalFuncs";
import { Controller } from "react-hook-form";

const RHFSeveralSelect = React.memo(
  ({
    optionsInit,
    selectedValuesInit,
    description,
    id,
    control,
    keyName,
    wrapStyles,
    isInactive = false,
  }) => {
    const editPageRef = useRef(null);
    const selectedOptionRef = useRef(null);

    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedOptionValues, setSelectedOptionValues] =
      useState(selectedValuesInit);

    useEffect(() => {
      if (editPageRef.current && selectedOptionRef.current) {
        editPageRef.current.style.zIndex = isExpanded ? "3" : "2";
      }
    }, [isExpanded]);

    // closing popup
    useEffect(() => {
      const listener = closePopupByParentID(`Select_${id}`, setIsExpanded);
      return () => window.removeEventListener("click", listener);
    }, []);

    // styles
    const lastOptionStyles = {
      borderBottom: `1px solid rgb(118, 118, 118)`,
      borderRadius: `0 0 12px 12px`,
      paddingBottom: "12px",
    };

    const wrapInactiveStyles = {
      backgroundColor: "rgb(245,245,245,1)",
      cursor: "auto",
    };

    // handlers
    const onClickSelectHandler = () => {
      setIsExpanded((prev) => !prev);
    };
    const onClickOptionHandler = (newOption, field) => {
      if (!selectedOptionValues.includes(newOption.value)) {
        const newValues = [...selectedOptionValues, newOption.value];
        setSelectedOptionValues(newValues);
        field.onChange(newValues);
      }
      setIsExpanded((prev) => !prev);
    };

    const onSelectedOptionRemove = (removedOption, field) => {
      if (selectedOptionValues.includes(removedOption.value)) {
        const newValues = selectedOptionValues.filter(
          (value) => value !== removedOption.value,
        );
        setSelectedOptionValues(newValues);
        field.onChange(newValues);
      }
    };

    return (
      <Controller
        control={control}
        name={keyName}
        render={({ field }) => (
          <div
            ref={editPageRef}
            className="RHFSeveralSelect"
            id={`Select_${id}`}
            style={wrapStyles ? wrapStyles : {}}
          >
            <div
              style={isInactive ? wrapInactiveStyles : {}}
              className="SeveralSelectWrap"
            >
              {description && (
                <div className="SeveralSelectDescription">{description}</div>
              )}

              <div ref={selectedOptionRef} className="SeveralSelectLeft">
                {selectedOptionValues &&
                  optionsInit
                    .filter((option) =>
                      selectedOptionValues.includes(option.value),
                    )
                    .map((selectedOption, index) => (
                      <div className="SelectedOption" key={index}>
                        <div className="SelectedOptionText">
                          {selectedOption.text}
                        </div>
                        <div
                          className="SelectedOptionCloseMarkWrap"
                          onClick={() =>
                            onSelectedOptionRemove(selectedOption, field)
                          }
                        >
                          <img
                            className="SelectedOptionCloseMark"
                            src={closeMark}
                            alt="видалити"
                          />
                        </div>
                      </div>
                    ))}
              </div>
              <div
                className="SeveralSelectRight"
                onClick={!isInactive ? onClickSelectHandler : () => null}
              >
                {!isInactive && <img src={select_arrow} alt="вибрати" />}
              </div>
            </div>
            {isExpanded &&
              optionsInit &&
              optionsInit
                .filter(
                  (option) => !selectedOptionValues.includes(option.value),
                )
                .map((option, index) => {
                  return (
                    <div
                      className="SeveralSelectOption"
                      key={index}
                      style={
                        index + 1 <
                        optionsInit.length - selectedOptionValues.length
                          ? {}
                          : lastOptionStyles
                      }
                      onClick={() => onClickOptionHandler(option, field)}
                    >
                      {option.text}
                    </div>
                  );
                })}
          </div>
        )}
      />
    );
  },
);

export default RHFSeveralSelect;
