import React from "react";
import "./ExclusionDays.scss";
import moment from "moment-timezone";
import close from "../../../../../assets/images/components/UI/AuthModal/close.svg";

const ExclusionDay = ({ date, isLastChild, id, onRemove }) => {
  const onRemoveHandler = () => {
    onRemove(id);
  };

  return (
    <div className="ExclusionDayWrap">
      <div className="ExclusionDay">
        <div className="ExclusionDay_DateBox">
          {moment(date.date).format("DD.MM.YYYY")}
        </div>
        <div className="ExclusionDay_TimeBox">
          {date.from.slice(0, date.from.length - 3)} -{" "}
          {date.to.slice(0, date.to.length - 3)}
        </div>
        <div className="ExclusionDay_Close" onClick={onRemoveHandler}>
          <img src={close} alt="close" />
        </div>
      </div>
      {!isLastChild && <span className="ExclusionDayWrap_Comma">,</span>}
    </div>
  );
};

export default ExclusionDay;
