import React from "react";

export const ModalWithTitle = ({ title }) => (
  <div
    style={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <p>{title}</p>
  </div>
);

export const ModalWithButton = (props) => (
  <div
    style={{
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <p>{props.title}</p>
    <div>
      <button
        onClick={() => props.onClose()}
        style={{
          marginTop: "20px",
          marginRight: "10px",
          padding: "9px 20px",
          cursor: "pointer",
          border: "1px solid #9978E2",
          color: "#9978E2",
          backgroundColor: "transparent",
        }}
      >
        {props.cancelTitle}
      </button>
      <button
        onClick={() => props.handler(props.id)}
        style={{
          marginTop: "20px",
          padding: "10px 20px",
          cursor: "pointer",
          border: "none",
          color: "#fff",
          backgroundColor: "#9978E2",
        }}
      >
        {props.buttonTitle}
      </button>
    </div>
  </div>
);
