import React, { useEffect, useState } from "react";
import "./CommonMerchantForm.scss";
import { useTranslation } from "react-i18next";
import { validation } from "../../../tools/validation";
import $api from "../../../api/http";
import Spinner from "../../UI/Spinner/Spinner";
import NumberFormat from "react-number-format";
import OpeningHours from "../../../pages/Administration/modals/fields/OpeningHours/OpeningHours";
import { getWorkTimeInit } from "../../../tools/pages/administration";
import ExclusionDays from "../../../pages/Administration/modals/fields/ExclusionDays/ExclusionDays";

const CommonMerchantForm = ({
  edit,
  form,
  formError,
  formIsValid,
  onChangeInput,
  editedMerchant,
  onUpdateMerchant,
  onCreateMerchant,
  weekDaysFormData,
  loading,
  setWeekDaysFormData,
  setExclusionDaysFormData,
  getAllShops,
}) => {
  const { t } = useTranslation();

  return (
    <div className="CommonMerchantForm">
      <div
        className="CommonMerchantForm_containerArea"
        style={{ marginTop: "12px" }}
      >
        <label
          htmlFor="name"
          className={formError === form[0].error ? "error" : null}
        >
          {t("merchant.merchantRows.merchantInfo.name")}
        </label>
        <input
          style={{ marginTop: "4px" }}
          value={form[0].value}
          onChange={(e) => onChangeInput(e, "name")}
          type="text"
          id="name"
          className={formError === form[0].error ? "error" : null}
        />
      </div>

      <OpeningHours
        isNotModalWindow
        weekDaysInit={
          editedMerchant && editedMerchant.weekDays
            ? editedMerchant.weekDays
            : getWorkTimeInit(t)
        }
        setWeekDaysFormData={setWeekDaysFormData}
        weekDaysFormData={weekDaysFormData}
        getAllShops={getAllShops}
        isMerchant
      />

      <ExclusionDays
        exclusionDaysInit={
          editedMerchant && editedMerchant.exclusionDays
            ? editedMerchant.exclusionDays
            : []
        }
        setExclusionDaysFormData={setExclusionDaysFormData}
        getAllShops={getAllShops}
        isMerchant
      />

      <div className="CommonMerchantFormBox">
        <h4>{t("merchant.merchantRows.merchantInfo.titleNames.address")}</h4>
        <div className="CommonMerchantForm_area_small">
          <label
            htmlFor="city"
            className={formError === form[1].error ? "error" : null}
          >
            {t("merchant.merchantRows.merchantInfo.fieldNames.city")}
          </label>
          <input
            value={form[1].value}
            onChange={(e) => onChangeInput(e, "city")}
            type="text"
            id="city"
            className={formError === form[1].error ? "error" : null}
          />
        </div>
        <div className="CommonMerchantForm_area_small">
          <label
            htmlFor="street"
            className={formError === form[2].error ? "error" : null}
          >
            {t("merchant.merchantRows.merchantInfo.fieldNames.street")}
          </label>
          <input
            value={form[2].value}
            onChange={(e) => onChangeInput(e, "street")}
            type="text"
            id="street"
            className={formError === form[2].error ? "error" : null}
          />
        </div>
        <div className="CommonMerchantForm_area_small">
          <label
            htmlFor="buildingNumber"
            className={formError === form[3].error ? "error" : null}
          >
            {t("merchant.merchantRows.merchantInfo.fieldNames.buildingNumber")}
          </label>
          <input
            value={form[3].value}
            onChange={(e) => onChangeInput(e, "buildingNumber")}
            type="text"
            id="buildingNumber"
            className={formError === form[3].error ? "error" : null}
          />
        </div>
        <div className="CommonMerchantForm_area_small">
          <label
            htmlFor="postalCode"
            className={formError === form[4].error ? "error" : null}
          >
            {t("merchant.merchantRows.merchantInfo.fieldNames.postalCode")}
          </label>
          <input
            value={form[4].value}
            onChange={(e) => onChangeInput(e, "postalCode")}
            type="number"
            id="postalCode"
            className={formError === form[4].error ? "error" : null}
          />
        </div>
        <div className="CommonMerchantFormBox">
          <h4>{t("merchant.merchantRows.merchantInfo.titleNames.contacts")}</h4>
          <div className="CommonMerchantForm_area_small">
            <label
              htmlFor="phone"
              className={formError === form[5].error ? "error" : null}
            >
              {"Номер телефона:"}
            </label>
            <NumberFormat
              format="+38(0##) ### ## ##"
              mask="_"
              type="text"
              id="phone"
              allowEmptyFormatting
              value={form[5].value}
              onChange={(e) => onChangeInput(e, "phone")}
              className={formError === form[5].error ? "error" : null}
            />
          </div>
          <div className="CommonMerchantForm_area_small">
            <label
              htmlFor="email"
              className={formError === form[6].error ? "error" : null}
            >
              {"email:"}
            </label>
            <input
              value={form[6].value}
              onChange={(e) => onChangeInput(e, "email")}
              type="text"
              id="email"
              className={formError === form[6].error ? "error" : null}
            />
          </div>
        </div>

        <div className="CommonMerchantFormBox">
          <h4>
            {t("merchant.merchantRows.merchantInfo.titleNames.notifications")}
          </h4>
          <div className="CommonMerchantForm_area_small">
            <div className="CommonMerchantForm_area_small">
              <div className="checkbox">
                <label htmlFor="sendSms">
                  {t("merchant.merchantRows.merchantInfo.fieldNames.sendSms")}
                </label>
                <input
                  style={form[7].value ? { opacity: "1" } : { opacity: "0.5" }}
                  type="checkbox"
                  id="sendSms"
                  checked={form[7].value}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    onChangeInput(
                      { ...e, target: { ...e.target, value: !form[7].value } },
                      "sendSms",
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div
            className="CommonMerchantForm_area_small"
            style={{ marginBottom: "24px" }}
          >
            <label
              htmlFor="notificationPhone"
              className={formError === form[8].error ? "error" : null}
            >
              {t(
                "merchant.merchantRows.merchantInfo.fieldNames.notificationPhone",
              )}
            </label>
            <NumberFormat
              disabled={!form[7].value}
              format="+38(0##) ### ## ##"
              mask="_"
              type="text"
              id="notificationPhone"
              allowEmptyFormatting
              value={form[8].value}
              onChange={(e) => onChangeInput(e, "notificationPhone")}
              className={formError === form[8].error ? "error" : null}
            />
          </div>
          <div className="CommonMerchantForm_area_small">
            <div className="checkbox">
              <label htmlFor="sendEmail">
                {t("merchant.merchantRows.merchantInfo.fieldNames.sendEmail")}
              </label>
              <input
                type="checkbox"
                style={form[9].value ? { opacity: "1" } : { opacity: "0.5" }}
                checked={form[9].value}
                id="sendEmail"
                /*onClick={(e) => e.stopPropagation()}*/
                onChange={(e) =>
                  onChangeInput(
                    { ...e, target: { ...e.target, value: !form[9].value } },
                    "sendEmail",
                  )
                }
              />
            </div>
          </div>
          <div
            className="CommonMerchantForm_area_small"
            style={{ marginBottom: "20px" }}
          >
            <label
              htmlFor="notificationEmail"
              className={formError === form[10].error ? "error" : null}
            >
              {t(
                "merchant.merchantRows.merchantInfo.fieldNames.notificationEmail",
              )}
            </label>
            <input
              disabled={!form[9].value}
              value={form[10].value}
              onChange={(e) => onChangeInput(e, "notificationEmail")}
              type="text"
              id="notificationEmail"
              className={formError === form[10].error ? "error" : null}
            />
          </div>
        </div>

        <div className="CommonMerchantFormBox">
          <h4>{t("merchant.merchantRows.merchantInfo.titleNames.links")}</h4>
          <div className="CommonMerchantForm_area_small">
            <label
              htmlFor="logoUrl"
              className={formError === form[11].error ? "error" : null}
            >
              {t("merchant.merchantRows.merchantInfo.fieldNames.logoLink")}
            </label>
            <input
              value={form[11].value}
              onChange={(e) => onChangeInput(e, "logoUrl")}
              type="text"
              id="logoUrl"
              className={formError === form[11].error ? "error" : null}
            />
          </div>
        </div>
        <div className="CommonMerchantForm_area">
          <div style={{ width: "100%" }}>
            <h4>{t("merchant.merchantRows.merchantInfo.titleNames.logo")}</h4>
            <div
              style={{
                padding: "10px 0 10px 0",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img alt="" src={form[10].value} style={{ width: "140px" }} />
            </div>
          </div>
        </div>
      </div>

      {edit && (
        <div className="CommonMerchantForm_footer">
          {!formIsValid && formError && (
            <p
              style={{
                color: "#c73f3f",
                fontSize: "12px",
                alignSelf: "center",
              }}
            >
              {formError}
            </p>
          )}
          {!loading && (
            <div>
              <button
                style={{ padding: "5px 24px" }}
                className={!formIsValid ? "disabled" : "blue"}
                onClick={formIsValid ? onUpdateMerchant : null}
              >
                {t(
                  "merchant.merchantRows.merchantInfo.buttonNames.saveChanges",
                )}
              </button>
            </div>
          )}
          {loading && (
            <div style={{ height: "50px", marginTop: "20px" }}>
              <Spinner />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CommonMerchantForm;
