import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PageStorage from "./PageStorage/PageStorage";
import Spinner from "../../components/UI/Spinner/Spinner";
import Error from "../../components/UI/Errors/ServerError/ServerError";
import CancelIcon from "@material-ui/icons/Cancel";
import * as actions from "../../store/actions/index";

// assets
import shopImg from "../../assets/images/components/ShopsList/shop.svg";
import bigShopImg from "../../assets/images/pages/Storage/big_shop_grey_icon.svg";

import "./storage.scss";
import $api from "../../api/http";

const Storage = (props) => {
  const { selectedShop, selectedMerchant, merchants } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const info = JSON.parse(localStorage.getItem("info"));
  const roleId = info && info.roleId;
  const merchantId = info && info.merchantId;

  const userIsSuperUser = roleId === -1;
  const userIsAdmin = roleId === 1;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [shops, setShops] = useState(null);

  useEffect(() => {
    if (userIsSuperUser && !merchants) {
      setError(false);
      setLoading(true);

      $api
        .get("/Merchants")
        .then((res) => {
          props.setMerchants(res.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    }
    if (userIsAdmin) {
      setLoading(true);
      setError(false);

      $api
        .get(`/Merchants/${merchantId}/shops`)
        .then((res) => {
          setShops(res.data.results);
          if (location.state && location.state.selectedRecord) {
            props.setSelShop(
              res.data.results.filter(
                (el) => +el.id === +location.state.selectedRecord.shopId,
              )[0],
            );
          }
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (selectedMerchant && selectedMerchant.id && roleId === -1) {
      setLoading(true);
      setError(false);

      $api
        .get(`/Merchants/${selectedMerchant.id}/shops`)
        .then((res) => setShops(res.data.results))
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    }
  }, [selectedMerchant]);

  if (loading) {
    return (
      <div className="storage-page-loading-container">
        <Spinner />
      </div>
    );
  }

  if (error) return <Error />;

  return (
    <div className="storage-page-container">
      <div className="selecting-market-point-header">
        {userIsSuperUser && (
          <div className="main-page-selected-merch">
            <label>
              <img className="shop_img" src={shopImg} alt="" />
              {selectedMerchant
                ? t("merchant.choosedMerchant")
                : t("merchant.chooseMerchant")}
            </label>
            {selectedMerchant && (
              <div>
                <p>
                  {selectedMerchant.name.length > 30
                    ? selectedMerchant.name.slice(0, 30) + "..."
                    : selectedMerchant.name}
                </p>
                <CancelIcon
                  onClick={() => props.setSelMerchant(null)}
                  className="cancel-icon"
                />
              </div>
            )}
            {!selectedMerchant && (
              <select
                value={selectedMerchant ? selectedMerchant : ""}
                onChange={(e) =>
                  props.setSelMerchant(JSON.parse(e.target.value))
                }
              >
                <option value={""} disabled hidden>
                  {t("merchant.chooseFromList")}
                </option>
                {merchants &&
                  merchants.map((el, i) => (
                    <option value={JSON.stringify(el)} key={i}>
                      {el.name.length > 30
                        ? el.name.slice(0, 30) + "..."
                        : el.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
        )}
        <div className="main-page-selected-merch">
          {((selectedMerchant && !selectedShop && userIsSuperUser) ||
            (userIsAdmin && !selectedShop)) &&
            shops?.length === 0 && (
              <label className="warning-message">{t("storage.shop.4")}</label>
            )}
          {((selectedMerchant && !selectedShop && userIsSuperUser) ||
            (userIsAdmin && !selectedShop)) &&
            shops?.length !== 0 && (
              <>
                <label>
                  <img className="shop_img" src={shopImg} alt="" />
                  {t("storage.shop.1")}
                </label>
                <select
                  value={selectedShop}
                  onChange={(e) => props.setSelShop(JSON.parse(e.target.value))}
                >
                  <option value={""} disabled hidden>
                    {t("merchant.chooseFromList")}
                  </option>
                  {shops &&
                    shops.map((el, i) => (
                      <option value={JSON.stringify(el)} key={i}>
                        {el.name.length > 30
                          ? el.name.slice(0, 30) + "..."
                          : el.name}
                      </option>
                    ))}
                </select>
              </>
            )}
          {selectedShop && (
            <>
              <label>
                <img className="shop_img" src={shopImg} alt="" />
                {t("storage.shop.2")}
              </label>
              <div>
                <p>
                  {selectedShop.name.length > 30
                    ? selectedShop.name.slice(0, 30) + "..."
                    : selectedShop.name}
                </p>
                <CancelIcon
                  onClick={() => props.setSelShop(null)}
                  className="cancel-icon"
                />
              </div>
            </>
          )}
        </div>
      </div>
      {((userIsSuperUser && (!selectedMerchant || !selectedShop)) ||
        (userIsAdmin && !selectedShop)) && (
        <div className="not-selected-shop">
          <div className="centered-container">
            <img src={bigShopImg} alt="" />
            <span>
              {!selectedMerchant &&
                userIsSuperUser &&
                t("merchant.notChoosedMerchant")}
              {((userIsSuperUser && selectedMerchant && !selectedShop) ||
                (!selectedShop && userIsAdmin)) &&
                t("storage.shop.3")}
            </span>
          </div>
        </div>
      )}
      {((selectedMerchant && selectedShop && userIsSuperUser) ||
        (selectedShop && userIsAdmin)) && (
        <PageStorage
          merchantId={userIsAdmin ? merchantId : selectedMerchant.id}
          shopId={selectedShop.id}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedMerchant: state.admin.selectedMerchant,
  merchants: state.admin.merchants,
  selectedShop: state.admin.selectedShop,
});

const mapDispatchToProps = (dispatch) => ({
  setMerchants: (arr) => dispatch(actions.setMerchants(arr)),
  setSelMerchant: (val) => dispatch(actions.setSelectedMerchant(val)),
  setSelShop: (val) => dispatch(actions.setSelectedShop(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Storage);
