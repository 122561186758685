import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import CategoryAPI from "../../../../../models/storage/categories/categories";
import ProductsAPI from "../../../../../models/storage/products/products";

import SelectCat from "./components/SelectCat/SelectCat";
import ProductItem from "./components/ProductItem/ProductItem";
import ProductModal from "../../../../../components/UI/ProductsModal/ProductsModal";
import TextField from "@material-ui/core/TextField";
import ServerError from "../../../../../components/UI/Errors/ServerError/ServerError";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import Modal from "../../../../../components/UI/Modal/Modal";
import CreateProd from "./components/CreateProd/CreateProd";
import EditProd from "./components/EditProd/EditProd";

// svg -s
import assort from "../../../../../assets/images/pages/Products/assort.svg";
import basket from "../../../../../assets/images/pages/Products/basket.svg";

const listingArr = (arr, i) => {
  let result = [...arr];
  result = result.map((el) => [
    { el, level: i },
    el.children && el.children.map((el) => listingArr([el], i + 1)),
  ]);
  return result;
};

const SuccessCreatedContent = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>
        {t("prod.successCreate")} <strong>{props.name}</strong>
      </p>
    </div>
  );
};

const SuccessChangedContent = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>
        {t("prod.successChange")} <strong>{props.name}</strong>
      </p>
    </div>
  );
};

const DeleteModeContent = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>{`${t("prod.deleteMode")} ${props.obj && props.obj.name}`}</p>
      <div>
        <button
          onClick={() => props.onClose()}
          style={{
            marginTop: "20px",
            marginRight: "10px",
            padding: "9px 20px",
            cursor: "pointer",
            border: "1px solid #9978E2",
            color: "#9978E2",
            backgroundColor: "transparent",
          }}
        >
          {t("prod.no")}
        </button>
        <button
          onClick={() => props.onDelete(props.obj && props.obj.id)}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            color: "#fff",
            backgroundColor: "#9978E2",
          }}
        >
          {t("prod.yes")}
        </button>
      </div>
    </div>
  );
};

const Products = (props) => {
  const { merchantId, shopId, modal } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [allCats, setAllCats] = useState([]);
  const [products, setProducts] = useState([]);
  const productsPerPage = 20;
  const [productsOffset, setProductsOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [searchName, setSearchName] = useState("");
  const [searchCat, setSearchCat] = useState("");
  const [searchMode, setSearchMode] = useState(false);

  // modals
  const [successCreated, setSuccessCreated] = useState(null);
  const [successChanged, setSuccessChanged] = useState(null);
  const [deleteMode, setDeleteMode] = useState(null);

  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(null);

  useEffect(async () => {
    setLoading(true);
    setError(false);
    const category = new CategoryAPI(merchantId, shopId);
    const products = new ProductsAPI(merchantId, shopId);
    try {
      const result = await category.getAllProd();
      const resultProd = await products.getAllProd(productsPerPage, 0);
      let newAllCats = result.data.results.filter((el) => !el.parentId);
      newAllCats = listingArr(newAllCats, 0);
      newAllCats = newAllCats.map((el) => el.flat(Infinity));
      setAllCats(newAllCats);
      setProducts(resultProd.data.results);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }, []);

  useEffect(async () => {
    setLoading(true);
    setError(false);

    const products = new ProductsAPI(merchantId, shopId);
    try {
      const resultProd = await products.getAllProd(
        productsPerPage,
        productsOffset,
      );
      setProducts(resultProd.data.results);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }, [productsOffset]);

  useEffect(async () => {
    if (location.state && location.state.selectedRecord) {
      return redirectToEdit(+location.state.selectedRecord.actionArgs);
    }

    if (location.state && location.state.successCreated) {
      setSuccessCreated(location.state.name);
      setCreateMode(false);
      history.push({
        pathname: "/main/storage/products",
        state: null,
      });
      await updateProducts();
    } else if (location.state && location.state.successChanged) {
      setSuccessChanged(location.state.name);
      setEditMode(null);
      history.push({
        pathname: "/main/storage/products",
        state: null,
      });
      await updateProducts();
    }
  }, [location.state]);

  const redirectToCreate = () => setCreateMode(true);

  const redirectToEdit = (id) => setEditMode(id);

  const updateProducts = async () => {
    setProductsOffset(0);
    setLoading(true);
    setError(false);
    const products = new ProductsAPI(merchantId, shopId);
    try {
      const resultProd = await products.getAllProd(productsPerPage, 0);
      setProducts(resultProd.data.results);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const onSearch = async () => {
    if (searchName === "" && searchCat === "") return;
    const products = new ProductsAPI(merchantId, shopId);
    try {
      const result = await products.search(searchName, searchCat);
      setProducts(result.data.results);
      setSearchMode(true);
    } catch (e) {
      setError(true);
    }
  };

  const onDeleteProd = async (id) => {
    setLoading(true);
    setError(false);
    setDeleteMode(null);
    const products = new ProductsAPI(merchantId, shopId);
    try {
      const result = products.deleteById(id);
      const resultProd = await products.getAllProd(
        productsPerPage,
        productsOffset,
      );
      setProducts(resultProd.data.results);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const onCancelSearch = async () => {
    setError(false);
    setLoading(true);
    setSearchMode(false);
    setSearchName("");
    setSearchCat("");
    const products = new ProductsAPI(merchantId, shopId);
    try {
      const resultProd = await products.getAllProd(productsPerPage, 0);
      setProductsOffset(0);
      setProducts(resultProd.data.results);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const w = window.innerWidth;

  return (
    <div className="products-container">
      {error && <ServerError />}
      {!error && (
        <>
          {loading && (
            <div
              style={{
                width: "100%",
                height: "80vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </div>
          )}
          {!error && !loading && (
            <ul className="products-container_content">
              <ProductModal
                show={createMode}
                onClose={() => setCreateMode(false)}
                className="create-prod-modal"
              >
                <CreateProd
                  {...props}
                  cats={allCats}
                  merchantId={merchantId}
                  shopId={shopId}
                  onClose={() => setCreateMode(false)}
                />
              </ProductModal>
              <ProductModal show={editMode} onClose={() => setEditMode(null)}>
                <EditProd
                  {...props}
                  id={editMode}
                  cats={allCats}
                  merchantId={merchantId}
                  shopId={shopId}
                  onClose={() => setEditMode(null)}
                />
              </ProductModal>
              <Modal
                show={successCreated}
                onClose={() => setSuccessCreated(null)}
              >
                <SuccessCreatedContent name={successCreated} />
              </Modal>
              <Modal
                show={successChanged}
                onClose={() => setSuccessChanged(null)}
              >
                <SuccessChangedContent name={successChanged} />
              </Modal>
              <Modal show={deleteMode} onClose={() => setDeleteMode(null)}>
                <DeleteModeContent
                  obj={deleteMode}
                  onDelete={(id) => onDeleteProd(id)}
                  onClose={() => setDeleteMode(null)}
                />
              </Modal>
              <div
                className="products-container_content-header"
                style={{ borderBottom: "none  " }}
              >
                <div className="left">
                  <img src={assort} alt="" />
                  <p>{t("prod.title")}</p>
                </div>
                <button onClick={redirectToCreate}>
                  <img src={basket} alt="" />
                  <p>{t("prod.button1")}</p>
                </button>
              </div>
              <div
                className="products-container_header_bottom"
                style={{
                  paddingBottom: "20px",
                  borderBottom: "1px solid #C6C7CF",
                  paddingLeft: "15px",
                }}
              >
                <TextField
                  style={{ marginRight: "10px", marginTop: "10px" }}
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                  label={t("prod.search.name")}
                  type="search"
                  variant="outlined"
                />
                <div style={{ marginRight: "10px", marginTop: "10px" }}>
                  <SelectCat
                    cats={allCats}
                    value={searchCat}
                    change={(e) => setSearchCat(e.target.value)}
                  />
                </div>
                <button onClick={onSearch} style={{ marginRight: "10px" }}>
                  {t("prod.search.search")}
                </button>
                {searchMode && (
                  <button className="cancel" onClick={onCancelSearch}>
                    {t("prod.search.cancel")}
                  </button>
                )}
              </div>
              <li className="products-container_content-header-names">
                <div className="pd-name">{t("prod.table.1")}</div>
                <div className="pd-image">{t("prod.table.2")}</div>
                <div className="pd-cat">{t("prod.table.3")}</div>
                <div className="pd-price">{t("prod.table.4")}</div>
                <div className="pd-count">
                  {w > 1024 && !modal ? t("prod.table.5") : t("prod.table.6")}
                </div>
                <div className="cat-tools">{t("prod.table.7")}</div>
              </li>
              {products.map((el, index) => (
                <ProductItem
                  key={index}
                  product={el}
                  cats={allCats}
                  onEdit={(id) => redirectToEdit(id)}
                  onDelete={(el) => setDeleteMode(el)}
                  modal={modal}
                  onAddModalProd={props.onAdd}
                />
              ))}
            </ul>
          )}
          <div className="products-container-pagination">
            <div
              onClick={() => {
                if (productsOffset !== 0) {
                  setProductsOffset(productsOffset - productsPerPage);
                }
              }}
            >
              &laquo;
            </div>
            {[
              productsOffset / productsPerPage - 1,
              productsOffset / productsPerPage,
              productsOffset / productsPerPage + 1,
              productsOffset / productsPerPage + 2,
              productsOffset / productsPerPage + 3,
              productsOffset / productsPerPage + 4,
            ].map((el) => {
              if (el >= 1) {
                return (
                  <div
                    onClick={
                      products.length < productsPerPage &&
                      el > productsOffset / productsPerPage + 1
                        ? null
                        : () => setProductsOffset((el - 1) * productsPerPage)
                    }
                    className={
                      el === productsOffset / productsPerPage + 1
                        ? "active"
                        : products.length < productsPerPage
                          ? "disabled"
                          : null
                    }
                  >
                    {el}
                  </div>
                );
              } else return null;
            })}
            <div
              onClick={
                products.length < productsPerPage
                  ? null
                  : () => setProductsOffset(productsOffset + productsPerPage)
              }
              className={products.length < productsPerPage ? "disabled" : null}
            >
              &raquo;
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Products;
