import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/UI/Spinner/Spinner";
import ServerError from "../../components/UI/Errors/ServerError/ServerError";
import Error from "../../components/UI/Errors/CustomError/CustomError";
import Modal from "../../components/UI/Modal/Modal";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import "./profile.scss";
import profileLogo from "../../assets/images/pages/Profile/profile_logo.svg";
import $api from "../../api/http";

const Profile = (props) => {
  const { t } = useTranslation();

  const [pass, setPass] = useState("");
  const [oldPass, setoldPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [successChangedPass, setSuccessChangedPass] = useState(false);

  const info = JSON.parse(localStorage.getItem("info"));
  const firstName = info && info.firstName;
  const middleName = info && info.middleName;
  const lastName = info && info.lastName;
  const title = info && info.title;
  const roleName = info && info.roleName;

  const onUpdatePassword = (pass) => {
    setError(false);
    setLoading(true);
    const userId = info.id;

    $api
      .get(`/Accounts/${userId}`)
      .then((res) => {
        const login = res.data.login;
        $api
          .post("/Accounts/change-password", {
            login,
            oldPassword: oldPass,
            password: pass,
          })
          .then((res) => {
            setLoading(false);
            setPass("");
            setoldPass("");
            setSuccessChangedPass(true);
          })
          .catch((err) => {
            if (err.response && err.response.data.message) {
              setError(err.response.data.message);
            } else {
              setError(true);
            }
            setLoading(false);
          });
      })
      .catch((err) => {
        if (err.response && err.response.data.message) {
          setError(err.response.data.message);
        } else {
          setError(true);
        }
        setLoading(false);
      });
  };

  const modalSuccessChangedPassword = (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>{t("perCab.successChnage")}</p>
    </div>
  );

  return (
    <div className="profile">
      <Modal
        show={successChangedPass}
        onClose={() => setSuccessChangedPass(false)}
      >
        {modalSuccessChangedPassword}
      </Modal>
      <div className="profile_label">
        <img alt="" src={profileLogo} />
      </div>
      <div className="profile_main">
        {loading && (
          <div
            style={{
              width: "100%",
              height: "80vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
          </div>
        )}
        {error === true && <ServerError />}
        {typeof error === "string" && <Error title={error} />}
        {!error && !loading && (
          <>
            <IconButton
              style={{ position: "absolute", top: "10px", left: "20px" }}
              onClick={() => props.history.push("/main/orders")}
            >
              <ArrowBackIcon />
            </IconButton>
            <h1>
              {t("perCab.title")}{" "}
              <div>
                <span>({`${firstName} ${lastName} ${middleName}`})</span>
              </div>{" "}
            </h1>
            <div className="profile_main_area">
              <span>{t("perCab.dolj")}</span>
              <p>{title}</p>
            </div>
            <div className="profile_main_area">
              <span>{t("perCab.roleName")}</span>
              <p>{roleName}</p>
            </div>
            <h3>{t("perCab.changePass.title")}</h3>
            <div className="profile_main_area" style={{ alignSelf: "center" }}>
              <label>{t("perCab.changePass.old")}</label>
              <input
                type="password"
                onChange={(e) => setoldPass(e.target.value)}
              />
            </div>
            <div className="profile_main_area" style={{ alignSelf: "center" }}>
              <label>{t("perCab.changePass.new")}</label>
              <input
                type="password"
                onChange={(e) => setPass(e.target.value)}
              />
            </div>
            <button
              onClick={
                pass.trim().length >= 6 && oldPass.trim().length >= 6
                  ? () => onUpdatePassword(pass)
                  : null
              }
              className={
                pass.trim().length >= 6 && oldPass.trim().length >= 6
                  ? "change_pass_active"
                  : "change_pass_dis"
              }
            >
              {t("perCab.changePass.button")}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
