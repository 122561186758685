import React, { useEffect, useState } from "react";
import ShopItem from "./ShopItem/ShopItem";
import { useTranslation } from "react-i18next";

//icons
import plus from "../../../assets/images/components/ShopsList/plus.svg";

import "./shopsList.scss";

const ShopsList = (props) => {
  const { t } = useTranslation();

  const {
    admin,
    shops,
    setSelectedShop,
    selectedShop,
    searchString,
    pagination,
    paginationFunction,
  } = props;

  const [currentShops, setCurrentShops] = useState(shops);

  useEffect(() => {
    shops && setCurrentShops(shops);
  }, [shops]);

  useEffect(() => {
    if (!searchString || searchString === "") setCurrentShops(shops);

    const newShops = shops
      ? [...shops].filter((el) =>
          [el.city, el.street, el.buildingNumber, el.name]
            .join(" ")
            .toLocaleLowerCase()
            .includes(searchString.toLowerCase()),
        )
      : [];
    setCurrentShops(newShops);
  }, [searchString]);

  const onSelectShopHandler = (shop) => {
    setSelectedShop(shop);
  };

  return (
    <div className="shops-list-block">
      <div className="shops-list-block__body">
        <div className="shops-list-block__body_scrolled">
          {currentShops &&
            currentShops.map((el) => (
              <ShopItem
                el={el}
                key={el.name}
                admin={admin}
                setEditedShop={props.setEditedShop}
                onEdit={() => props.setEditedShop(el)}
                /*onSelect={
              selectedShop && selectedShop.id === el.id ? () => {} : () => setSelectedShop(el)
            }*/
                onSelect={() => onSelectShopHandler(el)}
                selected={selectedShop ? selectedShop.id === el.id : false}
              />
            ))}
          {pagination && (
            <div
              onClick={paginationFunction}
              className="shops-list-block__body__pagination"
            >
              <p>{"Выгрузить еще..."}</p>
            </div>
          )}
        </div>
        <div className="scrollable-shadow" />
      </div>

      {admin && (
        <div onClick={props.onCreateNew} className="shops-list-block__footer">
          <img src={plus} alt="plus" />
          <p>{t("shopsList.createNew")}</p>
        </div>
      )}
    </div>
  );
};

export default ShopsList;
