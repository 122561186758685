import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import "moment/locale/uk";

import ErrorIcon from "@material-ui/icons/Error";
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PaymentIcon from "@material-ui/icons/Payment";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import TimerIcon from "@material-ui/icons/Timer";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";

// icons
import acessTimeIcon from "../../../assets/images/components/OrderItem/acess_time.svg";
import deliveryIcon from "../../../assets/images/components/OrderItem/delivery.svg";
import commentIcon from "../../../assets/images/components/OrderItem/comment.svg";

import "./orderItem.scss";

const OrderItem = (props) => {
  const { id, state } = props.order.order;
  const { t } = useTranslation();

  const accountFirstName =
    props.order.account && props.order.account.accountFirstName;
  const accountLastName =
    props.order.account && props.order.account.accountLastName;
  const accountMiddleName =
    props.order.account && props.order.account.accountMiddleName;

  const userId = JSON.parse(localStorage.getItem("info")).id;

  const createdDate = moment(props.order.order.createdDate)
    .locale("uk")
    .tz("Europe/Kiev")
    .format("HH:MM");
  const desirableDeliveryTime = props.order.order.properties
    ?.desirable_delivery_time
    ? moment(props.order.order.properties.desirable_delivery_time)
        .locale("uk")
        .tz("Europe/Kiev")
        .format("HH:MM")
    : "-";

  const { selected } = props;

  let orderStatusClassName = `order-item_status status_${state}`;
  const statusesLabel = {
    "-1": t("merchant.orders.error.status"),
    0: t("merchant.orders.issue.status"),
    1: t("merchant.orders.cancel.status"),
    2: t("merchant.orders.toWork.status"),
    3: t("merchant.orders.pend.status"),
    4: t("merchant.orders.pending.status"),
    5: t("merchant.orders.inProcess.status"),
    6: t("merchant.orders.completed.status"),
    7: t("merchant.orders.returned.status"),
  };
  const logosStatuses = {
    "-1": <ErrorIcon style={{ width: "20px", height: "20px" }} />,
    0: <DoneIcon style={{ width: "20px", height: "20px" }} />,
    1: <CancelIcon style={{ width: "20px", height: "20px" }} />,
    2: <AddCircleOutlineIcon style={{ width: "20px", height: "20px" }} />,
    3: <PaymentIcon style={{ width: "20px", height: "20px" }} />,
    4: <TimerIcon style={{ width: "20px", height: "20px" }} />,
    5: <AutorenewIcon style={{ width: "20px", height: "20px" }} />,
    6: <AlarmOnIcon style={{ width: "20px", height: "20px" }} />,
    7: <SwapHorizontalCircleIcon style={{ width: "20px", height: "20px" }} />,
  };

  const totalSum = +props.order.order.shoppingCart.products
    .reduce((acc, val) => {
      if (val.pd.bl) {
        return (
          acc + (val.q / (val.pd.w === 0 ? val.pd.v : val.pd.w)) * val.pd.p
        );
      } else {
        return acc + val.q * val.pd.p;
      }
    }, 0)
    .toFixed(2);

  const orderItemStyles =
    state === 2 && !props.order.account && selected
      ? {
          borderLeft: "6px solid rgba(89, 163, 56, 1)",
          backgroundColor: "#e9f2fe",
        }
      : state === 2 && !props.order.account && !selected
        ? { borderLeft: "6px solid rgba(89, 163, 56, 1)" }
        : selected
          ? {
              backgroundColor: "#e9f2fe",
              borderLeft: "6px solid #9978E2",
            }
          : { borderLeft: "6px solid #fff" };

  return (
    <div
      onClick={() => props.setSelectedOrder(props.order)}
      className="order-item-container"
      style={orderItemStyles}
    >
      <div className="order-item-container-top">
        <h3
          className="order-label"
          style={selected ? { color: "#9978E2" } : null}
        >{`${t("merchant.ordersList.name")} ${id}`}</h3>
        <div className="order-created-time">
          <img src={acessTimeIcon} alt="" />
          <span>{createdDate}</span>
        </div>
        <span className="total-price">{totalSum + " ₴"}</span>
      </div>

      <div className="order-item-container-middle">
        {props.order.user && (
          <div className="client-info">
            <span className="name">{props.order.user.fn}</span>
            <span className="phone">{props.order.user.phone}</span>
            {props.order.order.properties &&
              props.order.order.properties.comment && (
                <img src={commentIcon} alt="" className="comment" />
              )}
          </div>
        )}
        {props.order.account && (
          <div
            className={
              props.order.account.accountId === userId
                ? "main-selected-order-user-active"
                : "main-selected-order-user"
            }
          >
            {`${
              accountFirstName && accountFirstName.length > 8
                ? accountFirstName.slice(0, 7) + ".."
                : accountFirstName
            } ${accountLastName && accountLastName.slice(0, 1)}.${
              accountMiddleName && accountMiddleName.slice(0, 1)
            }.`}
          </div>
        )}
      </div>

      <div className="order-item-container-bottom">
        <div className={orderStatusClassName}>
          {logosStatuses[state]}
          <span>{statusesLabel[state]}</span>
        </div>
        {state === 2 && selected && !props.order.account && (
          <div className="order_take" onClick={() => props.addOrderToWork(id)}>
            <p>{t("merchant.orders.toWork.label")}</p>
          </div>
        )}
        <div className="desirable_delivery_time">
          <img src={deliveryIcon} alt="" />
          <span>{desirableDeliveryTime}</span>
        </div>
      </div>

      {/* <div className='order-item-container_right'>
        {state === 2 && selected && !props.order.account && (
          <div className='order-item-container_right_take' onClick={() => props.addOrderToWork(id)}>
            <p>{t('merchant.orders.toWork.label')}</p>
          </div>
        )}
        {props.order.account && (
          <div
            className={
              props.order.account.accountId === userId ? 'main-selected-order-user-active' : 'main-selected-order-user'
            }
          >
            {`${
              accountFirstName && accountFirstName.length > 8 ? accountFirstName.slice(0, 7) + '..' : accountFirstName
            } ${accountLastName && accountLastName.slice(0, 1)}.${accountMiddleName && accountMiddleName.slice(0, 1)}.`}
          </div>
        )} */}
    </div>
  );
};

export default OrderItem;
