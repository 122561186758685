import React, { useEffect, useState } from "react";
import closeAlertIcon from "../../../assets/images/components/common/closeAlertIcon.svg";
import confirmAlertIcon from "../../../assets/images/components/common/confirmAlertIcon.png";
import "./Alert.scss";
import "../../../app.scss";

const ConfirmAlert = ({ alertInfo, closeAlert, setWrapAnimationEnd }) => {
  const [animationEnd, setAnimationEnd] = useState(false);

  const onClickHandler = () => {
    closeAlert();
    alertInfo.confirmButtonF();
  };

  useEffect(() => {
    const t1 = window.setTimeout(() => {
      setAnimationEnd(true);
      setWrapAnimationEnd(true);
    }, 5000);
    const t2 = window.setTimeout(() => {
      alertInfo.confirmButtonF();
      closeAlert();
    }, 5500);
    return () => {
      window.clearTimeout(t1);
      window.clearTimeout(t2);
    };
  }, []);

  return (
    <div
      className={
        !animationEnd
          ? `AlertBox Animation_Opacity_0to1_04s`
          : `AlertBox Animation_Opacity_1to0_04s`
      }
      onMouseDown={(e) => e.stopPropagation()}
    >
      <img
        className="AlertCloseIcon"
        src={closeAlertIcon}
        alt="закрити"
        onClick={closeAlert}
      />
      <img
        className="AlertConfirmIcon"
        src={confirmAlertIcon}
        alt="взято в роботу"
        onClick={closeAlert}
      />
      <h3 className="AlertTitle">{alertInfo.titleText}</h3>
      <div className="AlertDescription">{alertInfo.descriptionText}</div>

      <div className="ButtonsWrap">
        <div
          className="ButtonConfirm"
          style={{ width: "100%" }}
          onClick={onClickHandler}
        >
          {alertInfo.confirmButtonText}
        </div>
      </div>
    </div>
  );
};

export default ConfirmAlert;
