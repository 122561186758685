import $api from "../http";

export default class AccountsService {
  static async createUser(data) {
    return $api.post(`/Accounts`, data);
  }

  static async updateUser(userId, data) {
    return $api.put(`/Accounts/${userId}`, data);
  }

  static async getUserAva(userId) {
    return $api.get(`/Accounts/${userId}/image`, {
      responseType: "arraybuffer",
    });
  }

  static async updateUserFile(userId, file) {
    return $api.post(`/Accounts/${userId}/image`, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
  }
}
