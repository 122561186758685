import {
  SET_ALERT_INFO,
  SET_ALERT_SHOWN,
  SET_PROMPT_INFO,
  SET_PROMPT_SHOWN,
} from "../actions/alert/alertTypes";

const initialState = {
  isAlertShown: false,
  alertInfo: undefined,
  isPromptShown: false,
  promptInfo: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERT_SHOWN: {
      return {
        ...state,
        isAlertShown: action.isAlertShown,
      };
    }
    case SET_ALERT_INFO: {
      return {
        ...state,
        isAlertShown: true,
        alertInfo: action.alertInfo,
      };
    }
    case SET_PROMPT_SHOWN: {
      return {
        ...state,
        isPromptShown: action.isPromptShown,
      };
    }
    case SET_PROMPT_INFO: {
      return {
        ...state,
        isPromptShown: true,
        promptInfo: action.promptInfo,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
