import React from "react";

const ServerError = () => {
  const mainDivStyle = {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  };
  const errorBlockStyle = {
    width: "450px",
    borderRadius: "10px",
    border: "3px solid #da4e3b",
    backgroundColor: "#fef2f4",
    marginTop: "40px",
  };
  const paragErrorStyle = {
    margin: "20px",
    color: "#da4e3b",
    letterSpacing: "1px",
  };
  return (
    <div style={mainDivStyle}>
      <div style={errorBlockStyle}>
        <p style={paragErrorStyle}>{"Произошла серверная ошибка..."}</p>
      </div>
    </div>
  );
};

export default ServerError;
