import axios from "axios";
import { logout } from "../../store/actions";
import { store } from "../../index";
import { backendUrl } from "../../config/config";

/*
main - https://utake.ukrpay.net/webadmin/
test - http://192.168.65.156:9710
test-mobile - http://192.168.65.71:4431
*/
//export const API_URL = "http://192.168.65.156:9710";
//export const API_URL = backendUrl;

const $api = axios.create({
  //withCredentials: true,
  baseURL: backendUrl,
});

// for using access token
$api.interceptors.request.use((config) => {
  console.log("STARTED interceptors.request");
  const token = localStorage.getItem("token");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// response
$api.interceptors.response.use(
  (config) => {
    console.log("config = ", config);
    return config;
  },
  async (error) => {
    if (error.response) {
      // Сервер ответил, но что-то пошло не так (статус-коды вне диапазона 2xx)
      console.log("error.response.data: ", error.response.data); // содержимое ответа сервера
    } else if (error.request) {
      // Запрос отправлен, но ответ не получен
      console.log("error.request: ", error.request);
    } else {
      // Проблема с формированием запроса на стороне клиента
      console.log("Ошибка:", error.message); // Сообщение об ошибке
    }
    //console.log('error.config: ', error.config); // Посмотрим на конфигурацию запроса
    console.log("error = ", error);
    if (error.response.status === 401) {
      store.dispatch(logout());
    }

    throw error;
  },
);

export default $api;
