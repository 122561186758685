import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import * as actions from "../../../../../../store/actions/index";

// assets
import shopImg from "../../../../../../assets/images/components/ShopsList/shop.svg";

const AdminPageMerchantNotChoosed = (props) => {
  const { selectedMerchant, merchants, setSelMerchant } = props;
  const { t } = useTranslation();

  return (
    <>
      <label>
        <img className="shop_img" src={shopImg} alt="" />
        {t("merchant.chooseMerchant")}
      </label>
      <select
        value={selectedMerchant ? selectedMerchant : ""}
        onChange={(e) => setSelMerchant(JSON.parse(e.target.value))}
      >
        <option value={""} disabled hidden>
          {t("merchant.chooseFromList")}
        </option>
        {merchants.map((el, i) => (
          <option value={JSON.stringify(el)} key={i}>
            {el.name.length > 30 ? el.name.slice(0, 30) + "..." : el.name}
          </option>
        ))}
      </select>
    </>
  );
};

const mapStateToProps = (state) => ({
  merchants: state.admin.merchants,
  selectedMerchant: state.admin.selectedMerchant,
});

const mapDispatchToProps = (dispatch) => ({
  setSelMerchant: (val) => dispatch(actions.setSelectedMerchant(val)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminPageMerchantNotChoosed);
