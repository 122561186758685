import React, { useState, createRef } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";

import select from "../../../../../../assets/images/wrapper/header/select.svg";
import avatar from "../../../../../../assets/images/wrapper/header/avatar.svg";

import useOutsideClick from "../../../../../../tools/useOutsideClick";

const UserInfoBlock = (props) => {
  const { t } = useTranslation();
  const { logout } = props;
  const history = useHistory();
  const info = JSON.parse(localStorage.getItem("info"));

  const [showUser, setShowUser] = useState(false);

  const userRef = createRef();
  useOutsideClick(userRef, showUser, () => setShowUser(false));

  return (
    <>
      <div
        onClick={() => setShowUser(true)}
        className="header__top__selected_user"
      >
        <img src={select} alt="select" />
        <img src={avatar} width={20} height={20} alt="avatar" />
        <p>{`${info && info.firstName && info.firstName} ${
          info && info.lastName && info.lastName.slice(0, 1)
        }. ${info && info.middleName && info.middleName.slice(0, 1)}.`}</p>
      </div>
      <CSSTransition
        in={showUser}
        timeout={300}
        classNames="anim"
        unmountOnExit
      >
        <ul
          ref={userRef}
          onClick={() => setShowUser(false)}
          className="dropdown"
        >
          <NavLink to="/profile">
            <li>
              {window.innerWidth > 700
                ? t("header.dropDown.1")
                : t("header.dropDown.3")}
            </li>
          </NavLink>
          <li
            onClick={() => {
              logout();
              history.push("/auth");
            }}
          >
            {t("header.dropDown.2")}
          </li>
        </ul>
      </CSSTransition>
    </>
  );
};

export default UserInfoBlock;
