import React, { useEffect, useMemo, useState } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import {
  getPosterStore_Fields,
  getPosterStoreSchema,
} from "../../../../../tools/pages/joinposter";
import { useYupValidationResolver } from "../../../../../tools/common/hooks";
import { useForm } from "react-hook-form";
import GlobalLoader from "../../../../../components/UI/GlobalLoader/GlobalLoader";
import RHFInput from "../../../../../components/common/form/RHFInput";
import OpeningHours from "../../../../Administration/modals/fields/OpeningHours/OpeningHours";
import { getWorkTimeInit } from "../../../../../tools/pages/administration";
import JoinPosterService from "../../../../../api/services/JoinPoster";
import RhfPhoto from "../../../../../components/common/form/RHFPhoto";
import RHFSelect from "../../../../../components/common/form/RHFSelect";
import {
  setAlertInfo,
  setPromptInfo,
} from "../../../../../store/actions/alert/alertActions";
import { useDispatch } from "react-redux";

const PosterStoreForm = ({ store, index }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const store_Fields = useMemo(() => getPosterStore_Fields(t), [t]);
  const schema = useMemo(() => getPosterStoreSchema(t), [t]);
  const resolver = useYupValidationResolver(schema);
  const [isLoading, setIsLoading] = useState(false);
  const [imageInit, setImageInit] = useState(
    store.image ? store.image : undefined
  );
  const [editIsActive, setEditIsActive] = useState(false);
  const [regionOptionInit, setRegionOptionInit] = useState(undefined);
  const [weekDaysFormData, setWeekDaysFormData] = useState(undefined);
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    validateMode: "onChange",
    resolver,
  });

  useEffect(() => {
    const parsedData = store.schedule
      ? JSON.parse(JSON.parse(store.schedule))
      : null;

    setWeekDaysFormData(
      parsedData && parsedData.open_hours
        ? parsedData.open_hours
        : getWorkTimeInit(t)
    );
  }, [store]);

  useEffect(() => {
    /*merchantInit && merchantInit.logo && getAvaInit(merchantInit.id);*/
    // set init form values
    const initValues = {
      name: store ? store.name : "",
      address: store ? store.address : "",
      paymentMerchantId: store ? store.paymentMerchantId : "",
      paymentTerminalId: store ? store.paymentTerminalId : "",
      regionId:
        store && store.regionId
          ? store.regionId
          : store_Fields.regionId.listData[0].value,
    };

    reset({ ...initValues });

    const regionOptionInit_l =
      store && store.regionId
        ? store_Fields.regionId.listData.find(
            (el) => el.value === store.regionId
          )
        : store_Fields.regionId.listData[0];
    setRegionOptionInit(regionOptionInit_l);
  }, [store]);

  const onChangeEditCheckboxHandler = () => {
    setEditIsActive((prev) => !prev);
  };

  const addFormDataPhoto = async (file) => {
    const newName = file.name.replaceAll(" ", "");
    const resp = await JoinPosterService.updateStorePhoto(
      file,
      store.id,
      newName
    );

    if (resp.status === 200) {
      setImageInit(`https://adminsamo.com.ua/img/spots/images/${newName}`);
    } else {
      dispatch(
        setPromptInfo({ promptText: `При завантаженні фото виникла помилка.` })
      );
    }
  };

  const onSubmit = async (data) => {
    if (!imageInit) {
      dispatch(
        setPromptInfo({
          promptText: `Дані не були збережені: необхідно додати фото.`,
        })
      );
      return;
    }
    setIsLoading(true);

    const parSchedule = store.schedule
      ? JSON.parse(JSON.parse(store.schedule))
      : null;
    const schedule = parSchedule
      ? {
          ...parSchedule,
          open_hours: weekDaysFormData,
        }
      : { open_hours: weekDaysFormData };

    try {
      const reqData = {
        ...store,
        ...data,
        schedule: JSON.stringify({ ...schedule }),
      };

      delete reqData.logo;
      delete reqData.image;

      const response = await JoinPosterService.updateStore(reqData);
      if (response.data.image) {
        setImageInit(response.data.image);
      }
    } catch (error) {
      console.log(error);
    }
    setEditIsActive(false);
    setIsLoading(false);
  };

  if (isLoading) return <GlobalLoader />;
  return (
    <div className="PosterStoreWrap">
      <form
        className="PosterStoreForm"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        id={`PosterStoreForm_${index}`}
      >
        <input
          type="checkbox"
          className="PosterStoreForm_EditCheckbox"
          onChange={onChangeEditCheckboxHandler}
        />

        <RHFInput
          wrapStyles={{ marginTop: "30px", marginBottom: "10px" }}
          keyName={store_Fields.name.keyName}
          description={store_Fields.name.description}
          register={register}
          error={errors[store_Fields.name.keyName]}
          inputType={store_Fields[store_Fields.name.keyName].type}
          control={control}
          isDisabled={!editIsActive}
        />
        {editIsActive && (
          <RHFInput
            wrapStyles={{ marginTop: "30px", marginBottom: "10px" }}
            keyName={store_Fields.address.keyName}
            description={store_Fields.address.description}
            register={register}
            error={errors[store_Fields.address.keyName]}
            inputType={store_Fields[store_Fields.address.keyName].type}
            control={control}
            isDisabled={!editIsActive}
          />
        )}
        {editIsActive && (
          <RHFInput
            wrapStyles={{ marginTop: "30px", marginBottom: "10px" }}
            keyName={store_Fields.paymentMerchantId.keyName}
            description={store_Fields.paymentMerchantId.description}
            register={register}
            error={errors[store_Fields.paymentMerchantId.keyName]}
            inputType={
              store_Fields[store_Fields.paymentMerchantId.keyName].type
            }
            control={control}
            isDisabled={!editIsActive}
          />
        )}
        {editIsActive && (
          <RHFInput
            wrapStyles={{ marginTop: "30px", marginBottom: "25px" }}
            keyName={store_Fields.paymentTerminalId.keyName}
            description={store_Fields.paymentTerminalId.description}
            register={register}
            error={errors[store_Fields.paymentTerminalId.keyName]}
            inputType={
              store_Fields[store_Fields.paymentTerminalId.keyName].type
            }
            control={control}
            isDisabled={!editIsActive}
          />
        )}

        {editIsActive && regionOptionInit && (
          <RHFSelect
            control={control}
            keyName={store_Fields.regionId.keyName}
            description={store_Fields.regionId.description}
            listData={store_Fields.regionId.listData}
            selectedValue={
              store && store.regionId
                ? store.regionId
                : store_Fields.regionId.listData[0].value
            }
            id={`${index}1`}
            optionInit={regionOptionInit}
          />
        )}

        {editIsActive && (
          <RhfPhoto
            id={`storeForm_photo${index}`}
            previewUrlInit={imageInit}
            buttonChangingText={t("joinposter.storeForm.changePhoto")}
            buttonAddingText={t("joinposter.storeForm.addPhoto")}
            descriptionText={t("joinposter.storeForm.photo")}
            setFormDataFile={addFormDataPhoto}
          />
        )}

        {editIsActive && (
          <div
            style={
              {
                /*width: '310px', margin: '0 auto'*/
              }
            }
          >
            {weekDaysFormData && (
              <OpeningHours
                isNotModalWindow
                weekDaysInit={{ ...weekDaysFormData }}
                setWeekDaysFormData={setWeekDaysFormData}
                weekDaysFormData={weekDaysFormData}
              />
            )}
          </div>
        )}

        {editIsActive && (<div>* усі поля є обовʼязковими до заповнення</div>)}

        {editIsActive && (
          <div className="ButtonConfirmWrap">
            <button
              className="ButtonConfirm"
              type="submit"
              form={`PosterStoreForm_${index}`}
            >
              <span>
                {t("merchant.merchantRows.personnelAccessRights.buttonSave")}
              </span>
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default PosterStoreForm;
