import React from "react";
import search from "../../assets/images/components/ShopsList/search.svg";
import { useTranslation } from "react-i18next";
import "./MerchantNavigation.scss";
import { MerchantNavigation_tabsTypes } from "../../dataUI/common/merchant";
import MerchantNavigationTab from "./MerchantNavigationTab";

const MerchantNavigation = ({
  selectedTabType,
  setSelectedTabType,
  searchString,
  setSearchString,
  setSelectedUser,
  setSelectedShop,
  setUpdateUserMode,
  setCreateUserMode,
}) => {
  const { t } = useTranslation();

  const onTabClickHandler = (tabType) => {
    setSelectedUser(null);
    setSelectedShop(null);
    setUpdateUserMode(null);
    setCreateUserMode(null);
    setSelectedTabType(tabType);
  };

  return (
    <div className="merchantNavigation">
      <div className="merchantNavigation__header">
        <MerchantNavigationTab
          tabType={MerchantNavigation_tabsTypes.SHOPS}
          selectedTabType={selectedTabType}
          onTabClick={onTabClickHandler}
        />
        <MerchantNavigationTab
          tabType={MerchantNavigation_tabsTypes.PERSONNEL}
          selectedTabType={selectedTabType}
          onTabClick={onTabClickHandler}
          wrapStyles={{ marginLeft: "6px" }}
        />
      </div>
      <label className="search" htmlFor="search_shops_list">
        <label htmlFor="search_shops_list">
          <img src={search} alt="" />
        </label>
        <input
          type="text"
          id="search_shops_list"
          placeholder={
            selectedTabType === MerchantNavigation_tabsTypes.SHOPS
              ? t("shopsList.searchPlaceHolder1")
              : t("shopsList.searchPlaceHolder2")
          }
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
      </label>
    </div>
  );
};

export default MerchantNavigation;
