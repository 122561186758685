import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import * as actions from "../../../../../../store/actions/index";

// assets
import shopImg from "../../../../../../assets/images/components/ShopsList/shop.svg";
import CancelIcon from "@material-ui/icons/Cancel";

const AdminPageMerchantChoosed = (props) => {
  const { selectedMerchant, setSelMerchant } = props;
  const { t } = useTranslation();

  return (
    <>
      <label>
        <img className="shop_img" src={shopImg} alt="" />
        {t("merchant.choosedMerchant")}
      </label>
      <div>
        <p>
          {selectedMerchant.name.length > 30
            ? selectedMerchant.name.slice(0, 30) + "..."
            : selectedMerchant.name}
        </p>
        <CancelIcon
          onClick={() => setSelMerchant(null)}
          className="cancel-icon"
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  merchants: state.admin.merchants,
  selectedMerchant: state.admin.selectedMerchant,
});

const mapDispatchToProps = (dispatch) => ({
  setSelMerchant: (val) => dispatch(actions.setSelectedMerchant(val)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminPageMerchantChoosed);
