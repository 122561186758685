import React, { useState, useEffect } from "react";
import CaterogyAPI from "../../../../../models/storage/categories/categories";
import { useTranslation } from "react-i18next";

import basket from "../../../../../assets/images/pages/Products/basket.svg";
import CategoryItem from "./components/CategoryItem/CategoryItem";
import ServerError from "../../../../../components/UI/Errors/ServerError/ServerError";
import CustomError from "../../../../../components/UI/Errors/CustomError/CustomError";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import Modal from "../../../../../components/UI/Modal/Modal";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const SuccessCreatedContent = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>
        {t("cats.successCreate")}
        <strong>{props.name}</strong>
      </p>
    </div>
  );
};

const SuccessChangedContent = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>
        {t("cats.successChange")}
        <strong>{props.name}</strong>
      </p>
    </div>
  );
};

const DeleteModeContent = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>{`${t("cats.deleteMode")} ${props.obj && props.obj.name}`}</p>
      <div>
        <button
          onClick={() => props.onClose()}
          style={{
            marginTop: "20px",
            marginRight: "10px",
            padding: "9px 20px",
            cursor: "pointer",
            border: "1px solid #9978E2",
            color: "#9978E2",
            backgroundColor: "transparent",
          }}
        >
          {t("prod.no")}
        </button>
        <button
          onClick={() => props.onDelete(props.obj && props.obj.id)}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            cursor: "pointer",
            border: "none",
            color: "#fff",
            backgroundColor: "#9978E2",
          }}
        >
          {t("cats.yes")}
        </button>
      </div>
    </div>
  );
};

const Categories = (props) => {
  const { t } = useTranslation();
  const { merchantId, shopId } = props;

  const [currentCats, setCurrentCats] = useState([]);
  const [parentCategory, setParentCategory] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [successCreated, setSuccessCreated] = useState(null);
  const [successChanged, setSuccessChanged] = useState(null);
  const [deleteMode, setDeleteMode] = useState(null);

  useEffect(async () => {
    const category = new CaterogyAPI(merchantId, shopId);
    setLoading(true);
    setError(false);

    try {
      if (!props.topLevel) {
        const id = props.match.params.id;
        const result = await category.getByCatId(id);
        setCurrentCats(result.data.children);
        setParentCategory({
          id: result.data.id,
          name: result.data.name,
          parentId: result.data.parentId,
        });
      } else {
        const result = await category.getTopLevel(merchantId, shopId);
        setCurrentCats(result.data.results);
        setParentCategory(null);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);

    return () => {};
  }, [props.match.params.id]);

  useEffect(() => {
    if (props.location.state && props.location.state.successCreated) {
      setSuccessCreated(props.location.state.name);
      props.location.state.successCreated = null;
      props.location.state.name = null;
    } else if (props.location.state && props.location.state.successChanged) {
      setSuccessChanged(props.location.state.name);
      props.location.state.successChanged = null;
      props.location.state.name = null;
    }
  }, [props.location.state]);

  const goToTopLevel = () => props.history.push(`/main/storage/categories`);
  const goToPreviousLevel = () =>
    props.history.push(`/main/storage/categories/${parentCategory.parentId}`);
  const redirectToCreate = () => {
    if (parentCategory) {
      props.history.push({
        pathname: `/main/storage/categories/${parentCategory.id}/create`,
        state: {
          name: parentCategory.name,
        },
      });
    } else {
      props.history.push({
        pathname: "/main/storage/categories/top-level/create",
      });
    }
  };
  const redirectToUpdate = (id) => {
    props.history.push(`/main/storage/categories/${id}/edit`);
  };

  const onDeleteCategory = async (id) => {
    setLoading(true);
    const category = new CaterogyAPI(merchantId, shopId);

    try {
      const result = await category.deleteById(id);
      setDeleteMode(null);
      const newCats = [...currentCats].filter((el) => el.id !== id);
      setCurrentCats(newCats);
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        setError(e.response.data.message);
      } else {
        setError(true);
      }
    }
    setLoading(false);
  };

  return (
    <div className="products-container">
      {loading && (
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
      {error === true && <ServerError />}
      {typeof error === "string" && <CustomError title={error} />}
      {!error && !loading && (
        <>
          <Modal show={successCreated} onClose={() => setSuccessCreated(null)}>
            <SuccessCreatedContent name={successCreated} />
          </Modal>
          <Modal show={successChanged} onClose={() => setSuccessChanged(null)}>
            <SuccessChangedContent name={successChanged} />
          </Modal>
          <Modal show={deleteMode} onClose={() => setDeleteMode(null)}>
            <DeleteModeContent
              obj={deleteMode}
              onClose={() => setDeleteMode(null)}
              onDelete={(id) => onDeleteCategory(id)}
            />
          </Modal>
          {parentCategory && (
            <IconButton
              style={{ position: "absolute", top: "20px", left: "470px" }}
              onClick={
                parentCategory.parentId ? goToPreviousLevel : goToTopLevel
              }
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <ul className="products-container_content">
            <div className="products-container_content-header">
              <div className="left">
                <p>{`${t("cats.title")} (${
                  parentCategory ? parentCategory.name : "Top-level"
                })`}</p>
              </div>
              <button onClick={redirectToCreate}>
                <img src={basket} alt="" />
                <p>{t("cats.create.button")}</p>
              </button>
            </div>
            <li className="products-container_content-header-names">
              <div className="cat-name">{t("cats.table.1")}</div>
              <div className="cat-image">{t("cats.table.2")}</div>
              <div className="cat-list">{t("cats.table.3")}</div>
              <div className="cat-tools"></div>
            </li>
            {currentCats.map((el, index) => (
              <CategoryItem
                key={index}
                id={el.id}
                name={el.name}
                image={el.image}
                child={el.children}
                onDelete={setDeleteMode}
                onEdit={(id) => redirectToUpdate(id)}
              />
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default Categories;
