import $api from "../http";
import {
  application_secret,
  joinPosterConfirmUrl,
  joinposterId,
} from "../../config/config";
import getFakeResponse from "../../tools/forTest/getFakeResponse";

export default class AuthService {
  static async login(login, password) {
    return $api.post("/Accounts/authenticate", {
      login,
      password,
    });
  }

  static async refreshToken() {
    return $api.post(`/Accounts/refresh-authentication`);
  }

  static async authByJoinPosterToken(account, code) {
    const data = {
      application_id: joinposterId,
      application_secret: application_secret,
      grant_type: "authorization_code",
      redirect_uri: joinPosterConfirmUrl,
      code: code,
    };
    return $api.post(
      `https://poster.ukrpay.net/application/${account}/register-merchant`,
      data,
    );
  }
}
