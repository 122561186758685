import React, { useEffect, useState } from "react";
import { mediaPicturesExtensions } from "../../../dataUI/common/commonConsts";
import noImageIcon from "../../../assets/images/no-image.jpg";

const RhfPhoto = ({
  previewUrlInit,
  buttonChangingText,
  buttonAddingText,
  descriptionText,
  id,
  setFormDataFile,
}) => {
  const [mediaFile, setMediaFile] = useState(undefined);
  const [noImageMode, setNoImageMode] = useState(true);

  const onSelectImage = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    const formDataFile = e.target.files[0];
    setFormDataFile && setFormDataFile(formDataFile);
    if (formDataFile) {
      reader.readAsDataURL(formDataFile);
      reader.onload = async function (e) {
        const file = e.target.result;
        const extension =
          file && file.toString().split("data:")[1].split(";base64")[0];
        if (mediaPicturesExtensions.includes(extension)) {
          setNoImageMode(false);
          setMediaFile(file);
        }
      };
    }
  };

  return (
    <div className="RHFPhoto">
      <div className="Description">{descriptionText}</div>
      <div className="ImageBox">
        <div className="ImageWrap">
          {previewUrlInit || mediaFile ? (
            <img src={mediaFile ? mediaFile : previewUrlInit} alt="" />
          ) : (
            <img src={noImageIcon} alt="" />
          )}
        </div>

        <div className="FormArea">
          <label htmlFor={id} className="label-for-file-p">
            {!noImageMode ? buttonChangingText : buttonAddingText}
          </label>
          <input
            accept="image/*"
            onChange={onSelectImage}
            type="file"
            id={id}
            style={{ display: "none" }}
          />
        </div>
      </div>
    </div>
  );
};

export default RhfPhoto;
