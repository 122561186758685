import * as actionTypes from "./actionTypes";

export const setMerchants = (arr) => {
  if (arr) {
    localStorage.setItem("merchants", JSON.stringify(arr));
  } else {
    localStorage.removeItem("merchants");
  }

  return {
    type: actionTypes.SET_MERCHANTS,
    merchants: arr,
  };
};
export const updateMerchants = (arr) => {
  if (arr) {
    localStorage.setItem("merchants", JSON.stringify(arr));
  } else {
    localStorage.removeItem("merchants");
  }

  return {
    type: actionTypes.UPDATE_MERCHANTS,
    merchants: arr,
  };
};

export const setSelectedMerchant = (val) => {
  if (val) {
    localStorage.setItem("selectedMerchant", JSON.stringify(val));
  } else {
    localStorage.removeItem("selectedMerchant");
  }

  return {
    type: actionTypes.SET_SEL_MERCHANT,
    val: val,
  };
};

export const setSelectedShop = (val) => {
  if (val) {
    localStorage.setItem("selectedShop", JSON.stringify(val));
  } else {
    localStorage.removeItem("selectedShop");
  }

  return {
    type: actionTypes.SET_SEL_SHOP,
    val: val,
  };
};
