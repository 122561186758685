import React, { useEffect, useState } from "react";
import shop from "../../assets/images/components/ShopsList/shop.svg";
import shop_active from "../../assets/images/components/ShopsList/shop_active.svg";
import personal from "../../assets/images/components/Personal/personal_inactive.svg";
import personal_active from "../../assets/images/components/Personal/personal.svg";
import { MerchantNavigation_tabsTypes } from "../../dataUI/common/merchant";
import { useTranslation } from "react-i18next";

const MerchantNavigationTab = ({
  tabType,
  selectedTabType,
  onTabClick,
  wrapStyles,
}) => {
  const { t } = useTranslation();
  const [defaultIcon, setDefaultIcon] = useState("");
  const [activeIcon, setActiveIcon] = useState("");
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setDefaultIcon(
      tabType === MerchantNavigation_tabsTypes.SHOPS ? shop : personal,
    );
    setActiveIcon(
      tabType === MerchantNavigation_tabsTypes.SHOPS
        ? shop_active
        : personal_active,
    );
  }, [tabType]);

  useEffect(() => {
    setIsActive(tabType === selectedTabType);
  }, [selectedTabType]);

  const onClickHandler = () => {
    onTabClick(tabType);
  };

  return (
    <div
      className={
        isActive
          ? "merchantNavigation__tab merchantNavigation__tab_isActive"
          : "merchantNavigation__tab"
      }
      onClick={onClickHandler}
      style={wrapStyles ? wrapStyles : {}}
    >
      <img
        src={isActive ? activeIcon : defaultIcon}
        alt={t("shopsList.tabName1")}
        style={
          tabType === MerchantNavigation_tabsTypes.PERSONNEL
            ? {
                width: "24px",
                height: "24px",
              }
            : { width: "18px", height: "16px" }
        }
      />

      {tabType === MerchantNavigation_tabsTypes.SHOPS ? (
        <div className="title">
          <div>{t("shopsList.tabName1").split(" ")[0]}</div>
          <div>{t("shopsList.tabName1").split(" ")[1]}</div>
        </div>
      ) : (
        <div className="title">{t("shopsList.tabName2")}</div>
      )}
    </div>
  );
};

export default MerchantNavigationTab;
