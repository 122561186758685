import React from "react";
import { connect } from "react-redux";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import GlobalLoader from "../UI/GlobalLoader/GlobalLoader";

import "./wrapper.scss";

const mainStyles = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
};

const Wrapper = (props) => {
  const { children, global_loading } = props;

  return (
    <div style={mainStyles}>
      {global_loading && <GlobalLoader />}
      <Header {...props} />
      <div className="content-under-header">{children}</div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  global_loading: state.mode.global_loading,
});

export default connect(mapStateToProps)(Wrapper);
