import React from "react";
import { CSSTransition } from "react-transition-group";

import "../AuthModal/authModal.scss";

const Modal = (props) => {
  const { show } = props;

  return (
    <>
      {show && <div className="auth-modal-backdrop" onClick={props.onClose} />}
      <CSSTransition
        in={show}
        timeout={300}
        classNames="auth-modal-anim"
        unmountOnExit
      >
        <div
          className="auth-modal-block-content"
          style={{ paddingBottom: "20px" }}
        >
          {props.children}
        </div>
      </CSSTransition>
    </>
  );
};

export default Modal;
