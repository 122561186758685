export const inputTypes = {
  PHONE_NUMBER: "PHONE_NUMBER",
  PASSWORD: "password",
};

export const mediaPicturesExtensions = ["image/jpeg", "video/mp4", "image/png"];

export const methodTypes = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
};

export const authUrls = {
  SAMO_AUTH: "auth",
  INSTRUCTION: "instruction",
  REG: "joinposter-registration",
  REG_CONFIRM: "joinposter-registration-confirm",
  IFRAME: "joinposter-iframe",
};
