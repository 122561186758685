import React from "react";
import { useTranslation } from "react-i18next";

import info from "../../../assets/images/components/ConsistItem/info-black.svg";
import cancel from "../../../assets/images/components/ConsistItem/cancelled.svg";
import plus from "../../../assets/images/components/ConsistItem/plus.svg";
import minus from "../../../assets/images/components/ConsistItem/minus.svg";

import "./consistItem.scss";

const ConsistItem = (props) => {
  const { pd, q } = props.good;
  const { locked } = props;
  const { t } = useTranslation();

  return (
    <div className="consist-item-container">
      <div className="consist-item-container_left">
        <div className="consist-item-container_left_top">
          <h2>{pd.t}</h2>
          {/* <div>
            <img src={info} alt="info" />
          </div> */}
        </div>
        <div className="consist-item-container_left_bottom">
          <p>
            {pd.bl &&
              t("orDetItem.100") +
                (pd.w === 0 ? `${pd.v} мл.: ` : `${pd.w} гр.: `)}
            {!pd.bl && t("orDetItem.1")}
          </p>
          <span>{`${pd.p} грн`}</span>
        </div>
      </div>

      <div className="consist-item-container_right">
        <div className="consist-item-container_right_count">
          {pd.bl && (
            <>
              {!locked && (
                <div className="icon" onClick={() => props.onDeleteProd(pd.id)}>
                  <img src={cancel} alt="cancel" />
                </div>
              )}
              {locked ? (
                <p>{q + " "}</p>
              ) : (
                <input
                  value={q.toString()}
                  type="number"
                  min="0"
                  onChange={(e) => props.onChangeBulkProduct(pd.id, e)}
                />
              )}
              <p style={{ marginLeft: "3px" }}>
                {pd.weight !== 0 ? "гр." : "мл."}
              </p>
            </>
          )}
          {!pd.bl && (
            <>
              {!locked && (
                <div
                  onClick={
                    q <= 1
                      ? () => props.onDeleteProd(pd.id)
                      : () => props.onChangeNotBulkProduct(pd.id, q - 1)
                  }
                  className="icon"
                >
                  <img src={q <= 1 ? cancel : minus} alt="" />
                </div>
              )}
              {locked ? (
                <p>{q + " шт."}</p>
              ) : (
                <input
                  value={q.toString()}
                  type="number"
                  min="0"
                  onChange={(e) =>
                    props.onChangeNotBulkProduct(pd.id, e.target.value)
                  }
                />
              )}
              {!locked && (
                <div
                  onClick={() => props.onChangeNotBulkProduct(pd.id, q + 1)}
                  className="icon"
                >
                  <img src={plus} alt="plus" />
                </div>
              )}
            </>
          )}
        </div>
        <div className="consist-item-container_right_price">
          <p>
            {pd.bl
              ? +((q / (pd.w === 0 ? pd.v : pd.w)) * pd.p).toFixed(2)
              : +(q * pd.p).toFixed(2)}{" "}
            ₴
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConsistItem;
