import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const IframePosterConfirm = ({ synchronizeMerchant }) => {
  const { t } = useTranslation();

  return (
    <div className="IframePosterConfirmPage">
      <div className="IframePosterConfirm">
        <h2 className="IframePosterConfirm_Title">{t("auth.storeImport")}</h2>

        <div className="IframePosterConfirm_pBox">
          Після успішного імпорту, з’явиться відповідне повідомлення на екрані.
          У разі помилки - потрібно зв’язатися з{" "}
          <a type="email" href="mailto:samo@ukrpay.net">
            технічною підтримкою
          </a>
          .
        </div>

        <div
          className="IframePosterConfirm_Button"
          onClick={synchronizeMerchant}
        >
          {t("auth.storeImport_button")}
        </div>
      </div>
    </div>
  );
};

export default IframePosterConfirm;
