import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Category from "../../../../../../../models/storage/categories/categories";
import Error from "../../../../../../../components/UI/Errors/ServerError/ServerError";
import CustomError from "../../../../../../../components/UI/Errors/CustomError/CustomError";
import Spinner from "../../../../../../../components/UI/Spinner/Spinner";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const EditCategory = (props) => {
  const { t } = useTranslation();
  const { merchantId, shopId } = props;
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [id, setId] = useState(null);
  const [parentId, setParentId] = useState(null);

  useEffect(async () => {
    const id = props.match.params.id;
    setLoading(true);
    setError(false);
    const category = new Category(merchantId, shopId);
    try {
      const result = await category.getByCatId(id);
      setName(result.data.name);
      setUrl(result.data.image);
      setId(result.data.id);
      setParentId(result.data.parentId || null);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }, []);

  const onSetName = (event) => {
    const val = event.target.value;
    if (val.length > 50) {
      setName(val.slice(0, 50));
    } else setName(val);
  };

  const onSetUrl = (event) => {
    const val = event.target.value;
    if (val.length > 2000) {
      setUrl("");
    } else {
      setUrl(val);
    }
  };

  const onCreateCategory = async () => {
    setLoading(true);
    setError(false);

    const categoryAPI = new Category(merchantId, shopId);
    try {
      const result = await categoryAPI.updateByCatId(id, { name, image: url });
      props.history.push({
        pathname: parentId
          ? `/main/storage/categories/${parentId}`
          : "/main/storage/categories/",
        state: {
          successChanged: true,
          name: result.data.name,
        },
      });
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        setError(e.response.data.message);
      } else {
        setError(true);
      }
    }
    setLoading(false);
  };

  return (
    <div className="create-cat-container" style={{ paddingLeft: "60px" }}>
      {error === true && <Error />}
      {typeof error === "string" && <CustomError title={error} />}
      {loading && (
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
      <IconButton
        style={{ position: "absolute", top: "10px", left: "60px" }}
        onClick={() => props.history.push("/main/storage/categories")}
      >
        <ArrowBackIcon />
      </IconButton>
      {!error && !loading && (
        <>
          <h2 style={{ alignSelf: "center" }}>{t("cats.update.title")}</h2>
          <div className="content">
            <div className="content_area">
              <label htmlFor="name">{t("cats.update.name")}</label>
              <input value={name} onChange={onSetName} type="text" id="name" />
            </div>
            <div className="content_area">
              <label htmlFor="name">{t("cats.update.url")}</label>
              <input value={url} onChange={onSetUrl} type="text" id="name" />
            </div>
            <div className="example">
              <p>{t("cats.update.photo")}</p>
              <img alt="" src={url} />
            </div>
            <button onClick={onCreateCategory}>
              {t("cats.update.button")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default EditCategory;
